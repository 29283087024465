/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Collapse, Stack } from '@mui/material';
import { useState } from 'react';
import { WorkflowTaskModel } from '../../datastore/interfaces/workflow';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { generateCustomTableData, parseReferences } from '../../common';
import CustomDataTable from './CustomDataTable';
import { TaskData } from '../../models';

interface CustomDataTableTaskProps {
  task: WorkflowTaskModel;
  taskData: TaskData[];
}

export default function CustomDataTableTask({
  task,
  taskData,
}: CustomDataTableTaskProps) {
  const [open, setOpen] = useState(false);

  const allElements = task.taskDefinition.form?.sections.flatMap(
    (s) => s.elements
  );
  const elementReferences = allElements?.map((a) => a.dataReference);

  const parsedData = elementReferences
    ? parseReferences(taskData, elementReferences)
    : task.data;

  const data = generateCustomTableData(parsedData);

  const maxColumns = Math.max(...data.map((d) => d.groups.length));

  return (
    <Stack spacing={1}>
      <Button
        variant="outlined"
        onClick={() => setOpen(!open)}
        endIcon={open ? <ArrowDownwardIcon /> : <ArrowForwardIcon />}
        sx={{ width: '100%', padding: '0.5em 1em' }}
      >
        {task.taskDefinition.name}
      </Button>
      <Collapse in={open} timeout="auto">
        <CustomDataTable data={data} maxColumns={maxColumns} />
      </Collapse>
    </Stack>
  );
}
