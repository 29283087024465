import { Grid, Typography } from '@mui/material';
import { FormElementModel } from '../../../datastore/interfaces/form';

interface InfoFieldProps {
  element: FormElementModel;
}

export default function InfoField({ element }: InfoFieldProps) {
  const data = element.data?.split('<br/>') ?? [''];

  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
      sx={element.hidden ? { display: 'none' } : {}}
    >
      <Grid item xs={12}>
        {data.map((info) => {
          return <Typography key={info}>{info}</Typography>;
        })}
      </Grid>
    </Grid>
  );
}
