import { Grid } from '@mui/material';
import { FormElementModel } from '../../../datastore/interfaces/form';
import HelpField from './HelpField';
import Error from './Error';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { FormSectionDirection } from '../../../common';

export interface ElementInputProps {
  element?: FormElementModel;
  direction: FormSectionDirection;
  children: string | JSX.Element | JSX.Element[];
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  showHelp?: boolean;
}

export default function ElementInput({
  element,
  direction,
  error,
  children,
  showHelp = true,
}: ElementInputProps) {
  return (
    <Grid
      item
      xs={12}
      md={direction === FormSectionDirection.Horizontal ? 12 : true}
      sx={{ marginBottom: '1em' }}
    >
      {children}
      {element && showHelp && (
        <>
          <HelpField message={element.help} />
          <Error element={element} error={error} />
        </>
      )}
    </Grid>
  );
}
