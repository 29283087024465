import dayjs from 'dayjs';
import { dateFormat } from '../dates/dateFormat';
import { isIsoDate } from '../dates/isIsoDate';
import { isNumber } from '../numbers/isNumber';

export function parseDataRef(data: any) {
  if ((isIsoDate(data) && !isNumber(data)) || dayjs.isDayjs(data)) {
    return dayjs(data).format(dateFormat);
  }

  if (typeof data === 'boolean') {
    return `${data}`;
  }

  return data;
}
