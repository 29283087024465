/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Chip,
  Collapse,
  Stack,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  StyledTable,
  StyledTableHead,
  StyledTableRow,
} from '../../../components';
import { useState, Fragment } from 'react';
import { SortKey, getValue, sortData } from '../../../common/arrays/sorting';
import StyledTableFooter from '../../../components/tables/StyledTableFooter';
import {
  departmentKey,
  filterDataWithSearch,
  parseDataRef,
  userCanEnterForm,
} from '../../../common';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TableFeatures from './TableFeatures';
import { WorkflowDefinitionModel } from '../../../datastore/interfaces/workflowDefinition';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';
import { useCurrentUser } from '../../../hooks';
import SearchFunction from './SearchFunction';
import { SearchFieldModel } from '../../../common/search/searchFieldModel';

const defaultHeaderCells = [
  { id: 'referenceNumber', label: 'Ref.' },
  {
    id: 'currentTasks.0.assignedTo.name',
    label: 'Assignee',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'status',
    label: 'Process Step',
  },
  {
    id: 'currentTasks.0.dateDue',
    label: 'Due Date',
  },
  {
    id: 'owner.name',
    label: 'Initiator',
  },
  {
    id: 'created',
    label: 'Submission Date',
  },
];

interface GroupTableProps {
  workflowDefinition: WorkflowDefinitionModel;
  workflows: WorkflowModel[];
}

export default function GroupTable({
  workflowDefinition,
  workflows,
}: GroupTableProps) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const saveSearchId = `groupTableSearch-${workflowDefinition.name}-${currentUser.id}`;
  const [allToggle, setAllToggle] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchFields, setSearchFields] = useState<SearchFieldModel[]>(
    getLocalSearchData()
  );
  const [searchOn, setSearchOn] = useState(getLocalSearchData().length > 0);

  const headerCells = workflowDefinition.customColumns.length
    ? workflowDefinition.customColumns.map((c) => {
        return { id: c.label, label: c.label };
      })
    : defaultHeaderCells;

  const [sort, setSort] = useState<SortKey>({
    key: headerCells[0].id,
    direction: 'desc',
  });

  let data = workflows
    .filter((w) => allToggle || !w.complete)
    .map((workflow) => {
      const result: any = {};
      result.id = workflow.id;
      if (workflowDefinition.customColumns.length) {
        for (const customColumn of workflowDefinition.customColumns) {
          if (customColumn.workflowReference?.length) {
            result[customColumn.label] = getValue(
              customColumn.workflowReference,
              workflow
            );
          } else if (customColumn.dataReference?.length) {
            result[customColumn.label] =
              workflow.customData.find(
                (c) => c.reference === customColumn.dataReference
              )?.value ?? '';
          }
        }
      }

      return result;
    });

  const departmentSearchFields = searchFields.filter(
    (s) => s.column === departmentKey
  );

  let sortedAndFilteredData = sortData(
    searchOn ? filterDataWithSearch(data, searchFields) : data,
    sort
  );

  if (searchOn && departmentSearchFields.length) {
    sortedAndFilteredData = sortedAndFilteredData.filter((d) =>
      workflows
        .find((w) => w.id === d.id)!
        .currentTasks.some(
          (currentTask) =>
            !currentTask.taskDefinition.groups.length ||
            currentTask.taskDefinition.groups.some((g) =>
              departmentSearchFields.some(
                (searchField) => searchField.value === g
              )
            )
        )
    );
  }

  const fullHeaderCells = [
    ...headerCells,
    {
      id: 'Actions',
      label: '',
    },
  ];

  function getLocalSearchData() {
    const savedSearch = localStorage.getItem(saveSearchId);
    if (savedSearch) {
      try {
        return JSON.parse(savedSearch) as SearchFieldModel[];
      } catch (error) {
        console.error(`Failed to parse local search data: ${error}`);
      }
    }

    return [];
  }

  function setSearchData(searchData: SearchFieldModel[]) {
    localStorage.setItem(saveSearchId, JSON.stringify(searchData));
    setSearchFields(searchData);
  }

  return (
    <Stack spacing={1}>
      <Stack
        spacing={2}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Typography variant="h1" sx={{ flex: 2 }}>
          {workflowDefinition.name}
        </Typography>
        <TableFeatures
          workflowName={workflowDefinition.name}
          data={data}
          searchOn={searchOn}
          allToggle={allToggle}
          setSearchOn={setSearchOn}
          setAllToggle={setAllToggle}
        />
      </Stack>

      <Collapse in={searchOn} timeout="auto">
        <SearchFunction
          data={data}
          workflowDefinition={workflowDefinition}
          searchFields={searchFields}
          setSearchFields={setSearchData}
        />
      </Collapse>

      {sortedAndFilteredData.length ? (
        <StyledTable>
          <StyledTableHead
            headerData={fullHeaderCells}
            sort={sort}
            setSort={setSort}
            searchFields={searchFields}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? sortedAndFilteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : sortedAndFilteredData
            ).map((entry) => {
              const workflow = workflows.find((w) => w.id === entry.id);

              if (!workflow) {
                console.error(
                  `No Workflow with ID ${entry.id} found for the relevant data!`
                );
                return <Fragment key={entry.id} />;
              }
              return (
                <StyledTableRow key={entry.id}>
                  {headerCells.map((cell) => {
                    const cellData = entry[cell.id];
                    const columnData = workflowDefinition.customColumns.find(
                      (c) => c.label === cell.id
                    );
                    if (cellData) {
                      if (columnData?.workflowReference === 'referenceNumber') {
                        return (
                          <TableCell key={cell.id}>
                            <Button
                              sx={{
                                padding: '0.2em 0.1em',
                              }}
                              onClick={() =>
                                navigate(`/workflows/${cellData}`, {
                                  state: {
                                    back: '/groupWorkflows',
                                  },
                                })
                              }
                            >
                              {cellData}
                            </Button>
                          </TableCell>
                        );
                      }

                      if (columnData?.workflowReference === 'status') {
                        return (
                          <TableCell key={cell.id}>
                            <Chip label={cellData} />
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={cell.id}>
                          {parseDataRef(cellData)}
                        </TableCell>
                      );
                    }

                    return <TableCell key={cell.id} />;
                  })}
                  <TableCell>
                    {!workflow.complete &&
                      workflow.currentTasks.map((c) => {
                        if (
                          userCanEnterForm(c, workflowDefinition, currentUser)
                        ) {
                          return (
                            <Button
                              key={c.id}
                              variant="outlined"
                              onClick={() =>
                                navigate(
                                  `/task/${workflow.referenceNumber}/${c.taskNumber}`,
                                  { state: { origin: '/groupWorkflows' } }
                                )
                              }
                            >
                              Action
                            </Button>
                          );
                        }
                        return <Fragment key={c.id} />;
                      })}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
          <StyledTableFooter
            data={sortedAndFilteredData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            type={workflowDefinition.name}
          />
        </StyledTable>
      ) : (
        <Typography>No Tasks found</Typography>
      )}
    </Stack>
  );
}
