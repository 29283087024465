import { DataStore, SortDirection } from 'aws-amplify';
import { Form, FormElement, FormSection } from '../models';
import { CurrentUserProps } from '../hooks/useCurrentUser';
import { FormDataProps, FormModel, FormSectionModel } from './interfaces/form';
import { convertFormToModel } from './convert/form';

export const formsData = (currentUser: CurrentUserProps) => {
  async function get(id: string) {
    const result = await DataStore.query(Form, id);
    if (result) {
      return await convertFormToModel(result);
    }
  }

  async function getList(search?: string) {
    let results = await DataStore.query(
      Form,
      search?.length
        ? (form) =>
            form.or((form) => [
              form.name.contains(search),
              form.description.contains(search),
            ])
        : null,
      {
        sort: (f) => f.createdAt(SortDirection.ASCENDING),
      }
    );

    if (results) {
      const convertedResults = [];
      for (const result of results) {
        convertedResults.push(await convertFormToModel(result));
      }
      return convertedResults;
    }

    return [];
  }

  async function create(data: FormModel) {
    const created = await DataStore.save(
      new Form({
        id: data.id,
        name: data.name,
        description: data.description,
      })
    );

    if (created) {
      for (const section of data.sections) {
        const newSection = await DataStore.save(
          new FormSection({
            id: section.id,
            formId: created.id,
            name: section.name,
            direction: section.direction,
            groups: section.groups,
          })
        );

        if (newSection) {
          await updateElements(section, newSection);
        }
      }

      return convertFormToModel(created);
    }

    throw new Error('Failed to create Form.');
  }

  async function update(data: FormModel) {
    const original = await DataStore.query(Form, data.id);

    if (original) {
      const updated = await DataStore.save(
        Form.copyOf(original, (updated) => {
          updated.name = data.name;
          updated.description = data.description;
        })
      );

      if (updated) {
        for (const section of data.sections) {
          const originalSection = await DataStore.query(
            FormSection,
            section.id
          );
          if (originalSection) {
            const updatedSection = await DataStore.save(
              FormSection.copyOf(originalSection, (updated) => {
                updated.name = section.name;
                updated.direction = section.direction;
                updated.groups = section.groups;
              })
            );

            if (updatedSection) {
              await updateElements(section, updatedSection);
            }
          } else {
            const newSection = await DataStore.save(
              new FormSection({
                id: section.id,
                formId: updated.id,
                name: section.name,
                direction: section.direction,
                groups: section.groups,
              })
            );
            if (newSection) {
              await updateElements(section, newSection);
            }
          }
        }

        return convertFormToModel(updated);
      }

      throw new Error('Failed to update Form.');
    }

    throw new Error('Could not find original Form.');
  }

  async function updateElements(
    section: FormSectionModel,
    sectionData: FormSection
  ) {
    const originalElements = await sectionData.elements.toArray();

    const newElementIds = section.elements.map((e) => e.id);

    for (const element of originalElements.filter(
      (e) => !newElementIds.includes(e.id)
    )) {
      await DataStore.delete(element);
    }

    for (const element of section.elements) {
      const originalElement = await DataStore.query(FormElement, element.id);

      if (originalElement) {
        await DataStore.save(
          FormElement.copyOf(originalElement, (updated) => {
            updated.order = element.order;
            updated.name = element.name;
            updated.type = element.type;
            updated.selectionId = element.selectionId;
            updated.dataReference = element.dataReference;
            updated.workflowReference = element.workflowReference;
            updated.data = element.data;
            updated.help = element.help;
            updated.hidden = element.hidden;
            updated.readonly = element.readonly;
            updated.regex = element.regex;
            updated.required = element.required;
            updated.default = element.default;
            updated.minimum = element.minimum;
            updated.maximum = element.maximum;
            updated.flex = element.flex;
            updated.outcomes = element.outcomes;
          })
        );
      } else {
        await DataStore.save(
          new FormElement({
            id: element.id,
            formSectionId: sectionData.id,
            selectionId: element.selectionId,
            workflowReference: element.workflowReference,
            dataReference: element.dataReference,
            data: element.data,
            help: element.help,
            hidden: element.hidden,
            readonly: element.readonly,
            regex: element.regex,
            name: element.name,
            order: element.order,
            type: element.type,
            required: element.required,
            default: element.default,
            minimum: element.minimum,
            maximum: element.maximum,
            flex: element.flex,
            outcomes: element.outcomes,
          })
        );
      }
    }
  }

  const returned: FormDataProps = {
    get,
    getList,
    create,
    update,
  };

  return returned;
};
