import { Button, Divider, Paper, Stack, Typography } from '@mui/material';

interface LoginProps {
  login: () => void;
}

export default function Login({ login }: LoginProps) {
  return (
    <Paper elevation={4}>
      <Stack
        spacing={3}
        sx={{
          padding: '4em 1em 2em 1em',
          marginTop: '1em',
          alignItems: 'center',
        }}
      >
        <Stack spacing={2} alignItems="center">
          <Typography variant="h4" fontWeight="bold">
            Welcome to the Workflow Service
          </Typography>
          {process.env.REACT_APP_ENV !== 'prod' ? (
            <Typography fontWeight="bold">
              {process.env.REACT_APP_ENV}
            </Typography>
          ) : (
            <></>
          )}
          <Divider />
          <Button
            variant="contained"
            onClick={login}
            sx={{ padding: '1em', width: '100%' }}
          >
            Login
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
