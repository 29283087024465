import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import { FormInputComponentProps } from './Interfaces';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';

export default function MultiTextField({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  const minimum = isNaN(Number(element.minimum)) ? 0 : Number(element.minimum);
  const maximum = isNaN(Number(element.maximum))
    ? Number.MAX_SAFE_INTEGER
    : Number(element.maximum);

  const elementId = `${idPrefix}${element.dataReference}`;

  const { fields, append, remove } = useFieldArray({
    name: elementId,
    control,
  });

  return (
    <Stack spacing={2}>
      {fields.map((text, i) => {
        return (
          <Stack key={text.id} direction="row" spacing={2}>
            <Controller
              control={control}
              name={`${elementId}.${i}.value`}
              rules={{
                required: element.required,
                minLength: minimum,
                maxLength: maximum,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  disabled={element.readonly ?? false}
                  inputProps={{
                    minLength: minimum,
                    maxLength: maximum,
                  }}
                />
              )}
            />
            <IconButton onClick={() => remove(i)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Stack>
        );
      })}
      <Box>
        <Button variant="contained" onClick={() => append({ value: '' })}>
          <AddIcon />
        </Button>
      </Box>
    </Stack>
  );
}
