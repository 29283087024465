export enum SearchConditions {
  contains = 'Contains',
  doesNotContain = 'Does Not Contain',
  equals = 'Equals',
  notEquals = 'Not Equals',
  greaterThan = 'Greater Than',
  greaterThanOrEqualTo = 'Greater Than Or Equal To',
  lessThan = 'Less Than',
  lessThanOrEqualTo = 'Less Than Or Equal To',
}
