export enum FormElementTypes {
  TextField = 'TextField',
  MediumTextField = 'MediumTextField',
  LargeTextField = 'LargeTextField',
  MultiTextField = 'MultiTextField',
  DateField = 'DateField',
  DateRange = 'DateRange',
  Comments = 'Comments',
  Select = 'Select',
  CheckBoxSelect = 'CheckBoxSelect',
  CheckBox = 'Checkbox',
  Attachment = 'Attachment',
  DataReference = 'DataRef',
  Assign = 'Assign',
  User = 'User',
  Info = 'Info',
  Currency = 'Currency',
  PhoneNumber = 'PhoneNumber',
  MultiUser = 'MultiUser',
  EmailField = 'EmailField',
  Number = 'Number',
  Radio = 'Radio',
}
