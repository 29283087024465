import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, CircularProgress } from '@mui/material';
import { themeOptions } from './themes/themes';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes';
import {
  AlertProvider,
  DatastoreProvider,
  LoadingProvider,
  UserLoginProvider,
} from './hooks';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en-au';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ServiceWorkerUpdate } from './components';
dayjs.extend(LocalizedFormat);
dayjs.locale('en-au');

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
        <ServiceWorkerUpdate>
          <LoadingProvider>
            <UserLoginProvider>
              <DatastoreProvider>
                <AlertProvider>
                  <RouterProvider
                    router={router}
                    fallbackElement={<CircularProgress />}
                  />
                </AlertProvider>
              </DatastoreProvider>
            </UserLoginProvider>
          </LoadingProvider>
        </ServiceWorkerUpdate>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
