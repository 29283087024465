import { TableRow, alpha, styled } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid var(--divider, #0000001F)',
  '& td': {
    paddingLeft: '1em',
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.secondary, 0.1),
  },
}));
