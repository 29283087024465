/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Collapse, Divider, Stack } from '@mui/material';
import {
  generateCustomTableData,
  parseReferencesFromGroups,
} from '../../common';
import { TaskData } from '../../models';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CustomDataTable from './CustomDataTable';

interface CustomDataTableReferencesProps {
  taskData: TaskData[];
  references: string[];
  startOpen: boolean;
  title: string;
}

export default function CustomDataTableReferences({
  taskData,
  references,
  startOpen,
  title,
}: CustomDataTableReferencesProps) {
  const [open, setOpen] = useState(startOpen);

  const parsedTaskData = parseReferencesFromGroups(taskData, references);

  const data = generateCustomTableData(parsedTaskData);
  const maxColumns = Math.max(...data.map((d) => d.groups.length));

  return data.length ? (
    <Stack spacing={1}>
      <Button
        variant="outlined"
        onClick={() => setOpen(!open)}
        endIcon={open ? <ArrowDownwardIcon /> : <ArrowForwardIcon />}
        sx={{ width: '100%', padding: '0.5em 1em' }}
      >
        {title}
      </Button>
      <Collapse in={open} timeout="auto">
        <CustomDataTable data={data} maxColumns={maxColumns} />
      </Collapse>
      <Divider />
    </Stack>
  ) : (
    <></>
  );
}
