import {
  Button,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import {
  WorkflowModel,
  WorkflowTaskModel,
} from '../../datastore/interfaces/workflow';
import OktaLookup from '../lookup/OktaLookup';
import { OktaUser } from '../../models';

interface ReassignFormProps {
  open: boolean;
  onSubmit: (data: ReassignFormModel) => void;
  onClose: () => void;
  workflow: WorkflowModel;
  currentTasks: WorkflowTaskModel[];
}

export interface ReassignFormModel {
  assignedTo: string;
  assignedToName: string;
  taskId: string;
}

export default function ReassignForm({
  open,
  onSubmit,
  onClose,
  currentTasks,
}: ReassignFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const assignedToName = 'assignedToName';
  const assignedToEmail = 'assignedTo';

  const defaultValues = {
    [assignedToEmail]: '',
    [assignedToName]: '',
    taskId: currentTasks[0].id,
  };

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ReassignFormModel>({ defaultValues });

  function onLookupChange(user: OktaUser) {
    setValue(assignedToEmail, user.email);
    setValue(assignedToName, user.name);
    clearErrors();
  }

  function onLookupClear() {
    setValue(assignedToEmail, '');
    setValue(assignedToName, '');
    clearErrors();
  }

  const onSubmitForm: SubmitHandler<ReassignFormModel> = (data) => {
    if (data.assignedTo.length && data.assignedToName.length) {
      onSubmit(data);
    } else {
      setError(assignedToEmail, { message: 'User not selected.' });
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={2} sx={{ padding: isMobile ? '1em' : '1em 2em' }}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" fontWeight="bold">
                Reassign Task To
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
          </Stack>
          {currentTasks.length > 1 ? (
            <Stack spacing={1}>
              <Typography>Task</Typography>
              <Controller
                control={control}
                name="taskId"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select size="small" fullWidth {...field}>
                    {currentTasks.map((task) => {
                      return (
                        <MenuItem key={task.id} value={task.id}>
                          {task.taskDefinition.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              {errors['assignedTo'] && (
                <Typography>{errors['assignedTo'].message}</Typography>
              )}
            </Stack>
          ) : (
            <></>
          )}
          <OktaLookup
            onSelect={onLookupChange}
            onClear={onLookupClear}
            allowSelf={true}
          />
          {errors[assignedToEmail] && (
            <Typography>{errors[assignedToEmail].message}</Typography>
          )}
          <Divider />
          <Stack>
            <Button variant="contained" type="submit" sx={{ marginTop: '2em' }}>
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
}
