/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Owner, TaskData } from '../../models';
import { generateDefaultValues, parseFormData } from '../../common';
import { TaskDefinitionModel } from '../../datastore/interfaces/workflowDefinition';
import { WorkflowModel } from '../../datastore/interfaces/workflow';
import { FormElementVisibility } from '../../common/interfaces/formElementVisiblity';
import InnerForm from './InnerForm';
import { FormOutcomes } from '../../common/interfaces/formOutcomes';
import PreviousForm from './PreviousForm';
import { Fragment } from 'react';
import { FormElementModel } from '../../datastore/interfaces/form';
import { useCurrentUser } from '../../hooks';

interface FormProps {
  existingData: TaskData[];
  taskDefinition: TaskDefinitionModel;
  workflow: WorkflowModel;
  onComplete: (
    data: TaskData[],
    assignee: Owner | undefined,
    triggers: string[]
  ) => Promise<void>;
}

export interface FormResultsData {
  key: string;
  group?: string;
  value: any;
  isChanged: boolean;
  groupId: string;
  sectionId: string;
  index: number;
}

export default function Form({
  existingData,
  taskDefinition,
  onComplete,
  workflow,
}: FormProps) {
  const currentUser = useCurrentUser();
  const formDefinition = taskDefinition.form;
  const allFormDefinitions = workflow.definition.process
    .filter((p) => p)
    .map((p) => p.form!);

  const previousFormsToEdit = allFormDefinitions
    .filter(
      (a) =>
        taskDefinition.editPreviousForms.includes(a.id) &&
        workflow.history.some((h) => h.taskDefinition.formId === a.id)
    )
    .sort(
      (a, b) =>
        taskDefinition.editPreviousForms.indexOf(a.id) -
        taskDefinition.editPreviousForms.indexOf(b.id)
    );

  let defaultValues: any = formDefinition
    ? generateDefaultValues(formDefinition, existingData, currentUser)
    : {};

  for (const previousForm of previousFormsToEdit) {
    if (previousForm) {
      defaultValues = {
        ...defaultValues,
        ...generateDefaultValues(previousForm, existingData, currentUser),
      };
      for (const section of previousForm.sections) {
        for (const element of section.elements) {
          hideElements(element, taskDefinition.elementsToHide ?? []);
        }
      }
    }
  }

  function hideElements(element: FormElementModel, elementsToHide: string[]) {
    if (elementsToHide.includes(element.id)) {
      element.hidden = true;
    }
  }

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues });

  let visibility: FormElementVisibility[] = [];

  const onSubmitForm: SubmitHandler<any> = async (data) => {
    const results: FormResultsData[] = [];
    const elements = taskDefinition.form!.sections.flatMap((s) => s.elements);

    for (const sectionKey of Object.keys(data)) {
      const dataSections = data[sectionKey];
      const originalSections = defaultValues[sectionKey];

      if (!Array.isArray(dataSections)) {
        throw Error(
          'Error! A section did not return as an array. Please contact support with this message.'
        );
      }

      const section =
        formDefinition?.sections.find((s) => s.id === sectionKey) ??
        previousFormsToEdit
          .flatMap((p) => p?.sections)
          .find((s) => s?.id === sectionKey);

      if (dataSections.length === 1 && !section?.groups) {
        const firstDataSection = dataSections[0];
        const firstOriginalSection = originalSections[0];
        for (const key of Object.keys(firstDataSection)) {
          results.push({
            key: key,
            value: firstDataSection[key],
            isChanged: firstOriginalSection[key] !== firstDataSection[key],
            groupId: '',
            sectionId: sectionKey,
            index: 0,
          });
        }
      } else if (section) {
        for (let i = 0; i < dataSections.length; i++) {
          const dataSection = dataSections[i];
          const originalSection = originalSections[i];
          const name =
            section.groups?.names && section.groups?.names.length !== 0
              ? section.groups.names.length > i
                ? section.groups.names[i]
                : section.groups.names[0]
              : '';
          const group = section.groups?.fixed ? name : `${name} ${i + 1}`;

          for (const key of Object.keys(dataSection)) {
            results.push({
              key: key,
              group: group,
              value: dataSection[key],
              isChanged: originalSection
                ? originalSection[key] !== dataSection[key]
                : true,
              groupId: section?.groups?.groupId ?? '',
              sectionId: sectionKey,
              index: i,
            });
          }
        }
      }
    }

    const {
      data: parsedData,
      assignee,
      errors: parsedErrors,
    } = await parseFormData(
      elements,
      results,
      visibility,
      previousFormsToEdit.flatMap((p) => p.sections.flatMap((s) => s.elements))
    );

    if (parsedErrors.length) {
      for (const parsedError of parsedErrors) {
        setError(parsedError.reference, { message: parsedError.message });
      }
      return;
    }

    let triggers = [];

    for (const outcome of outcomes) {
      for (const next of outcome.nextElements) {
        const outcomeResult = parsedData.find(
          (p) => p.reference === outcome.currentElement.dataReference
        );
        if (
          outcomeResult &&
          visibility.some(
            (v) => v.id === outcome.currentElement.id && v.isVisible
          ) &&
          next.triggers.some(
            (t) =>
              outcomeResult?.value.includes(t) ||
              outcomeResult?.custom?.includes(t)
          ) &&
          next.taskTrigger
        ) {
          triggers.push(next.taskTrigger);
        }
      }
    }

    onComplete(parsedData, assignee, triggers);
  };

  if (!formDefinition) {
    return <Typography>Error! Form not available for task.</Typography>;
  }

  const sections = formDefinition.sections.sort(
    (a, b) =>
      Math.min(...a.elements.map((ae) => ae.order)) -
      Math.min(...b.elements.map((be) => be.order))
  );

  const outcomes: FormOutcomes[] = sections.flatMap((s) =>
    s.elements
      .sort((a, b) => a.order - b.order)
      .map((e, i) => {
        return {
          order: e.order,
          sectionOrder: i,
          section: s.name,
          currentElement: e,
          nextElements: e.outcomes.map((o) => {
            return {
              ...o,
              nextElement: formDefinition.sections
                .flatMap((s) => s.elements)
                .find((fe) => fe.id === o.nextElement),
            };
          }),
        };
      })
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={2}>
        {previousFormsToEdit.map((previousForm) => {
          return (
            <Fragment key={previousForm.id}>
              <PreviousForm
                formDefinition={previousForm}
                watch={watch}
                control={control}
                workflow={workflow}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
                visibility={visibility}
              />
              <Divider />
            </Fragment>
          );
        })}
        <InnerForm
          formDefinition={formDefinition}
          watch={watch}
          control={control}
          workflow={workflow}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          clearErrors={clearErrors}
          outcomesOverride={outcomes}
          visibility={visibility}
        />
      </Stack>
    </form>
  );
}
