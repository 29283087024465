import {
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, Control, FieldErrors, useWatch } from 'react-hook-form';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { FormSelectionSection } from '../../../models';
import { Error } from '../components';

export interface SelectFieldProps {
  element: FormElementModel;
  control: Control<any>;
  errors: FieldErrors;
  idPrefix: string;
}

export default function SelectField({
  element,
  control,
  errors,
  idPrefix,
}: SelectFieldProps) {
  const elementId = `${idPrefix}${element.dataReference}`;
  const selection = element.selection;
  const error = errors[elementId];
  const customError = errors[`${elementId} Custom`];
  const watchField = useWatch({ control, name: elementId });

  if (!selection) {
    return (
      <Typography>
        Error! Selection element does not have any selection data.
      </Typography>
    );
  }

  const maximum = isNaN(Number(element.maximum))
    ? Number.MAX_SAFE_INTEGER
    : Number(element.maximum);
  const multiple = maximum > 1;

  const watchFieldSplit = watchField?.split(', ') ?? [];
  const allowCustom = selection.sections.flatMap((s) =>
    s.choices.filter((c) => c.isCustom)
  );
  const customSelected = allowCustom.filter((a) =>
    watchFieldSplit.includes(a.choice)
  );

  function selectGroup(section: FormSelectionSection) {
    const items = section.choices.map((c) => {
      return (
        <MenuItem key={c.choice} value={c.choice}>
          {c.choice}
        </MenuItem>
      );
    });

    return section.name
      ? [
          <ListSubheader
            key={section.name}
            sx={{ fontWeight: 'bold', fontSize: '1.3em' }}
          >
            {section.name}
          </ListSubheader>,
          items,
        ]
      : items;
  }

  return (
    <Stack spacing={2}>
      <Controller
        control={control}
        name={elementId}
        defaultValue={''}
        rules={{ required: element.required }}
        render={({ field }) => (
          <Select
            size="small"
            fullWidth
            error={error !== undefined}
            multiple={multiple}
            disabled={element.readonly ?? false}
            {...field}
            value={multiple ? field.value?.split(', ') ?? [] : field.value}
            onChange={(e) => {
              if (multiple) {
                field.onChange(
                  e.target.value
                    .filter((v: string) => v)
                    .slice(0, maximum)
                    .join(', ')
                );
              } else {
                field.onChange(e.target.value);
              }
            }}
          >
            <MenuItem value="">
              {multiple
                ? `Select up to ${maximum} Options`
                : 'Select an Option'}
            </MenuItem>
            {selection.sections.map((section, i) => {
              return selectGroup(section);
            })}
          </Select>
        )}
      />
      {customSelected.length ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h5">
            {customSelected[0].customLabel ?? 'Custom'}
          </Typography>
          <Controller
            control={control}
            name={`${elementId} Custom`}
            rules={{ required: true }}
            render={({ field }) => (
              <OutlinedInput {...field} fullWidth size="small" />
            )}
          />
          <Error element={element} error={customError} />
        </Stack>
      ) : null}
    </Stack>
  );
}
