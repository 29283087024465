import { DataStore, SortDirection } from 'aws-amplify';
import { Task, Workflow } from '../models';
import { CurrentUserProps } from '../hooks/useCurrentUser';
import { TaskModel } from './interfaces/task';
import { convertTaskToModel } from './convert/task';
import dayjs from 'dayjs';

export interface TaskDataProps {
  get: (id: string) => Promise<TaskModel | undefined>;
  getListByAssignedTo: (ownerEmail: string) => Promise<TaskModel[]>;
  unassign: (taskId: string) => Promise<TaskModel>;
}

export const tasksData = (currentUser: CurrentUserProps) => {
  async function get(id: string) {
    const result = await DataStore.query(Task, id);
    if (result) {
      return await convertTaskToModel(result);
    }
  }

  async function getListByAssignedTo(ownerEmail: string) {
    let results = await DataStore.query(
      Task,
      (task) => task.assignedToEmail.eq(ownerEmail),
      { sort: (t) => t.createdAt(SortDirection.ASCENDING) }
    );

    if (results) {
      const convertedResults = [];
      for (const result of results) {
        convertedResults.push(await convertTaskToModel(result));
      }
      return convertedResults;
    }

    return [];
  }

  async function unassign(taskId: string) {
    const existingTask = await DataStore.query(Task, taskId);

    if (!existingTask) {
      throw new Error('Task not found!');
    }

    const workflow = await DataStore.query(Workflow, existingTask.workflowId);

    if (!workflow) {
      throw new Error('Workflow not found!');
    }

    const savedTask = await DataStore.save(
      Task.copyOf(existingTask, (updated) => {
        updated.complete = true;
        updated.reassigned = true;
        updated.skipTrigger = true;
      })
    );

    if (!savedTask) {
      throw new Error('Failed to save task!');
    }

    const unassignedTask = await DataStore.save(
      new Task({
        id: crypto.randomUUID(),
        workflowId: existingTask.workflowId,
        taskDefinitionId: existingTask.taskDefinitionId,
        taskNumber: existingTask.taskNumber + 1,
        previousTaskNumber: existingTask.taskNumber,
        assignedToEmail: '',
        assignedToName: '',
        status: 'Unassigned',
        comments: `Unassigned by ${currentUser.name}`,
        complete: true,
        reassigned: true,
        skipTrigger: true,
        data: [],
        outcomeTriggers: existingTask.outcomeTriggers,
        workflow: workflow,
        availableToUsers: existingTask.availableToUsers,
        availableToGroups: existingTask.availableToGroups,
        createdAt: dayjs().toISOString(),
      })
    );

    const newTask = await DataStore.save(
      new Task({
        id: crypto.randomUUID(),
        workflowId: existingTask.workflowId,
        taskDefinitionId: existingTask.taskDefinitionId,
        taskNumber: unassignedTask.taskNumber + 1,
        previousTaskNumber: unassignedTask.taskNumber,
        assignedToEmail: '',
        assignedToName: '',
        status: existingTask.status,
        complete: false,
        reassigned: false,
        data: existingTask.data,
        outcomeTriggers: existingTask.outcomeTriggers,
        dateDue: existingTask.dateDue,
        workflow: workflow,
        availableToUsers: existingTask.availableToUsers,
        availableToGroups: existingTask.availableToGroups,
        createdAt: dayjs().toISOString(),
      })
    );

    if (newTask) {
      const updated = await DataStore.save(
        Workflow.copyOf(workflow, (updated) => {
          updated.currentTasks = [newTask.id];
          updated.updatedBy = {
            email: currentUser.email,
            name: currentUser.name,
          };
        })
      );

      if (updated) {
        return await convertTaskToModel(newTask);
      }
      throw new Error('Failed to update Workflow.');
    }

    throw new Error('Failed to create new Task.');
  }

  const returned: TaskDataProps = {
    get,
    getListByAssignedTo,
    unassign,
  };

  return returned;
};
