/* eslint-disable react-hooks/exhaustive-deps */
import {
  Link as MuiLink,
  Stack,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  useAlert,
  useCurrentUser,
  useDatastore,
  useLoading,
} from '../../hooks';
import { useEffect, useState, Fragment } from 'react';
import {
  AddNoteForm,
  CustomDataTableTask,
  LoadingSpinner,
  ReassignForm,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  UnassignForm,
} from '../../components';
import { Link, useLocation, useParams } from 'react-router-dom';
import { dateFormat, uploadFile } from '../../common';
import { ReassignFormModel } from '../../components/modals/ReassignForm';
import { AddNoteFormModel } from '../../components/modals/AddNoteForm';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import TopSection from './components/TopSection';
import NotesTable from './components/NotesTable';
import { WorkflowModel } from '../../datastore/interfaces/workflow';

export default function WorkflowDetails() {
  const { referenceNumber } = useParams();
  const store = useDatastore();
  const currentUser = useCurrentUser();
  const alert = useAlert();
  const setLoading = useLoading();
  const { state } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [workflow, setWorkflow] = useState<WorkflowModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [reassignOpen, setReassignOpen] = useState(false);
  const [unassignOpen, setUnassignOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);

  const assignedTask =
    workflow?.currentTasks.find(
      (c) => c.assignedTo.email === currentUser.email
    ) ?? workflow?.currentTasks[0];

  const inDepartment =
    workflow?.definition.groups.includes(currentUser.department) ||
    workflow?.definition.emails.includes(currentUser.email);

  useEffect(() => {
    getData(referenceNumber);
  }, []);

  async function getData(number: string | undefined) {
    if (number) {
      const results = await store.workflows.getByReferenceNumber(number);
      if (results) {
        setWorkflow(results);
      }
    }

    setIsLoading(false);
  }

  async function onReassignFormSubmit(data: ReassignFormModel) {
    if (!workflow) {
      alert('Cannot reassign. Workflow not found!', 'error');
      return;
    }
    setLoading(true);

    const task = workflow.currentTasks.find((c) => c.id === data.taskId);

    if (!task) {
      alert('Cannot reassign. Task owned by you not found!', 'error');
      return;
    }

    await store.workflows.reassignTask(workflow, task, {
      email: data.assignedTo,
      name: data.assignedToName,
    });
    await getData(referenceNumber);

    alert('Task reassigned', 'success');
    setLoading(false);
    setReassignOpen(false);
  }

  async function onUnassignFormSubmit(taskId: string) {
    if (!workflow) {
      alert('Cannot unassign. Workflow not found!', 'error');
      return;
    }

    setLoading(true);

    const task = workflow.currentTasks.find((c) => c.id === taskId);

    if (!task) {
      alert('Cannot unassign. Task not found!', 'error');
      return;
    }

    try {
      await store.tasks.unassign(taskId);
      await getData(workflow.referenceNumber);
      alert('Task unassigned.', 'success');
    } catch (e) {
      alert('Failed to unassign Task.', 'error');
    }

    setLoading(false);
    setUnassignOpen(false);
  }

  async function onAddNoteFormSubmit(data: AddNoteFormModel) {
    setLoading(true);

    if (workflow) {
      const keys = [];
      for (const file of data.attachments.map((v) => v.file)) {
        keys.push({
          key: await uploadFile(file),
          fileName: file.name,
          url: '',
        });
      }
      workflow.notes.push({
        createdBy: currentUser.getOwner(),
        note: data.note,
        attachments: keys,
        date: dayjs(),
      });

      const result = await store.workflows.update(workflow);

      if (result) {
        setWorkflow(workflow);
        alert('Note saved.', 'success');
      } else {
        alert('Failed to save Note.', 'error');
      }
    }

    setLoading(false);
    setNoteOpen(false);
  }

  return (
    <Stack spacing={2}>
      <LoadingSpinner isLoading={isLoading}>
        {workflow ? (
          <>
            <TopSection
              workflow={workflow}
              inDepartment={inDepartment ?? false}
              setNoteOpen={setNoteOpen}
              setReassignOpen={setReassignOpen}
              setUnassignOpen={setUnassignOpen}
              backNavigation={state?.back ?? '/'}
            />
            <Stack spacing={2}>
              <StyledTable>
                <StyledTableHead
                  headerData={[
                    {
                      id: 'field',
                      label: isMobile ? 'Field' : 'Field/Attribute',
                      width: '20%',
                    },
                    {
                      id: 'value',
                      label: isMobile ? 'Value' : 'Value/Data',
                    },
                  ]}
                />
                <TableBody>
                  <StyledTableRow>
                    <TableCell>Submission Date</TableCell>
                    <TableCell>{workflow.created.format(dateFormat)}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Initiator</TableCell>
                    <TableCell>{workflow.owner.name}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Assignee</TableCell>
                    <TableCell>
                      {workflow.currentTasks.map((c) => (
                        <Typography key={c.id} variant="body2">
                          {c.assignedTo.name}
                        </Typography>
                      ))}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Process Step</TableCell>
                    <TableCell>
                      {workflow.status} -{' '}
                      <MuiLink component={Link} to={`history`}>
                        View History
                      </MuiLink>
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </StyledTable>
              {inDepartment && (
                <>
                  <Typography variant="h3">Latest Task Data</Typography>
                  {workflow.definition.process.map((taskDefinition) => {
                    const latestTask = workflow.history.find(
                      (h) =>
                        h.complete &&
                        !h.reassigned &&
                        h.taskDefinition.id === taskDefinition.id
                    );
                    return latestTask ? (
                      <CustomDataTableTask
                        key={taskDefinition.id}
                        task={latestTask}
                        taskData={workflow.customData}
                      />
                    ) : (
                      <Fragment key={taskDefinition.id} />
                    );
                  })}
                </>
              )}
            </Stack>
            {workflow.notes.length > 0 && inDepartment && (
              <NotesTable workflow={workflow} />
            )}
            {assignedTask && (
              <ReassignForm
                open={reassignOpen}
                onClose={() => setReassignOpen(false)}
                onSubmit={onReassignFormSubmit}
                workflow={workflow}
                currentTasks={workflow.currentTasks}
              />
            )}

            <UnassignForm
              open={unassignOpen}
              onClose={() => setUnassignOpen(false)}
              onSubmit={onUnassignFormSubmit}
              currentTasks={workflow.currentTasks}
            />
            {noteOpen && (
              <AddNoteForm
                open={noteOpen}
                onClose={() => setNoteOpen(false)}
                onSubmit={onAddNoteFormSubmit}
                workflow={workflow}
              />
            )}
          </>
        ) : (
          <Typography>No workflow found.</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
