import { FormSelection } from '../../models';
import { FormSelectionModel } from '../interfaces/formSelection';

export function convertFormSelectionToModel(data: FormSelection) {
  const results: FormSelectionModel = {
    id: data.id,
    name: data.name,
    description: data.description,
    subName: data.subName,
    allowCustomSubChoice: data.allowCustomSubChoice,
    sections: data.sections,
  };

  return results;
}
