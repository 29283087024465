import { Grid } from '@mui/material';
import { FormElementModel } from '../../../datastore/interfaces/form';

export interface ElementLayoutProps {
  element?: FormElementModel;
  children: (string | JSX.Element | JSX.Element[])[];
}

export default function ElementLayout({
  element,
  children,
}: ElementLayoutProps) {
  return (
    <Grid
      container
      alignItems="start"
      spacing={1}
      sx={{
        display: element?.hidden ? 'none' : 'inherit',
        flex: element?.flex ?? 1,
      }}
    >
      {children}
    </Grid>
  );
}
