/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider,
  Paper,
  Stack,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  StyledTable,
  StyledTableHead,
  StyledTableRow,
} from '../../../components';
import { dateFormat } from '../../../common';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';
import { useCurrentUser } from '../../../hooks';

interface HistoryTableProps {
  workflow: WorkflowModel;
}

export default function HistoryTable({ workflow }: HistoryTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useCurrentUser();

  const inDepartment =
    workflow.definition.groups.includes(currentUser.department) ||
    workflow.definition.emails.includes(currentUser.email);

  const headerCells = [
    { id: 'step', label: 'Step #', width: '8%' },
    {
      id: 'status',
      label: 'Process Step',
      width: '15%',
    },
    {
      id: 'assignedTo',
      label: 'Assignee',
      width: '15%',
    },
    {
      id: 'started',
      label: 'Started',
      width: '10%',
    },
    {
      id: 'completed',
      label: 'Completed',
      width: '10%',
    },
  ];

  if (inDepartment) {
    headerCells.push({
      id: 'comments',
      label: 'Comments',
      width: '',
    });
  }

  return isMobile ? (
    <Stack spacing={2}>
      {workflow.history.map((task) => {
        return (
          <Paper key={task.id}>
            <Stack spacing={2} direction="row" sx={{ padding: '1em' }}>
              <Typography fontWeight="bold">{task.taskNumber}</Typography>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={1}>
                <Typography>Status - {task.status}</Typography>
                <Typography>Assigned - {task.assignedTo.name}</Typography>
                <Typography>
                  Started - {task.created.format(dateFormat)}
                </Typography>
                <Typography>
                  Completed -{' '}
                  {task.complete ? task.updated.format(dateFormat) : 'N/A'}
                </Typography>
                {inDepartment && (
                  <Typography>Comments - {task.comments}</Typography>
                )}
              </Stack>
            </Stack>
          </Paper>
        );
      })}
    </Stack>
  ) : (
    <Stack>
      <StyledTable>
        <StyledTableHead headerData={headerCells} />
        <TableBody>
          {workflow.history.map((task) => {
            return (
              <StyledTableRow key={task.taskNumber}>
                <TableCell>{task.taskNumber}</TableCell>
                <TableCell>{task.status}</TableCell>
                <TableCell>{task.assignedTo.name}</TableCell>
                <TableCell>{task.created.format(dateFormat)}</TableCell>
                <TableCell>
                  {task.complete ? task.updated.format(dateFormat) : '-'}
                </TableCell>
                {inDepartment && <TableCell>{task.comments}</TableCell>}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </Stack>
  );
}
