import dayjs from 'dayjs';
import { LocalAttachment } from '../interfaces/attachment';
import { FormModel, FormSectionModel } from '../../datastore/interfaces/form';
import { LazyTaskData } from '../../models';
import { FormElementTypes } from '../formElementTypes';
import { suffixDivider } from './suffixes';
import { DefaultElementDefinitions } from '../defaultElementDefinitions';
import { CurrentUserProps } from '../../hooks/useCurrentUser';

export function generateDefaultValues(
  formDefinition: FormModel,
  existingData: LazyTaskData[],
  requestor: CurrentUserProps
) {
  const results: any = {};
  for (const section of formDefinition?.sections) {
    let references: string[] = [];

    if (section.groups) {
      if (section.groups.fixed) {
        references = section.groups.names;
      } else {
        for (let i = 0; i < section.groups.maximum; i++) {
          const name =
            section.groups.names.length === 0
              ? ''
              : section.groups.names.length > i
              ? section.groups.names[i]
              : section.groups.names[0];
          references.push(`${name} ${i + 1}`);
        }
      }
    }

    const sectionResults = [];

    if (references.length) {
      for (const reference of references) {
        if (section.groups && !section.groups?.fixed) {
          const existingElements = section.elements.some((e) =>
            existingData.some(
              (ed) =>
                ed.reference ===
                `${e.dataReference}${suffixDivider}${reference}`
            )
          );
          if (!existingElements) {
            continue;
          }
        }

        sectionResults.push(
          generateDefaultSectionWithExistingCheck(
            section,
            existingData,
            reference,
            requestor
          )
        );
      }
    } else {
      sectionResults.push(
        generateDefaultSectionWithExistingCheck(
          section,
          existingData,
          '',
          requestor
        )
      );
    }
    results[section.id] = sectionResults;
  }

  return results;
}

function generateDefaultSectionWithExistingCheck(
  section: FormSectionModel,
  existingData: LazyTaskData[],
  reference: string | number | null,
  requestor: CurrentUserProps
) {
  const defaultElementValues: any = {};
  for (const element of section.elements.sort((a, b) => a.order - b.order)) {
    let existingDataReference = buildReferenceName(
      reference,
      element.dataReference,
      ''
    );
    const existing = existingData.find(
      (e) => e.reference === existingDataReference
    );

    let defaultElementValue = '';

    switch (element.default) {
      case DefaultElementDefinitions.Requestor:
        defaultElementValue = requestor.name;
        break;
      default:
        defaultElementValue = element.default ?? '';
        break;
    }

    switch (element.type) {
      case FormElementTypes.DateRange:
        const minName = `${element.dataReference} Minimum`;
        existingDataReference = buildReferenceName(
          reference,
          element.dataReference,
          ' Minimum'
        );
        const existingMinName = `${existingDataReference}`;
        const existingMin = existingData.find(
          (e) => e.reference === existingMinName
        );
        defaultElementValues[minName] =
          existingMin?.value ?? dayjs().toISOString();

        const maxName = `${element.dataReference} Maximum`;
        existingDataReference = buildReferenceName(
          reference,
          element.dataReference,
          ' Maximum'
        );
        const existingMaxName = `${existingDataReference}`;
        const existingMax = existingData.find(
          (e) => e.reference === existingMaxName
        );
        defaultElementValues[maxName] =
          existingMax?.value ?? dayjs().toISOString();
        break;
      case FormElementTypes.Assign:
      case FormElementTypes.User:
        const userName = `${element.dataReference} Name`;
        existingDataReference = buildReferenceName(
          reference,
          element.dataReference,
          ' Name'
        );
        const existingNameRef = `${existingDataReference}`;
        const existingName = existingData.find(
          (e) => e.reference === existingNameRef
        );
        defaultElementValues[userName] = existingName?.value ?? '';

        const emailName = `${element.dataReference} Email`;
        existingDataReference = buildReferenceName(
          reference,
          element.dataReference,
          ' Email'
        );
        const existingEmailName = `${existingDataReference}`;
        const existingEmail = existingData.find(
          (e) => e.reference === existingEmailName
        );
        defaultElementValues[emailName] = existingEmail?.value ?? '';
        break;
      case FormElementTypes.Attachment:
        try {
          if (existing) {
            const parsed = JSON.parse(existing.value);
            defaultElementValues[element.dataReference] =
              parsed as LocalAttachment[];
          }
        } catch {
          defaultElementValues[element.dataReference] =
            existing?.value ?? defaultElementValue;
        }
        break;
      case FormElementTypes.CheckBox:
        let value = existing?.value === 'true' ?? false;

        if (element.data) {
          try {
            const extraData = JSON.parse(element.data);
            if (extraData.forceFalseOnStart) {
              value = false;
            }
          } catch (e) {
            console.error(e);
          }
        }

        defaultElementValues[element.dataReference] = value;
        break;
      case FormElementTypes.CheckBoxSelect:
      case FormElementTypes.Select:
        const selection = element.selection!.sections.flatMap((s) => s.choices);
        let isCustom = false;
        if (selection.length && existing) {
          const dividedValues = existing.value.split(', ');

          for (const divValue of dividedValues) {
            const selectionValue = selection.find((s) => s.choice === divValue);
            //no choice must mean a custom call
            if (!selectionValue) {
              const valuesWithOther = [
                ...dividedValues.filter((d) => d !== divValue),
                selection.find((s) => s.isCustom)?.choice,
              ];
              defaultElementValues[element.dataReference] =
                valuesWithOther.join(', ');
              defaultElementValues[`${element.dataReference} Custom`] =
                divValue;
              isCustom = true;
              break;
            }
          }
        }
        if (!isCustom) {
          defaultElementValues[element.dataReference] =
            existing?.value ?? defaultElementValue;
        }
        break;
      default:
        defaultElementValues[element.dataReference] =
          existing?.value ?? defaultElementValue;
        break;
    }
  }

  return defaultElementValues;
}

export function generateDefaultSection(section: FormSectionModel) {
  const defaultElementValues: any = {};
  for (const element of section.elements.sort((a, b) => a.order - b.order)) {
    switch (element.type) {
      case FormElementTypes.DateRange:
        const minName = `${element.dataReference} Minimum`;
        defaultElementValues[minName] = dayjs().toISOString();

        const maxName = `${element.dataReference} Maximum`;
        defaultElementValues[maxName] = dayjs().toISOString();
        break;
      case FormElementTypes.CheckBox:
        defaultElementValues[element.dataReference] = false;
        break;
      default:
        defaultElementValues[element.dataReference] = '';
        break;
    }
  }

  return defaultElementValues;
}

function buildReferenceName(
  reference: string | number | null,
  dataReference: string,
  extra: string = ''
) {
  return reference
    ? `${dataReference}${extra}${suffixDivider}${reference}`
    : `${dataReference}${extra}`;
}
