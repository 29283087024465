import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isIsoDate } from '../dates/isIsoDate';
import { SearchConditions } from './searchConditions';
import { SearchFieldModel } from './searchFieldModel';
import { dateFormat } from '../dates/dateFormat';
import { departmentKey } from './departmentKey';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export function filterDataWithSearch(
  data: any,
  searchFields: SearchFieldModel[]
) {
  const results = data.filter((r: any) =>
    searchFields.every((searchField) => {
      if (
        searchField.value.length &&
        searchField.column.length &&
        searchField.column !== departmentKey
      ) {
        const valueLowerCase = searchField.value.toLowerCase();
        if (!valueLowerCase) {
          return true;
        }

        if (!r[searchField.column]) {
          return false;
        }
        switch (searchField.condition) {
          case SearchConditions.contains:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column])
                  .format(dateFormat)
                  .includes(valueLowerCase)
              : r[searchField.column].toLowerCase().includes(valueLowerCase);
          case SearchConditions.doesNotContain:
            return isIsoDate(r[searchField.column])
              ? !dayjs(r[searchField.column])
                  .format(dateFormat)
                  .includes(valueLowerCase)
              : !r[searchField.column].toLowerCase().includes(valueLowerCase);
          case SearchConditions.equals:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column]).format(dateFormat) ===
                  valueLowerCase
              : r[searchField.column].toLowerCase() === valueLowerCase;
          case SearchConditions.notEquals:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column]).format(dateFormat) !==
                  valueLowerCase
              : r[searchField.column].toLowerCase() !== valueLowerCase;
          case SearchConditions.greaterThan:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column]).isAfter(
                  dayjs(valueLowerCase, dateFormat)
                )
              : r[searchField.column].toLowerCase() > valueLowerCase;
          case SearchConditions.greaterThanOrEqualTo:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column]).isSameOrAfter(
                  dayjs(valueLowerCase, dateFormat)
                )
              : r[searchField.column].toLowerCase() >= valueLowerCase;
          case SearchConditions.lessThan:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column]).isBefore(
                  dayjs(valueLowerCase, dateFormat)
                )
              : r[searchField.column].toLowerCase() < valueLowerCase;
          case SearchConditions.lessThanOrEqualTo:
            return isIsoDate(r[searchField.column])
              ? dayjs(r[searchField.column]).isSameOrBefore(
                  dayjs(valueLowerCase, dateFormat)
                )
              : r[searchField.column].toLowerCase() <= valueLowerCase;
          default:
            return false;
        }
      }

      return true;
    })
  );

  return results;
}
