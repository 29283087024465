/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Chip,
  Stack,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  LoadingSpinner,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
} from '../../components';
import { useCurrentUser, useDatastore } from '../../hooks';
import { useEffect, useState, Fragment } from 'react';
import { SortKey, sortData } from '../../common/arrays/sorting';
import StyledTableFooter from '../../components/tables/StyledTableFooter';
import { dateFormat, userCanEnterForm } from '../../common';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TableFeatures from './components/TableFeatures';
import { WorkflowDefinitionModel } from '../../datastore/interfaces/workflowDefinition';
import { WorkflowModel } from '../../datastore/interfaces/workflow';

const headerCells = [
  { id: 'referenceNumber', label: 'Ref.' },
  {
    id: 'definition.name',
    label: 'Process',
  },
  {
    id: 'status',
    label: 'Process Step',
  },
  {
    id: 'currentTasks.0.taskDefinition.description',
    label: 'Description',
  },
  {
    id: 'owner.name',
    label: 'Initiator',
  },
  {
    id: 'created',
    label: 'Submission Date',
  },
  {
    id: 'currentTasks.0.dateDue',
    label: 'Due Date',
  },
  {
    id: 'currentTasks.0.assignedTo.name',
    label: 'Assignee',
  },
  {
    id: 'actions',
    label: '',
  },
];

export default function Home() {
  const store = useDatastore();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState<WorkflowModel[]>([]);
  const [workflowDefintions, setWorkflowDefinitions] = useState<
    WorkflowDefinitionModel[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allToggle, setAllToggle] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sort, setSort] = useState<SortKey>({
    key: headerCells[0].id,
    direction: 'desc',
  });

  const sortedData = sortData(
    data.filter(
      (d) =>
        (allToggle || !d.complete) &&
        (!search.length ||
          [
            d.referenceNumber,
            d.status,
            d.description,
            d.definition.name,
            d.owner,
            d.currentTasks.map((c) => c.assignedTo.name),
            d.currentTasks.map((c) => c.dateDue?.format(dateFormat) ?? 'N/A'),
          ]
            .join(' ')
            .toLowerCase()
            .includes(search.toLowerCase()))
    ),
    sort
  ) as WorkflowModel[];

  useEffect(() => {
    async function getData() {
      const results = await store.workflows.getListByAssignedTo(
        currentUser.email
      );
      if (results) {
        setData(results);
      }

      const workflows = await store.workflowDefinitions.getList();
      if (workflows) {
        setWorkflowDefinitions(workflows);
      }

      setIsLoading(false);
    }

    const savedSearch = localStorage.getItem(
      `groupTableSearch-home-${currentUser.id}}`
    );
    if (savedSearch) {
      setSearch(savedSearch);
    }

    getData();
  }, []);

  async function onNewSelect(id: string) {
    navigate(`/createWorkflow/${id}/`);
  }

  function handleSearchChange(value: string) {
    setSearch(value);
    localStorage.setItem(`groupTableSearch-home-${currentUser.id}}`, value);
  }

  return (
    <Stack spacing={4}>
      <Stack
        spacing={2}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Typography variant="h1" sx={{ flex: 1 }}>
          My Tasks
        </Typography>

        <TableFeatures
          search={search}
          allToggle={allToggle}
          setSearch={handleSearchChange}
          setAllToggle={setAllToggle}
          workflowDefinitions={workflowDefintions}
          onNewSelect={onNewSelect}
        />
      </Stack>
      <LoadingSpinner isLoading={isLoading}>
        {sortedData.length ? (
          <StyledTable>
            <StyledTableHead
              headerData={headerCells}
              sort={sort}
              setSort={setSort}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? sortedData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : sortedData
              ).map((workflow) => {
                return (
                  <StyledTableRow key={workflow.id}>
                    <TableCell>
                      <Button
                        sx={{
                          padding: '0.2em 0.1em',
                        }}
                        onClick={() =>
                          navigate(`/workflows/${workflow.referenceNumber}`, {
                            state: {
                              back: '/',
                            },
                          })
                        }
                      >
                        {workflow.referenceNumber}
                      </Button>
                    </TableCell>
                    <TableCell>{workflow.definition.name}</TableCell>
                    <TableCell>
                      <Chip label={workflow.status} />
                    </TableCell>
                    <TableCell>{workflow.description}</TableCell>
                    <TableCell>{workflow.owner.name}</TableCell>
                    <TableCell>{workflow.created.format(dateFormat)}</TableCell>
                    <TableCell>
                      {workflow.currentTasks[0]?.dateDue?.format(dateFormat) ??
                        'N/A'}
                    </TableCell>
                    <TableCell>
                      {workflow.currentTasks.map((c) => (
                        <Typography key={c.id} variant="body2">
                          {c.assignedTo.name}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      {!workflow.complete &&
                        workflow.currentTasks.map((c) => {
                          if (
                            userCanEnterForm(
                              c,
                              workflow.definition,
                              currentUser
                            )
                          ) {
                            return (
                              <Button
                                key={c.id}
                                variant="outlined"
                                onClick={() =>
                                  navigate(
                                    `/task/${workflow.referenceNumber}/${c.taskNumber}`,
                                    { state: { origin: '/' } }
                                  )
                                }
                              >
                                Action
                              </Button>
                            );
                          }
                          return <Fragment key={c.id} />;
                        })}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <StyledTableFooter
              data={sortedData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              type="home"
            />
          </StyledTable>
        ) : (
          <Typography>No Tasks found</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
