/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import { LoadingSpinner } from '../../components';
import { useCurrentUser, useDatastore } from '../../hooks';
import { useEffect, useState } from 'react';

import { WorkflowDefinitionModel } from '../../datastore/interfaces/workflowDefinition';
import { WorkflowModel } from '../../datastore/interfaces/workflow';
import GroupTable from './components/GroupTable';

export default function GroupWorkflows() {
  const store = useDatastore();
  const currentUser = useCurrentUser();
  const [data, setData] = useState<WorkflowModel[]>([]);
  const [workflowDefinitions, setWorkflowDefinitions] = useState<
    WorkflowDefinitionModel[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const definitions =
        await store.workflowDefinitions.getListByDepartmentOrEmail(
          currentUser.department,
          currentUser.email
        );
      if (definitions) {
        setWorkflowDefinitions(definitions);

        const results = await store.workflows.getListByDefinitions(
          definitions.map((w) => w.id)
        );
        if (results) {
          setData(results);
        }
      }

      setIsLoading(false);
    }

    getData();
  }, []);

  return (
    <Stack spacing={4}>
      <LoadingSpinner isLoading={isLoading}>
        {workflowDefinitions.length > 0 ? (
          workflowDefinitions.map((definition) => {
            return (
              <GroupTable
                key={definition.id}
                workflowDefinition={definition}
                workflows={data.filter((d) => d.definitionId === definition.id)}
              />
            );
          })
        ) : (
          <Typography>Your department does not have any Workflows.</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
