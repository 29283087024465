/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import {
  useAlert,
  useCurrentUser,
  useDatastore,
  useLoading,
} from '../../hooks';
import { useEffect, useState } from 'react';
import {
  CustomDataTableReferences,
  Form,
  LoadingSpinner,
} from '../../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WorkflowModel } from '../../datastore/interfaces/workflow';
import { Owner, TaskData } from '../../models';
import { userCanEnterForm } from '../../common';

interface ViewPreviousDataDetails {
  label: string;
  openOnStart?: boolean;
  data: string[];
}

export default function ActionTask() {
  const { referenceNumber, taskNumber } = useParams();
  const store = useDatastore();
  const alert = useAlert();
  const setLoading = useLoading();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const [workflow, setWorkflow] = useState<WorkflowModel | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const task = workflow?.history.find(
    (h) => h.taskNumber.toString() === taskNumber
  );

  useEffect(() => {
    async function getData() {
      if (referenceNumber && taskNumber) {
        const results = await store.workflows.getByReferenceNumber(
          referenceNumber
        );
        if (results) {
          setWorkflow(results);
        }
      }

      setIsLoading(false);
    }

    getData();
  }, []);

  async function onComplete(
    data: TaskData[],
    assignee: Owner | undefined,
    triggers: string[]
  ) {
    if (!workflow || !task) {
      alert('Cannot save, Task not found', 'error');
      return;
    }
    setLoading(true);

    task.data = data;
    await store.workflows.completeTask(workflow, task, '', assignee, triggers);

    setLoading(false);
    alert('Task submitted. Thank you!', 'success');
    navigate(state?.origin || '/');
  }

  const previousData: ViewPreviousDataDetails[] = [];

  if (task?.taskDefinition.viewPreviousData) {
    try {
      for (const data of task.taskDefinition.viewPreviousData) {
        const result: ViewPreviousDataDetails = JSON.parse(data);
        previousData.push(result);
      }
    } catch (e) {
      previousData.push({
        label: 'Reference',
        openOnStart: false,
        data: task.taskDefinition.viewPreviousData,
      });
    }
  }

  return (
    <Stack spacing={2}>
      <LoadingSpinner isLoading={isLoading}>
        {workflow && task ? (
          userCanEnterForm(task, workflow.definition, currentUser) ? (
            <>
              <Typography variant="h1">{task.taskDefinition.name}</Typography>
              {previousData.map((data) => {
                return (
                  <CustomDataTableReferences
                    key={data.label}
                    title={data.label}
                    references={data.data}
                    startOpen={data.openOnStart ?? false}
                    taskData={workflow.customData}
                  />
                );
              })}
              <Form
                existingData={workflow.customData}
                taskDefinition={task.taskDefinition}
                workflow={workflow}
                onComplete={onComplete}
              />
            </>
          ) : (
            <Typography>You are not eligible for this Task.</Typography>
          )
        ) : (
          <Typography>No task found.</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
