import { TaskData } from '../../models';
import { suffixDivider } from '../form/suffixes';

export function parseReferencesFromGroups(
  taskData: TaskData[],
  references: string[]
) {
  return taskData.filter((t) => {
    const split = t.reference?.split(suffixDivider);
    if (split && split.length > 0) {
      return references.includes(split[0]);
    }

    return false;
  });
}

export function parseReferences(taskData: TaskData[], references: string[]) {
  return taskData.filter((t) => {
    const split = t.reference?.split(' ');
    if (split && split.length > 0) {
      return references.includes(split[0]);
    }

    return false;
  });
}
