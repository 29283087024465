import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputComponentProps } from './Interfaces';
import { PatternFormat } from 'react-number-format';

export default function PhoneField({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  return (
    <Controller
      control={control}
      name={`${idPrefix}${element.dataReference}`}
      rules={{
        required: element.required,
      }}
      render={({ field }) => (
        <PatternFormat
          value={field.value}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          inputRef={field.ref}
          customInput={TextField}
          disabled={element.readonly ?? false}
          fullWidth
          size="small"
          format="#### ### ###"
        />
      )}
    />
  );
}
