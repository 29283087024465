/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Switch, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCSVDownloader } from 'react-papaparse';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { parseDataRef } from '../../../common';

interface TableFeaturesProps {
  workflowName: string;
  data: any[];
  allToggle: boolean;
  searchOn: boolean;
  setAllToggle: (toggle: boolean) => void;
  setSearchOn: (on: boolean) => void;
}

export default function TableFeatures({
  workflowName,
  data,
  allToggle,
  setAllToggle,
  searchOn,
  setSearchOn,
}: TableFeaturesProps) {
  const { CSVDownloader } = useCSVDownloader();
  const dataWithoutIdAndParsed = data.map(({ id, ...d }) => {
    const result: any = {};
    for (const key of Object.keys(d)) {
      result[key] = parseDataRef(d[key]);
    }

    return result;
  });
  return (
    <>
      <Stack direction="row" sx={{ flex: 1, alignItems: 'center' }}>
        <Typography>Requires Action</Typography>
        <Switch
          color="primary"
          value={allToggle}
          onChange={() => setAllToggle(!allToggle)}
        />
        <Typography>All</Typography>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        sx={{ flex: 2, justifyContent: 'end', alignItems: 'center' }}
      >
        <Button
          variant={searchOn ? 'contained' : 'outlined'}
          onClick={() => setSearchOn(!searchOn)}
        >
          <SearchIcon />
        </Button>
        <CSVDownloader
          bom
          filename={`${workflowName.replace('.', '-')}-${dayjs().format(
            'YYYY-MM-DD'
          )}`}
          data={dataWithoutIdAndParsed}
        >
          <Button variant="outlined" startIcon={<DownloadIcon />}>
            CSV
          </Button>
        </CSVDownloader>
      </Stack>
    </>
  );
}
