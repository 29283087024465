import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { searchOkta } from '../../graphQL';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { OktaUser } from '../../models';
import { useCurrentUser } from '../../hooks';

interface OktaLookupProps {
  defaultValue?: string;
  allowSelf?: boolean;
  hasError?: boolean;
  onSelect: (value: OktaUser) => void;
  onClear: () => void;
}

interface OktaResult {
  searchOkta: string;
}

export default function OktaLookup({
  onSelect,
  onClear,
  defaultValue = '',
  allowSelf = false,
  hasError = false,
}: OktaLookupProps) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<OktaUser[]>([]);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [result, setResult] = useState(defaultValue);
  const [hasSelected, setHasSelected] = useState(defaultValue.length > 0);
  const currentUser = useCurrentUser();

  function onSearchChange(value: string) {
    setResult(value);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => getUsers(value), 500);
    setTimer(timeoutId);
  }

  function onAutoCompleteChange(value: string) {
    if (!value.length) {
      setResult('');
      setHasSelected(false);
      onClear();
    }
    const match = searchResults.find(
      (s) => value.startsWith(s.name) && value.includes(s.email)
    );

    if (match) {
      setResult(value);
      setHasSelected(true);
      onSelect(match);
    }
  }

  async function getUsers(value: string) {
    if (isSearching || value.length < 3) {
      return;
    }
    setIsSearching(true);
    const result = (await API.graphql(
      graphqlOperation(searchOkta, { search: value })
    )) as GraphQLResult<OktaResult>;

    if (result.data && result.data.searchOkta) {
      const body: OktaUser[] = JSON.parse(result.data.searchOkta);
      setSearchResults(
        process.env.REACT_APP_ENV === 'prod' && !allowSelf
          ? body.filter((b) => b.email !== currentUser.email)
          : body
      );
    }

    setIsSearching(false);
  }

  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
      <Autocomplete
        freeSolo
        fullWidth
        value={result}
        onChange={(e, newValue) => onAutoCompleteChange(newValue ?? '')}
        options={searchResults.map(
          (result) => `${result.name} (${result.email})`
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={isSearching}
            error={hasError}
            onChange={(e) => onSearchChange(e.target.value)}
            fullWidth
            size="small"
            helperText={hasSelected ? '' : 'Select a user from Okta.'}
          />
        )}
      />
      {isSearching ? <CircularProgress /> : <></>}
    </Stack>
  );
}
