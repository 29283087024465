import { WorkflowTaskModel } from '../../datastore/interfaces/workflow';
import { WorkflowDefinitionModel } from '../../datastore/interfaces/workflowDefinition';
import { CurrentUserProps } from '../../hooks/useCurrentUser';

export function userCanEnterForm(
  task: WorkflowTaskModel,
  workflowDefinition: WorkflowDefinitionModel,
  currentUser: CurrentUserProps
) {
  const taskGroups = task.taskDefinition.groups.length
    ? task.taskDefinition.groups
    : workflowDefinition.groups;
  const taskEmails = task.taskDefinition.emails.length
    ? task.taskDefinition.emails
    : workflowDefinition.emails;

  const lowerEmail = currentUser.email.toLowerCase();
  const lowerAssigned = task.assignedTo.email.toLowerCase();

  return (
    lowerEmail === lowerAssigned ||
    (!lowerAssigned.length &&
      (taskGroups.includes(currentUser.department) ||
        taskEmails.includes(lowerEmail)))
  );
}
