import { Typography } from '@mui/material';
import Markdown from 'react-markdown';

interface HelpFieldProps {
  message?: string;
}

export default function HelpField({ message }: HelpFieldProps) {
  return message?.length ? (
    <Markdown
      components={{
        p({ children }) {
          return <Typography variant="body2">{children}</Typography>;
        },
      }}
    >
      {message}
    </Markdown>
  ) : (
    <></>
  );
}
