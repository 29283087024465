import {
  FormControl,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputComponentProps } from './Interfaces';

export default function RadioField({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  const selection = element.selection;
  const elementId = `${idPrefix}${element.dataReference}`;

  if (!selection) {
    return (
      <Typography>
        Error! Radio element does not have any selection data.
      </Typography>
    );
  }

  return (
    <FormControl disabled={element.readonly ?? false}>
      <Controller
        control={control}
        name={elementId}
        rules={{ required: element.required }}
        render={({ field }) => (
          <RadioGroup row {...field}>
            {selection.sections.map((section) => {
              return section.choices.map((c) => {
                return (
                  <FormControlLabel
                    key={c.choice}
                    value={c.choice}
                    control={<Radio />}
                    label={c.choice}
                  />
                );
              });
            })}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
