import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export default function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
}
