/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  useFieldArray,
  UseFormWatch,
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
  UseFormClearErrors,
} from 'react-hook-form';
import {
  FormSectionDirection,
  generateDefaultSection,
  getValue,
} from '../../common';

import { useTheme } from '@mui/material/styles';
import { WorkflowModel } from '../../datastore/interfaces/workflow';
import {
  FormElementModel,
  FormModel,
  FormSectionModel,
} from '../../datastore/interfaces/form';
import { FormElementVisibility } from '../../common/interfaces/formElementVisiblity';
import { Fragment } from 'react';
import {
  ElementInput,
  ElementLabel,
  ElementLayout,
  GetInput,
} from './components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FormOutcomes } from '../../common/interfaces/formOutcomes';

interface InnerFormProps {
  formDefinition: FormModel;
  watch: UseFormWatch<any>;
  control: Control<any>;
  workflow: WorkflowModel;
  errors: FieldErrors;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  clearErrors: UseFormClearErrors<any>;
  outcomesOverride?: FormOutcomes[];
  visibility: FormElementVisibility[];
  skipSubmitButton?: boolean;
}

export interface FormResultsData {
  key: string;
  group?: string;
  value: any;
  groupId: string;
  sectionId: string;
  index: number;
}

interface FormRenderSectionProps {
  section: FormSectionModel;
}

interface FormRenderSectionInnerProps {
  section: FormSectionModel;
  fields: Record<'id', string>[];
  groups?: string[];
}

interface FormRenderSubmitButtonProps {
  element: FormElementModel;
}

export default function InnerForm({
  formDefinition,
  watch,
  control,
  workflow,
  errors,
  setValue,
  getValues,
  clearErrors,
  outcomesOverride,
  visibility,
  skipSubmitButton,
}: InnerFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const sections = formDefinition.sections.sort(
    (a, b) =>
      Math.min(...a.elements.map((ae) => ae.order)) -
      Math.min(...b.elements.map((be) => be.order))
  );

  const outcomes: FormOutcomes[] =
    outcomesOverride ??
    sections.flatMap((s) =>
      s.elements
        .sort((a, b) => a.order - b.order)
        .map((e, i) => {
          return {
            order: e.order,
            sectionOrder: i,
            section: s.name,
            currentElement: e,
            nextElements: e.outcomes.map((o) => {
              return {
                ...o,
                nextElement: formDefinition.sections
                  .flatMap((s) => s.elements)
                  .find((fe) => fe.id === o.nextElement),
              };
            }),
          };
        })
    );

  if (outcomes.length === 0) {
    return <Typography>Error! Form does not have any elements.</Typography>;
  }

  function isElementVisible(
    element: FormElementModel,
    section: FormSectionModel,
    visibility: FormElementVisibility[]
  ) {
    const previousOutcomes = outcomes.filter((o) =>
      o.nextElements.some((p) => p.nextElement?.id === element.id)
    );
    const previousNextOutcomes = previousOutcomes.flatMap((o) =>
      o.nextElements.filter((p) => p.nextElement?.id === element.id)
    );

    //this one requires a trigger check
    if (previousOutcomes?.length) {
      let hasTrigger = false;

      const visiblePrevious = previousOutcomes.filter((p) =>
        visibility.some((v) => v.id === p.currentElement.id && v.isVisible)
      );

      const emptyPrevious = previousOutcomes.filter((p) =>
        p.nextElements.some((n) => !n.triggers.length)
      );

      const emptyNextPrevious = previousNextOutcomes.filter(
        (p) => !p.triggers.length
      );

      if (
        emptyNextPrevious.length &&
        visiblePrevious.some((v) =>
          emptyPrevious.some((e) => e.currentElement.id === v.currentElement.id)
        )
      ) {
        hasTrigger = true;
      } else if (visiblePrevious.length) {
        for (const previous of visiblePrevious) {
          //TODO: we treat grouped elements the same, so at this stage cannot hide/show individual elements in a group
          const watchElement = watch(
            `${section.id}.0.${previous.currentElement.dataReference}`
          );
          const elementsToCheck =
            typeof watchElement === 'string' || watchElement instanceof String
              ? watchElement.split(', ')
              : [`${watchElement}`];
          for (const check of elementsToCheck) {
            if (
              previousNextOutcomes.filter((p) => p.triggers.includes(check))
                .length
            ) {
              hasTrigger = true;
              break;
            }
          }
        }
      }

      visibility.push({ id: element.id, isVisible: hasTrigger });
      return hasTrigger;
    }

    visibility.push({ id: element.id, isVisible: true });
    return true;
  }

  function SubmitButton({ element }: FormRenderSubmitButtonProps) {
    const currentOutcomes = outcomes.filter(
      (o) => o.currentElement.id === element.id
    );
    const nextOutcomes = outcomes.filter((o) =>
      currentOutcomes
        .flatMap((c) => c.nextElements)
        .some((n) => n.nextElement?.id === o.currentElement.id)
    );
    return nextOutcomes?.length || skipSubmitButton ? (
      <></>
    ) : (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: isMobile ? 'center' : 'flex-end',
        }}
      >
        <Button
          type="submit"
          sx={{
            padding: '0.5em 2em',
            width: isMobile ? '100%' : 'auto',
          }}
          variant="contained"
        >
          Submit
        </Button>
      </Stack>
    );
  }

  function RenderInnerSection({
    section,
    fields,
    groups = [],
  }: FormRenderSectionInnerProps) {
    return (
      <>
        {section.elements
          .sort((a, b) => a.order - b.order)
          .map((element) => {
            return isElementVisible(element, section, visibility) ? (
              <Fragment key={element.id}>
                <ElementLayout element={element}>
                  <ElementLabel
                    element={element}
                    direction={section.direction}
                  />
                  {fields.map((field, index) => {
                    const idPrefix = `${section.id}.${index}.`;
                    const error = getValue(
                      `${section.id}.${index}.${element.dataReference}`,
                      errors
                    );
                    const group = groups.length > index ? groups[index] : '';
                    const isLast = index + 1 >= fields.length;
                    return (
                      <ElementInput
                        key={field.id}
                        element={element}
                        direction={section.direction}
                        error={error}
                        showHelp={isLast}
                      >
                        {isMobile && group.length > 0 ? (
                          <Typography variant="caption">{group}</Typography>
                        ) : (
                          <></>
                        )}
                        <GetInput
                          element={element}
                          errors={errors}
                          control={control}
                          workflow={workflow}
                          setValue={setValue}
                          getValues={getValues}
                          clearErrors={clearErrors}
                          idPrefix={idPrefix}
                        />
                      </ElementInput>
                    );
                  })}
                </ElementLayout>
                <SubmitButton element={element} />
              </Fragment>
            ) : (
              <Fragment key={element.id}></Fragment>
            );
          })}
      </>
    );
  }

  function RenderSection({ section }: FormRenderSectionProps) {
    const { fields } = useFieldArray({
      control,
      name: section.id,
    });

    return (
      <Stack
        direction={
          section.direction === FormSectionDirection.Horizontal
            ? 'row'
            : 'column'
        }
        spacing={section.direction === FormSectionDirection.Horizontal ? 1 : 0}
        justifyContent="space-between"
      >
        <RenderInnerSection section={section} fields={fields} />
      </Stack>
    );
  }

  function RenderMultipleSections({ section }: FormRenderSectionProps) {
    const { fields, append, remove } = useFieldArray({
      control,
      name: section.id,
    });

    if (!section.groups) {
      return (
        <Typography>
          Error! This form section is set to render multiple, yet has no data
          for it.
        </Typography>
      );
    }

    return (
      <Stack spacing={1}>
        <Stack
          direction={
            section.direction === FormSectionDirection.Horizontal && !isMobile
              ? 'row'
              : 'column'
          }
          spacing={
            section.direction === FormSectionDirection.Horizontal ? 1 : 0
          }
          //needed to counteract the spacing above
          sx={
            section.direction === FormSectionDirection.Horizontal
              ? { marginLeft: '-0.5em !important', alignItems: 'start' }
              : {}
          }
        >
          {section.groups.fixed && !isMobile && (
            <ElementLayout>
              <ElementLabel direction={section.direction} />
              {section.groups.names.map((name) => (
                <ElementInput key={name} direction={section.direction}>
                  <Typography variant="h4">{name}</Typography>
                </ElementInput>
              ))}
            </ElementLayout>
          )}
          <RenderInnerSection
            section={section}
            groups={section.groups.fixed ? section.groups.names : []}
            fields={fields}
          />
        </Stack>
        {fields.length === 0 && <Typography>No entries added.</Typography>}
        {!section.groups.fixed && (
          <Stack direction="row" spacing={2}>
            {fields.length < Number(section.groups.maximum) && (
              <Button
                variant="contained"
                onClick={() => append(generateDefaultSection(section))}
              >
                <AddIcon />
              </Button>
            )}
            {fields.length !== 0 && (
              <Button
                variant="outlined"
                onClick={() => remove(fields.length - 1)}
              >
                <RemoveIcon />
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={4}>
      {sections.map((section) => {
        return (
          <Fragment key={section.id}>
            {section.name.length > 0 && (
              <Stack sx={{ paddingTop: '1em' }}>
                <Typography variant="h3" fontWeight="bold">
                  {section.name}
                </Typography>
                <Divider />
              </Stack>
            )}
            {section.groups ? (
              <RenderMultipleSections key={section.id} section={section} />
            ) : (
              <RenderSection key={section.id} section={section} />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
}
