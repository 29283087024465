import { createContext, useContext, useEffect, useState } from 'react';
import { Auth, DataStore, Hub } from 'aws-amplify';
import { useLoading } from './useLoading';
import { Login } from '../pages';
import { Owner } from '../models';

interface UserProviderProps {
  children?: React.ReactNode;
}

export interface CurrentUserProps {
  cognitoUser?: any;
  id: string;
  name: string;
  email: string;
  groups: string[];
  department: string;
  hasGroup: (group: string) => boolean;
  hasAtLeastOneGroup: (groups: string[]) => boolean;
  getOwner: () => Owner;
}

const UserContext = createContext<CurrentUserProps>({} as CurrentUserProps);

const useCurrentUser = () => useContext(UserContext);

const UserLoginProvider = ({ children }: UserProviderProps) => {
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const setLoading = useLoading();

  useEffect(() => {
    const listener = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => {
            setCognitoUser(userData);
          });
          break;
        case 'signOut':
        case 'oAuthSignOut':
          logout();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        case 'tokenRefresh_failure':
          console.error('token refresh failed');
          logout();
          Auth.signOut();
          break;
      }
    });

    getUser().then((userData) => {
      setCognitoUser(userData);
    });

    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUser() {
    try {
      setLoading(true);
      const userData = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setLoading(false);
      return userData;
    } catch {
      setLoading(false);
      console.log('Not signed in');
    }
  }

  function login() {
    Auth.federatedSignIn({ customProvider: 'LoginWithOkta' });
  }

  function logout() {
    setCognitoUser(null);
    DataStore.clear();
  }

  function getGroups() {
    if (cognitoUser?.attributes) {
      const customGroups: string = cognitoUser?.attributes['custom:groups'];

      if (customGroups) {
        const parsed = customGroups
          .replace('[', '')
          .replace(']', '')
          .split(', ');
        return parsed;
      }
    }
    return [];
  }

  function hasGroup(group: string) {
    const groups = getGroups();

    return groups.includes(`${process.env.REACT_APP_GROUP_PREFIX}.${group}`);
  }

  function hasAtLeastOneGroup(groups: string[]) {
    for (const group of groups) {
      if (hasGroup(group)) {
        return true;
      }
    }

    return false;
  }

  const currentUser = {
    cognitoUser,
    id: cognitoUser?.username,
    name: `${cognitoUser?.attributes?.given_name} ${cognitoUser?.attributes?.family_name}`,
    email: cognitoUser?.attributes?.email.toLowerCase(),
    groups: getGroups(),
    department: cognitoUser?.attributes['custom:department'] ?? '',
    hasGroup: hasGroup,
    hasAtLeastOneGroup: hasAtLeastOneGroup,
    getOwner: () => {
      return {
        name: `${cognitoUser?.attributes?.given_name} ${cognitoUser?.attributes?.family_name}`,
        email: cognitoUser?.attributes?.email,
      };
    },
  };

  const value = currentUser;

  return cognitoUser && Object.entries(cognitoUser).length ? (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  ) : (
    <Login login={login} />
  );
};

export { useCurrentUser, UserLoginProvider };
