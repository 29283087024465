import { CircularProgress } from '@mui/material';

interface LoadingSpinnerProps {
  isLoading: boolean;
  children: JSX.Element | JSX.Element[];
}

export default function LoadingSpinner({
  isLoading,
  children,
}: LoadingSpinnerProps) {
  return isLoading ? <CircularProgress /> : <>{children}</>;
}
