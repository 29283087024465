import { Checkbox } from '@mui/material';
import { Controller, Control, UseFormSetValue } from 'react-hook-form';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { getValue } from '../../../common';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';

export interface CheckBoxFieldProps {
  element: FormElementModel;
  control: Control<any>;
  workflow: WorkflowModel;
  setValue: UseFormSetValue<any>;
  idPrefix: string;
}

export default function CheckBoxField({
  element,
  control,
  workflow,
  setValue,
  idPrefix,
}: CheckBoxFieldProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (element.data?.length && event.target.checked) {
      try {
        const data = JSON.parse(element.data);
        if (data.ifTrue?.dataReference && data.ifTrue.workflowReference) {
          setValue(
            `${idPrefix}${data.ifTrue.dataReference}`,
            getValue(`${idPrefix}${data.ifTrue.workflowReference}`, workflow)
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  return (
    <Controller
      name={`${idPrefix}${element.dataReference}`}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...field}
          checked={field.value}
          disabled={element.readonly ?? false}
          onChange={(e) => {
            field.onChange(e.target.checked);
            handleChange(e);
          }}
        />
      )}
    />
  );
}
