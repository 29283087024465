import { Paper, Table, TableProps } from '@mui/material';

export default function StyledTable(props: TableProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '0 0 1em 1em',
        overflow: 'auto',
        boxShadow:
          '0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F',
      }}
    >
      <Table sx={{ padding: '0 0.5em' }}>{props.children}</Table>
    </Paper>
  );
}
