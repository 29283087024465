// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const {
  WorkflowDefinition,
  TaskDefinition,
  Form,
  FormSection,
  FormElement,
  FormSelection,
  Workflow,
  Task,
  CustomColumn,
  TaskOutcomeDefinition,
  TaskEmailDefinition,
  TaskEmailTemplate,
  TaskEmailTable,
  TaskEmailTableRow,
  TaskEmailTableCell,
  FormSectionGroups,
  FormSelectionSection,
  FormSelectionChoice,
  FormOutcome,
  WorkflowNote,
  Attachment,
  TaskData,
  Owner,
  OktaUser,
} = initSchema(schema);

export {
  WorkflowDefinition,
  TaskDefinition,
  Form,
  FormSection,
  FormElement,
  FormSelection,
  Workflow,
  Task,
  CustomColumn,
  TaskOutcomeDefinition,
  TaskEmailDefinition,
  TaskEmailTemplate,
  TaskEmailTable,
  TaskEmailTableRow,
  TaskEmailTableCell,
  FormSectionGroups,
  FormSelectionSection,
  FormSelectionChoice,
  FormOutcome,
  WorkflowNote,
  Attachment,
  TaskData,
  Owner,
  OktaUser,
};
