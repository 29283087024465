import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { FormElementTypes } from '../../../common';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';
import {
  AssignField,
  Attachments,
  CheckBoxField,
  CheckBoxSelectField,
  CostField,
  DataReferenceField,
  DateField,
  DateRange,
  EmailField,
  InfoField,
  MultiOktaField,
  MultiTextField,
  NumberField,
  PhoneField,
  SelectField,
  TextFormField,
  RadioField,
} from '../fields';

interface FormInputProps {
  element: FormElementModel;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  errors: FieldErrors;
  control: Control<any>;
  workflow: WorkflowModel;
  idPrefix: string;
}

export function GetInput({
  element,
  errors,
  control,
  workflow,
  setValue,
  getValues,
  clearErrors,
  idPrefix,
}: FormInputProps) {
  const error = errors[element.dataReference];
  switch (element.type) {
    case FormElementTypes.TextField:
    case FormElementTypes.MediumTextField:
    case FormElementTypes.LargeTextField:
    case FormElementTypes.Comments:
      return (
        <TextFormField
          element={element}
          idPrefix={idPrefix}
          control={control}
          error={error}
        />
      );
    case FormElementTypes.MultiTextField:
      return (
        <MultiTextField
          element={element}
          idPrefix={idPrefix}
          control={control}
        />
      );
    case FormElementTypes.Currency:
      return (
        <CostField element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.Number:
      return (
        <NumberField element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.PhoneNumber:
      return (
        <PhoneField element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.EmailField:
      return (
        <EmailField
          element={element}
          idPrefix={idPrefix}
          control={control}
          error={error}
        />
      );
    case FormElementTypes.Select:
      return (
        <SelectField
          element={element}
          idPrefix={idPrefix}
          control={control}
          errors={errors}
        />
      );
    case FormElementTypes.CheckBoxSelect:
      return (
        <CheckBoxSelectField
          element={element}
          idPrefix={idPrefix}
          control={control}
          setValue={setValue}
          errors={errors}
        />
      );
    case FormElementTypes.CheckBox:
      return (
        <CheckBoxField
          element={element}
          idPrefix={idPrefix}
          control={control}
          workflow={workflow}
          setValue={setValue}
        />
      );
    case FormElementTypes.Radio:
      return (
        <RadioField element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.DateField:
      return (
        <DateField element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.DateRange:
      return (
        <DateRange element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.Attachment:
      return (
        <Attachments element={element} idPrefix={idPrefix} control={control} />
      );
    case FormElementTypes.Assign:
    case FormElementTypes.User:
      return (
        <AssignField
          element={element}
          idPrefix={idPrefix}
          getValues={getValues}
          setValue={setValue}
          clearErrors={clearErrors}
          error={error}
        />
      );
    case FormElementTypes.MultiUser: {
      return (
        <MultiOktaField
          element={element}
          idPrefix={idPrefix}
          control={control}
          setValue={setValue}
        />
      );
    }
    case FormElementTypes.DataReference:
      return (
        <DataReferenceField
          element={element}
          idPrefix={idPrefix}
          control={control}
          workflow={workflow}
        />
      );
    case FormElementTypes.Info:
      return <InfoField element={element} />;
    default:
      return <></>;
  }
}
