import { Button, ButtonProps, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderMenuButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: '3em',
  borderRadius: '1px',
  padding: '0 1em',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.light, 0.25),
  },
}));

export default HeaderMenuButton;
