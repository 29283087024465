/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Switch, TextField, Typography } from '@mui/material';
import { ButtonWithMenu } from '../../../components';
import { WorkflowDefinitionModel } from '../../../datastore/interfaces/workflowDefinition';

interface TableFeaturesProps {
  allToggle: boolean;
  search: string;
  workflowDefinitions: WorkflowDefinitionModel[];
  setAllToggle: (toggle: boolean) => void;
  setSearch: (search: string) => void;
  onNewSelect: (id: string) => void;
}

export default function TableFeatures({
  allToggle,
  setAllToggle,
  search,
  setSearch,
  workflowDefinitions,
  onNewSelect,
}: TableFeaturesProps) {
  return (
    <>
      <Stack direction="row" sx={{ flex: 1, alignItems: 'center' }}>
        <Typography>Requires Action</Typography>
        <Switch
          color="primary"
          value={allToggle}
          onChange={() => setAllToggle(!allToggle)}
        />
        <Typography>All</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <TextField
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          InputProps={{
            sx: {
              '& input': {
                padding: '0.4em 0.5em',
              },
            },
          }}
        />
        <ButtonWithMenu
          label="New"
          items={workflowDefinitions
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((w) => {
              return { id: w.id, label: w.name };
            })}
          onSelect={onNewSelect}
        />
      </Stack>
    </>
  );
}
