import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#CE372F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F1EDE7',
      dark: '#8c8780',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#2E7D32',
    },
    warning: {
      main: '#EF6C00',
    },
    error: {
      main: '#CF352F',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000',
      secondary: '#595959',
    },
  },
  typography: {
    fontFamily: '"Century Gothic", sans-serif',
    h1: {
      fontSize: '2em',
    },
    h2: {
      fontSize: '1.8em',
    },
    h3: {
      fontSize: '1.5em',
    },
    h4: {
      fontSize: '1.2em',
    },
    h5: {
      fontSize: '1em',
    },
    caption: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '0.25em 1em',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 4,
          padding: '0.6em 1em',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0.5em 0.5em',
        },
      },
    },
  },
});
