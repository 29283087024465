import {
  Button,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { WorkflowTaskModel } from '../../datastore/interfaces/workflow';
import { useState } from 'react';

interface UnassignFormProps {
  open: boolean;
  onSubmit: (taskId: string) => void;
  onClose: () => void;
  currentTasks: WorkflowTaskModel[];
}

export default function UnassignForm({
  open,
  onSubmit,
  onClose,
  currentTasks,
}: UnassignFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [taskId, setTaskId] = useState(currentTasks[0]?.id ?? '');

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      {currentTasks?.length ? (
        <Stack spacing={2} sx={{ padding: isMobile ? '1em' : '1em 2em' }}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" fontWeight="bold">
                Unassign Task
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
          </Stack>
          {currentTasks.length > 1 ? (
            <Stack spacing={1}>
              <Typography>Task to Unassign</Typography>
              <Select
                size="small"
                fullWidth
                value={taskId}
                onChange={(e) => setTaskId(e.target.value)}
              >
                {currentTasks.map((task) => {
                  return (
                    <MenuItem key={task.id} value={task.id}>
                      {task.taskDefinition.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          ) : (
            <Typography>
              Are you sure you want to unassign{' '}
              {currentTasks[0].assignedTo.name} from{' '}
              {currentTasks[0].taskDefinition.name}?
            </Typography>
          )}
          <Divider />
          <Stack>
            <Button
              variant="contained"
              type="submit"
              sx={{ marginTop: '2em' }}
              onClick={() => onSubmit(taskId)}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Typography>This workflow has no active tasks</Typography>
      )}
    </Dialog>
  );
}
