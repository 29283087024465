import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SortKey } from '../../common/arrays/sorting';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SearchFieldModel } from '../../common/search/searchFieldModel';
import SearchIcon from '@mui/icons-material/Search';

interface StyledTableHeadProps {
  headerData: { id: string; label: string; width?: string }[];
  sort?: SortKey;
  setSort?: (data: SortKey) => void;
  searchFields?: SearchFieldModel[];
  maxColumns?: number;
}

export default function StyledTableHead({
  headerData,
  sort,
  setSort,
  searchFields,
  maxColumns,
}: StyledTableHeadProps) {
  const theme = useTheme();

  function setSortHandler(id: string) {
    if (sort && setSort) {
      const isAsc = sort.key === id && sort.direction === 'asc';
      setSort({ key: id, direction: isAsc ? 'desc' : 'asc' });
    }
  }

  const excessColumns = [];

  if (maxColumns && maxColumns > headerData.length) {
    excessColumns.push(
      ...Array.from(Array(maxColumns - headerData.length).keys())
    );
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
            borderBottom: '1px solid var(--divider, #0000001F)',
            padding: '0.5em 0 0.5em 1em',
          },
        }}
      >
        {headerData.map((header) => {
          const isSearchedOn =
            searchFields &&
            header.label.length &&
            searchFields.some((s) => s.column === header.label);
          return (
            <TableCell
              key={header.id}
              sortDirection={sort?.key === 'name' ? sort.direction : false}
              width={header.width ?? 'auto'}
            >
              {sort && setSort ? (
                <TableSortLabel
                  active={sort.key === header.id}
                  direction={sort.key === header.id ? sort.direction : 'asc'}
                  onClick={() => setSortHandler(header.id)}
                  IconComponent={KeyboardArrowUpIcon}
                  sx={{ flexDirection: 'row' }}
                >
                  {header.label}
                  {isSearchedOn ? (
                    <SearchIcon fontSize="small" color="primary" />
                  ) : (
                    <></>
                  )}
                </TableSortLabel>
              ) : (
                <>
                  {header.label}
                  {isSearchedOn ? (
                    <SearchIcon fontSize="small" color="primary" />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </TableCell>
          );
        })}
        {excessColumns.map((col) => {
          return <TableCell key={col} width={'auto'} />;
        })}
      </TableRow>
    </TableHead>
  );
}
