import { Controller } from 'react-hook-form';
import { FormInputComponentProps } from './Interfaces';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

export default function DateField({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  let minimum: dayjs.Dayjs | undefined;
  let maximum: dayjs.Dayjs | undefined;
  const today = dayjs();

  if (element.minimum === 'today') {
    minimum = today;
  }

  if (element.maximum === 'today') {
    maximum = today;
  }

  return (
    <Controller
      control={control}
      name={`${idPrefix}${element.dataReference}`}
      rules={{ required: element.required }}
      render={({ field: { onChange, value, ...otherFields } }) => (
        <DatePicker
          value={dayjs(value)}
          onChange={(e) => {
            const date = dayjs(e);

            if (date.isValid()) {
              onChange(dayjs(e).toISOString());
            }
          }}
          format="DD/MM/YYYY"
          sx={{ width: '100%' }}
          slotProps={{
            textField: { size: 'small' },
            inputAdornment: { position: 'start' },
          }}
          disabled={element.readonly ?? false}
          minDate={minimum}
          maxDate={maximum}
          {...otherFields}
        />
      )}
    />
  );
}
