import { Box, Button, IconButton, Stack } from '@mui/material';
import { Control, UseFormSetValue, useFieldArray } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { OktaUser } from '../../../models';
import OktaLookup from '../../lookup/OktaLookup';
import { FormElementModel } from '../../../datastore/interfaces/form';

export interface MultiOktaFieldProps {
  element: FormElementModel;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  idPrefix: string;
}

interface FieldProps {
  id: string;
  saved?: OktaUser;
  value: string;
}

export default function MultiOktaField({
  element,
  control,
  setValue,
  idPrefix,
}: MultiOktaFieldProps) {
  const elementId = `${idPrefix}${element.dataReference}`;
  const { fields, append, remove } = useFieldArray({
    name: elementId,
    control,
  });

  return (
    <Stack spacing={2}>
      {fields.map((field, i) => {
        const parsedField = field as FieldProps;
        function onLookupChange(user: OktaUser) {
          setValue(`${elementId}.${i}.value`, user.email);
          setValue(`${elementId}.${i}.saved`, user);
        }

        function onLookupClear() {
          setValue(`${elementId}.${i}`, null);
        }
        return (
          <Stack
            key={field.id}
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <OktaLookup
              onSelect={onLookupChange}
              onClear={onLookupClear}
              defaultValue={
                parsedField.saved
                  ? `${parsedField.saved.name} (${parsedField.saved.email})`
                  : ''
              }
            />
            <IconButton onClick={() => remove(i)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Stack>
        );
      })}
      <Box>
        {fields.length < Number(element.maximum) && (
          <Button variant="contained" onClick={() => append({ value: '' })}>
            <AddIcon />
          </Button>
        )}
      </Box>
    </Stack>
  );
}
