import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputWithErrorProps } from './Interfaces';

export default function EmailField({
  element,
  control,
  error,
  idPrefix,
}: FormInputWithErrorProps) {
  const minimum = isNaN(Number(element.minimum)) ? 0 : Number(element.minimum);
  const maximum = isNaN(Number(element.maximum))
    ? Number.MAX_SAFE_INTEGER
    : Number(element.maximum);

  return (
    <Controller
      control={control}
      name={`${idPrefix}${element.dataReference}`}
      rules={{
        required: element.required,
        minLength: minimum,
        maxLength: maximum,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Invalid email address',
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          error={error !== undefined}
          fullWidth
          size="small"
          disabled={element.readonly ?? false}
          inputProps={{
            minLength: minimum,
            maxLength: maximum,
          }}
        />
      )}
    />
  );
}
