import { createBrowserRouter } from 'react-router-dom';
import {
  ActionTask,
  CreateWorkflow,
  GroupWorkflows,
  Home,
  NextTask,
  WorkflowDetails,
  WorkflowHistory,
  Workflows,
} from './pages';
import { Layout } from './components';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
  {
    path: '/workflows',
    element: (
      <Layout>
        <Workflows />
      </Layout>
    ),
  },
  {
    path: '/workflows/:referenceNumber',
    element: (
      <Layout>
        <WorkflowDetails />
      </Layout>
    ),
  },
  {
    path: '/workflows/:referenceNumber/nextTask',
    element: (
      <Layout>
        <NextTask />
      </Layout>
    ),
  },
  {
    path: '/workflows/:referenceNumber/history',
    element: (
      <Layout>
        <WorkflowHistory />
      </Layout>
    ),
  },
  {
    path: '/groupWorkflows',
    element: (
      <Layout>
        <GroupWorkflows />
      </Layout>
    ),
  },
  {
    path: '/createworkflow/:id',
    element: (
      <Layout>
        <CreateWorkflow />
      </Layout>
    ),
  },
  {
    path: '/task/:referenceNumber/:taskNumber',
    element: (
      <Layout>
        <ActionTask />
      </Layout>
    ),
  },
]);
