import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Auth, DataStore } from 'aws-amplify';
import { useCurrentUser, useLoading } from '../../hooks';
import { ReactComponent as Logo } from '../../assets/images/mrllogo.svg';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';

export default function MobileHeader() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const setLoading = useLoading();
  const [open, setOpen] = useState(false);

  function goTo(to: string) {
    setOpen(false);
    navigate(to);
  }

  return (
    <AppBar sx={{ height: '3em', backgroundColor: 'black', zIndex: 100000 }}>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          flex: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          {open ? (
            <CloseIcon
              sx={{ color: theme.palette.primary.contrastText }}
              fontSize="large"
            />
          ) : (
            <MenuIcon
              sx={{ color: theme.palette.primary.contrastText }}
              fontSize="large"
            />
          )}
        </IconButton>
      </Stack>
      <Drawer anchor="top" open={open} onClose={() => setOpen(false)}>
        <Stack sx={{ paddingTop: '3em' }}>
          <Logo style={{ width: '50%' }} />
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {user.name}
                    </Typography>
                    <Typography>v{process.env.REACT_APP_VERSION}</Typography>
                  </Stack>
                }
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => goTo('/')}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            {user.department.length > 0 && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => goTo('/groupWorkflows')}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Department" />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setLoading(true);
                  DataStore.stop().then(() => {
                    DataStore.clear();
                    Auth.signOut();
                    setLoading(false);
                  });
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Drawer>
    </AppBar>
  );
}
