/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Typography } from '@mui/material';
import { attachmentFileTypes } from '../../common';
import { useDropzone } from 'react-dropzone';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

interface UploadAreaProps {
  onUpload: (files: File[]) => void;
}

export default function UploadArea({ onUpload }: UploadAreaProps) {
  const theme = useTheme();
  const dropzoneFileTypes: any = {};
  for (const fileType of attachmentFileTypes) {
    dropzoneFileTypes[fileType] = [];
  }

  const { getRootProps, getInputProps, acceptedFiles, isDragAccept } =
    useDropzone({
      accept: dropzoneFileTypes,
    });

  useEffect(() => {
    onUpload(acceptedFiles);
  }, [acceptedFiles]);

  const borderColour = isDragAccept
    ? theme.palette.primary.main
    : theme.palette.text.primary;

  const backgroundColour = isDragAccept
    ? theme.palette.primary.light
    : theme.palette.background.paper;

  return (
    <Paper
      {...getRootProps()}
      elevation={0}
      sx={{
        padding: '2em 1em',
        border: `dashed ${borderColour} 1px`,
        backgroundColor: backgroundColour,
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <Typography>Click or Drag-And-Drop Attachment(s)</Typography>
      <input
        {...getInputProps()}
        hidden
        accept={attachmentFileTypes.join(', ')}
        type="file"
        multiple
      />
    </Paper>
  );
}
