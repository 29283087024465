import { DataStore, SortDirection } from 'aws-amplify';
import { Owner, Task, TaskData, Workflow, WorkflowDefinition } from '../models';
import { FormElementTypes, OutcomeAssignToTypes } from '../common';
import dayjs from 'dayjs';
import { CurrentUserProps } from '../hooks/useCurrentUser';
import {
  convertWorkflowToModel,
  convertWorkflowsToModelsAndEagerLoad,
} from './convert/workflow';
import {
  WorkflowCreateMultiModel,
  WorkflowDataProps,
  WorkflowExtraDataModel,
  WorkflowModel,
  WorkflowTaskModel,
} from './interfaces/workflow';
import { MultiDataChanges } from '../common/interfaces/multiDataChanges';

export const workflowsData = (currentUser: CurrentUserProps) => {
  async function get(id: string) {
    const result = await DataStore.query(Workflow, id);
    if (result) {
      return await convertWorkflowToModel(result);
    }
  }

  async function getByReferenceNumber(referenceNumber: string) {
    const results = await DataStore.query(Workflow, (workflow) =>
      workflow.referenceNumber.eq(referenceNumber)
    );
    if (results && results.length) {
      return await convertWorkflowToModel(results[0]);
    }
  }

  async function getListByDefinitions(ids: string[]) {
    const results = await DataStore.query(Workflow, (workflow) =>
      workflow.or((workflow) => [
        ...ids.map((id) => workflow.definitionId.eq(id)),
      ])
    );
    const convertedResults = await convertWorkflowsToModelsAndEagerLoad(
      results
    );
    return convertedResults;
  }

  async function getListByOwner(ownerEmail: string) {
    let results = await DataStore.query(
      Workflow,
      (workflow) => workflow.ownerEmail.eq(ownerEmail),
      {
        sort: (w) => w.createdAt(SortDirection.ASCENDING),
      }
    );

    if (results) {
      const convertedResults = await convertWorkflowsToModelsAndEagerLoad(
        results
      );
      return convertedResults;
    }

    return [];
  }

  async function getListByAssignedTo(email: string) {
    let results = await DataStore.query(
      Workflow,
      (workflow) => workflow.availableToUsers.contains(email),
      {
        sort: (w) => w.createdAt(SortDirection.ASCENDING),
      }
    );

    if (results) {
      const convertedResults = await convertWorkflowsToModelsAndEagerLoad(
        results
      );
      return convertedResults;
    }

    return [];
  }

  async function buildEmpty(definitionId: string, owner: Owner) {
    const definition = await DataStore.query(WorkflowDefinition, definitionId);

    if (!definition) {
      throw new Error('Definition not found!');
    }

    const workflowId = crypto.randomUUID();
    const taskDefinitions = await definition.process.toArray();
    const firstTaskDefinition = taskDefinitions.find((t) => t.order === 0);

    if (!firstTaskDefinition) {
      throw new Error('Workflow definition does not have a starting Task.');
    }

    const emails = definition.emails ?? [];

    const task = new Task({
      id: crypto.randomUUID(),
      workflowId: workflowId,
      taskDefinitionId: firstTaskDefinition.id,
      taskNumber: 1,
      assignedToEmail: owner.email,
      assignedToName: owner.name,
      status: 'New',
      complete: false,
      reassigned: false,
      data: [],
      dateDue: firstTaskDefinition.daysDue
        ? dayjs().add(firstTaskDefinition.daysDue, 'day').toISOString()
        : null,
      outcomeTriggers: [],
      availableToUsers: [owner.email, ...emails],
      availableToGroups: definition.groups,
      createdAt: dayjs().toISOString(),
    });

    const workflow = new Workflow({
      id: workflowId,
      definitionId: definition.id,
      referenceNumber: '',
      description: definition.description,
      status: 'New',
      complete: false,
      ownerEmail: currentUser.email,
      ownerName: currentUser.name,
      currentTasks: [task.id],
      history: [task],
      notes: [],
      updatedBy: {
        email: currentUser.email,
        name: currentUser.name,
      },
      definition: definition,

      availableToUsers: [currentUser.email, ...emails],
      availableToGroups: definition.groups,
      createdAt: dayjs().toISOString(),
    });

    const updatedTask = Task.copyOf(task, (updated) => {
      updated.workflowId = workflow.id;
    });

    return convertWorkflowToModel(
      workflow,
      true,
      [definition],
      [updatedTask],
      taskDefinitions
    );
  }

  async function create(
    definitionId: string,
    owner: Owner,
    extraData?: WorkflowExtraDataModel
  ) {
    const definition = await DataStore.query(WorkflowDefinition, definitionId);

    if (!definition) {
      throw new Error('Workflow definition does not exist.');
    }

    const id = crypto.randomUUID();
    const refNumber = `${dayjs().format('YYMMDDHHmmss')}${id
      .replace(/[^0-9]/g, '')
      .substring(0, 3)}`;
    const emails = definition.emails ?? [];
    const created = await DataStore.save(
      new Workflow({
        id: id,
        definitionId: definitionId,
        referenceNumber: refNumber,
        description: extraData?.description ?? definition.description,
        status: 'New',
        complete: false,
        ownerEmail: owner.email,
        ownerName: owner.name,
        definition: definition,
        currentTasks: [],
        notes: [],
        updatedBy: {
          email: currentUser.email,
          name: currentUser.name,
        },
        availableToUsers: [owner.email, ...emails],
        availableToGroups: definition.groups,
        createdAt: dayjs().toISOString(),
      })
    );

    if (!created) {
      throw new Error('Failed to create Workflow.');
    }

    const taskDefinitions = await definition.process.toArray();
    const firstTaskDefinition = taskDefinitions.find((t) => t.order === 0);

    if (!firstTaskDefinition) {
      throw new Error('Workflow definition does not have a starting Task.');
    }

    const createdTask = await DataStore.save(
      new Task({
        id: crypto.randomUUID(),
        workflowId: created.id,
        taskDefinitionId: firstTaskDefinition.id,
        taskNumber: 1,
        assignedToEmail: owner.email,
        assignedToName: owner.name,
        status: 'New',
        complete: false,
        reassigned: false,
        data: [],
        dateDue: firstTaskDefinition.daysDue
          ? dayjs().add(firstTaskDefinition.daysDue, 'day').toISOString()
          : null,
        workflow: created,
        outcomeTriggers: [],
        availableToUsers: [owner.email, ...emails],
        availableToGroups: definition.groups,
        createdAt: dayjs().toISOString(),
      })
    );

    if (createdTask) {
      const updated = await DataStore.save(
        Workflow.copyOf(created, (updated) => {
          updated.currentTasks = [createdTask.id];
        })
      );

      if (updated) {
        return convertWorkflowToModel(updated);
      }
      throw new Error('Failed to update Workflow.');
    }
  }

  async function createWithMulti(
    definitionId: string,
    owner: Owner,
    data: TaskData[]
  ) {
    const workflowDefinition = await DataStore.query(
      WorkflowDefinition,
      definitionId
    );

    if (!workflowDefinition) {
      throw new Error('Workflow definition not found!');
    }

    const results: WorkflowCreateMultiModel[] = [];

    if (
      workflowDefinition.multiWorkflow &&
      workflowDefinition.multiDataReference.length
    ) {
      const multiData = workflowDefinition.multiDataReference.flatMap((r) =>
        data
          .filter((t) => t.reference === r)
          .flatMap((d) =>
            d.value.split(', ').map((v) => {
              return { key: d.key, reference: r, value: v };
            })
          )
      );

      for (var i = 0; i < multiData.length; i++) {
        const dataEntry = multiData[i];
        const newWorkflow = await create(workflowDefinition.id, owner, {
          description: dataEntry.value,
        });
        const newTask = newWorkflow?.currentTasks[0];
        if (!newWorkflow || !newTask) {
          throw new Error('Workflow failed to create!');
        }

        const elementToMulti = newTask.taskDefinition.form?.sections
          .flatMap((s) => s.elements)
          .find((e) => e.dataReference === dataEntry.reference);

        let editedData = data.filter(
          (t) =>
            !t.reference ||
            !workflowDefinition.multiDataReference.includes(t.reference)
        );

        editedData.push(dataEntry);

        if (elementToMulti?.data?.length) {
          const forLoopLevel = i;
          try {
            const multiDataChanges: MultiDataChanges[] = JSON.parse(
              elementToMulti.data
            );
            const multiReferences = multiDataChanges.flatMap((m) =>
              m.data.map((d) => d.dataReference)
            );

            const multisToChange = multiDataChanges.find(
              (m) => m.choice === forLoopLevel
            );

            if (multisToChange) {
              const elementsToAdd: TaskData[] = [];

              for (const multi of multisToChange?.data) {
                const existing = data.find(
                  (d) => d.reference === multi.dataReference
                );
                if (existing) {
                  elementsToAdd.push({
                    key: multi.newKey,
                    reference: multi.newReference,
                    value: existing.value,
                  });
                }
              }

              editedData = editedData.filter(
                (e) => e.reference && !multiReferences.includes(e.reference)
              );

              for (const elementToAdd of elementsToAdd) {
                editedData.push(elementToAdd);
              }
            }
          } catch {
            console.log('Multi element has "data" but did not parse correctly');
          }
        }

        const editedTaskModel: WorkflowTaskModel = {
          ...newTask,
          id: newWorkflow.currentTasks[0].id,
          workflowId: newWorkflow?.id,
          data: editedData,
        };

        results.push({
          workflow: newWorkflow,
          task: editedTaskModel,
          description: dataEntry.value,
        });
      }
    } else {
      let extraData: WorkflowExtraDataModel | undefined = undefined;
      if (workflowDefinition.descriptionReference) {
        const dataReference = data.find(
          (d) => d.reference === workflowDefinition.descriptionReference
        );
        if (dataReference) {
          extraData = { description: dataReference.value };
        }
      }
      const newWorkflow = await create(workflowDefinition.id, owner, extraData);
      const newTask = newWorkflow?.currentTasks[0];
      if (!newWorkflow || !newTask) {
        throw new Error('Workflow failed to create!');
      }

      newTask.data = data;

      results.push({
        workflow: newWorkflow,
        task: newTask,
        description: '',
      });
    }

    return results;
  }

  async function completeTask(
    data: WorkflowModel,
    task: WorkflowTaskModel,
    description: string,
    assignNextTaskTo?: Owner,
    triggers?: string[]
  ) {
    if (!task) {
      throw new Error('No current Task available');
    }
    const workflow = await DataStore.query(Workflow, data.id);
    const workflowDefinition = await DataStore.query(
      WorkflowDefinition,
      data.definitionId
    );
    const existingTask = await DataStore.query(Task, task.id);

    if (!workflow || !existingTask) {
      throw new Error('The workflow or task do not exist.');
    }

    const taskDefinitions = await workflowDefinition?.process.toArray();
    const history = await workflow.history.toArray();

    if (!taskDefinitions) {
      throw new Error('Task Definitions not found.');
    }

    const commentElement = task.taskDefinition.form?.sections
      .flatMap((s) => s.elements)
      .find((e) => e.type === FormElementTypes.Comments);
    const comment = commentElement
      ? task.data.find((d) => d.key === commentElement.name)?.value
      : '';

    const descriptionElement = task.taskDefinition.form?.sections
      .flatMap((s) => s.elements)
      .find((e) => e.workflowReference === 'description');

    const workflowDescription =
      task.data.find((d) => d.reference === descriptionElement?.dataReference)
        ?.value ??
      workflow.description ??
      task.taskDefinition.description;

    const currentTaskDefinition = taskDefinitions.find(
      (t) => t.id === existingTask.taskDefinitionId
    );
    const taskOutcomes = currentTaskDefinition!.outcomes;
    const outcomes = taskOutcomes.filter(
      (t) =>
        !t.triggers.length ||
        t.triggers.some((trigger) => triggers!.includes(trigger))
    );

    if (!outcomes.length) {
      throw new Error('Task Definition does not have eligible outcomes!');
    }

    let assignedTo = task.assignedTo;
    if (!assignedTo.email.length && !assignedTo.name.length) {
      assignedTo = currentUser.getOwner();
    }

    const savedTask = await DataStore.save(
      Task.copyOf(existingTask, (updated) => {
        updated.taskNumber = task.taskNumber;
        updated.assignedToEmail = assignedTo.email;
        updated.assignedToName = assignedTo.name;
        updated.comments = comment;
        updated.status = outcomes[0].status;
        updated.complete = true;
        updated.data = task.data;
        updated.outcomeTriggers = triggers ?? [];
        updated.availableToUsers = assignNextTaskTo?.email
          ? [...new Set([...updated.availableToUsers, assignNextTaskTo.email])]
          : updated.availableToUsers;
      })
    );

    for (const historicTask of history.filter((h) => h.id !== task.id)) {
      await DataStore.save(
        Task.copyOf(historicTask, (updated) => {
          updated.availableToUsers = assignNextTaskTo?.email
            ? [
                ...new Set([
                  ...updated.availableToUsers,
                  assignNextTaskTo.email,
                ]),
              ]
            : updated.availableToUsers;
        })
      );
    }

    if (!savedTask) {
      throw new Error('Task failed to save.');
    }

    let newTasks: Task[] = [];

    const openTasks = history.filter(
      (h) =>
        h.id !== savedTask.id &&
        !h.complete &&
        h.previousTaskNumber === savedTask.previousTaskNumber
    );

    const workflowIsFinished = outcomes.every((o) => !o.nextTask?.length);

    if (openTasks.length) {
      //if there are tasks still open, then don't move on to the next stage
      const taskIsRejected = outcomes[0].status === 'Rejected';

      //if rejected, then all other open tasks must end
      if (taskIsRejected) {
        for (const openTask of openTasks) {
          await DataStore.save(
            Task.copyOf(openTask, (updated) => {
              updated.complete = true;
              updated.skipTrigger = true;
            })
          );
        }
      }

      //if the task is considered finished, then the entire workflow must be finished,
      //and all open tasks should be marked done.
      if (workflowIsFinished || taskIsRejected) {
        await DataStore.save(
          Workflow.copyOf(workflow, (updated) => {
            updated.currentTasks = [];
            updated.status = outcomes[0].nextStatus ?? outcomes[0].status;
            updated.complete = true;
            updated.completedAt = dayjs().toISOString();
            updated.updatedBy = {
              email: currentUser.email,
              name: currentUser.name,
            };
            updated.availableToUsers = savedTask.availableToUsers;
          })
        );
      } else {
        await DataStore.save(
          Workflow.copyOf(workflow, (updated) => {
            updated.currentTasks = openTasks.map((t) => t.id);
            updated.description = description.length
              ? description
              : workflowDescription;
            updated.updatedBy = {
              email: currentUser.email,
              name: currentUser.name,
            };
            updated.availableToUsers = savedTask.availableToUsers;
          })
        );
      }
    } else {
      let taskNumber = task.taskNumber;
      for (const outcome of outcomes) {
        const newTaskDefinition = outcome.nextTask
          ? taskDefinitions.find((t) => t.id === outcome.nextTask)
          : undefined;

        let nextAssignee = assignNextTaskTo;
        if (outcome.assignTo?.length) {
          switch (outcome.assignTo) {
            case OutcomeAssignToTypes.Owner:
              nextAssignee = {
                email: workflow.ownerEmail,
                name: workflow.ownerName,
              };
              break;
            case OutcomeAssignToTypes.Self:
              nextAssignee = currentUser.getOwner();
          }
        }

        if (newTaskDefinition) {
          taskNumber = taskNumber + 1;
          const newTask = await DataStore.save(
            new Task({
              id: crypto.randomUUID(),
              workflowId: workflow.id,
              taskDefinitionId: newTaskDefinition.id,
              taskNumber: taskNumber,
              previousTaskNumber: task.taskNumber,
              assignedToEmail: nextAssignee?.email ?? '',
              assignedToName: nextAssignee?.name ?? '',
              status: outcome.nextStatus ?? outcome.status,
              complete: false,
              reassigned: false,
              data: [],
              outcomeTriggers: [],
              dateDue: newTaskDefinition.daysDue
                ? dayjs().add(newTaskDefinition.daysDue, 'day').toISOString()
                : null,
              workflow: workflow,
              availableToUsers: savedTask.availableToUsers,
              availableToGroups: workflowDefinition?.groups ?? [],
              createdAt: dayjs().toISOString(),
            })
          );

          if (newTask) {
            newTasks.push(newTask);
          }
        }
      }
    }

    if (newTasks.length) {
      await DataStore.save(
        Workflow.copyOf(workflow, (updated) => {
          updated.currentTasks = newTasks.map((n) => n.id);
          updated.status = outcomes[0].nextStatus ?? outcomes[0].status;
          updated.description = description.length
            ? description
            : workflowDescription;
          updated.updatedBy = {
            email: currentUser.email,
            name: currentUser.name,
          };
          updated.availableToUsers = savedTask.availableToUsers;
        })
      );
    } else if (workflowIsFinished) {
      //we're hopefully done by this point
      await DataStore.save(
        Workflow.copyOf(workflow, (updated) => {
          updated.status = outcomes[0].status;
          updated.description = description.length
            ? description
            : workflowDescription;
          updated.complete = true;
          updated.completedAt = dayjs().toISOString();
          updated.updatedBy = {
            email: currentUser.email,
            name: currentUser.name,
          };
          updated.availableToUsers = savedTask.availableToUsers;
        })
      );
    }
  }

  async function reassignTask(
    data: WorkflowModel,
    task: WorkflowTaskModel,
    assignNextTaskTo: Owner
  ) {
    if (!task) {
      throw new Error('No current Task available');
    }
    const workflow = await DataStore.query(Workflow, data.id);
    const existingTask = await DataStore.query(Task, task.id);

    if (!workflow || !existingTask) {
      throw new Error('The workflow or task do not exist.');
    }

    const savedTask = await DataStore.save(
      Task.copyOf(existingTask, (updated) => {
        updated.complete = true;
        updated.reassigned = true;
        updated.skipTrigger = true;
        updated.availableToUsers = assignNextTaskTo?.email
          ? [...new Set([...updated.availableToUsers, assignNextTaskTo.email])]
          : updated.availableToUsers;
      })
    );

    if (!savedTask) {
      throw new Error('Task failed to save.');
    }

    const reassignedTask = await DataStore.save(
      new Task({
        id: crypto.randomUUID(),
        workflowId: workflow.id,
        taskDefinitionId: task.taskDefinition.id,
        taskNumber: task.taskNumber + 1,
        previousTaskNumber: task.taskNumber,
        assignedToEmail: assignNextTaskTo.email,
        assignedToName: assignNextTaskTo.name,
        status: 'Reassigned',
        complete: true,
        reassigned: true,
        skipTrigger: true,
        data: [],
        comments: `Reassigned by ${currentUser.name}`,
        outcomeTriggers: task.outcomeTriggers,
        workflow: workflow,
        availableToUsers: savedTask.availableToUsers,
        availableToGroups: savedTask.availableToGroups,
        createdAt: dayjs().toISOString(),
      })
    );

    const newTask = await DataStore.save(
      new Task({
        id: crypto.randomUUID(),
        workflowId: workflow.id,
        taskDefinitionId: task.taskDefinition.id,
        taskNumber: reassignedTask.taskNumber + 1,
        previousTaskNumber: reassignedTask.taskNumber,
        assignedToEmail: assignNextTaskTo.email,
        assignedToName: assignNextTaskTo.name,
        status: task.status,
        complete: false,
        reassigned: true,
        data: task.data,
        outcomeTriggers: task.outcomeTriggers,
        dateDue: task.taskDefinition.daysDue
          ? dayjs().add(task.taskDefinition.daysDue, 'day').toISOString()
          : null,
        workflow: workflow,
        availableToUsers: savedTask.availableToUsers,
        availableToGroups: savedTask.availableToGroups,
        createdAt: dayjs().toISOString(),
      })
    );

    if (newTask) {
      await DataStore.save(
        Workflow.copyOf(workflow, (updated) => {
          updated.updatedBy = {
            email: currentUser.email,
            name: currentUser.name,
          };
          updated.currentTasks = [newTask.id];
          updated.availableToUsers = savedTask.availableToUsers;
        })
      );

      return await get(workflow.id);
    }
  }

  async function update(data: WorkflowModel) {
    const original = await DataStore.query(Workflow, data.id);

    if (original) {
      const updated = await DataStore.save(
        Workflow.copyOf(original, (updated) => {
          updated.status = data.status;
          updated.ownerEmail = data.owner.email;
          updated.ownerName = data.owner.name;
          updated.notes = data.notes.map((note) => {
            return {
              createdBy: note.createdBy,
              note: note.note,
              date: note.date.toISOString(),
              attachments: note.attachments.map((a) => {
                return { key: a.key, fileName: a.fileName };
              }),
            };
          });
          updated.updatedBy = {
            email: currentUser.email,
            name: currentUser.name,
          };
        })
      );

      if (updated) {
        return convertWorkflowToModel(updated);
      }
    }
  }

  const returned: WorkflowDataProps = {
    get,
    getByReferenceNumber,
    getListByOwner,
    getListByDefinitions,
    getListByAssignedTo,
    buildEmpty,
    create,
    createWithMulti,
    completeTask,
    reassignTask,
    update,
  };

  return returned;
};
