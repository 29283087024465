/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { WorkflowModel } from '../../datastore/interfaces/workflow';
import UploadArea from '../upload/UploadArea';

interface AddNoteFormProps {
  open: boolean;
  onSubmit: (data: AddNoteFormModel) => void;
  onClose: () => void;
  workflow: WorkflowModel;
}

export interface AddNoteFormModel {
  note: string;
  attachments: { file: File }[];
}

export default function AddNoteForm({
  open,
  onSubmit,
  onClose,
  workflow,
}: AddNoteFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AddNoteFormModel>();

  const { fields, append, remove } = useFieldArray({
    name: 'attachments',
    control,
  });

  async function onAttachmentUpload(files: File[]) {
    if (files) {
      for (const file of files) {
        if (file.size > 12582912) {
          //arbitrary number, can be changed
          setError('attachments', {
            type: 'custom',
            message: 'File too big! Please make sure it is 12MB or less.',
          });
          break;
        } else {
          append({ file });
          clearErrors();
        }
      }
    }
  }

  const onSubmitForm: SubmitHandler<AddNoteFormModel> = (data) =>
    onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={2} sx={{ padding: isMobile ? '1em' : '1em 2em' }}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" fontWeight="bold">
                Add Note to {workflow.definition.name}
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
          </Stack>
          <Stack spacing={1}>
            <Typography>Note</Typography>
            <Controller
              control={control}
              name="note"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                />
              )}
            />
            {errors['note'] && (
              <Typography>{errors['note'].message}</Typography>
            )}
          </Stack>
          <Stack spacing={1}>
            <Typography>Attachments</Typography>
            <UploadArea onUpload={onAttachmentUpload} />

            <Stack>
              {fields.map((field: any, i) => {
                return (
                  <Stack key={field.id} direction="row" spacing={2}>
                    <Typography>{field.file.name}</Typography>
                    <Button variant="text" onClick={() => remove(i)}>
                      X
                    </Button>
                  </Stack>
                );
              })}
            </Stack>
            {errors['attachments'] && (
              <Typography>{errors['attachments'].message}</Typography>
            )}
          </Stack>
          <Divider />
          <Stack>
            <Button variant="contained" type="submit" sx={{ marginTop: '2em' }}>
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
}
