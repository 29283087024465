import { Typography } from '@mui/material';
import { getValue, parseDataRef } from '../../../common';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';
import { Control, useWatch } from 'react-hook-form';

interface DataReferenceFieldProps {
  element: FormElementModel;
  workflow: WorkflowModel;
  control: Control<any>;
  idPrefix: string;
}

export default function DataReferenceField({
  element,
  control,
  workflow,
  idPrefix,
}: DataReferenceFieldProps) {
  const watchData = useWatch({
    control,
    name: `${idPrefix}${element.dataReference}`,
  });

  const data = element.workflowReference.length
    ? getValue(`${idPrefix}${element.workflowReference}`, workflow)
    : watchData ?? '';

  const value = parseDataRef(data);
  return <Typography>{value}</Typography>;
}
