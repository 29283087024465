import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { FormElementModel } from '../../../datastore/interfaces/form';

interface ErrorProps {
  element: FormElementModel;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

export default function Error({ element, error }: ErrorProps) {
  const theme = useTheme();
  let message = error?.message?.toString();

  if (!message && error?.type === 'required') {
    message = 'This is required';
  }

  if (!message && error?.type === 'maxLength') {
    message = `Text exceeds max length of ${element.maximum} characters`;
  }

  return message?.length ? (
    <Typography color={theme.palette.error.main} fontWeight="bold">
      {message}
    </Typography>
  ) : (
    <></>
  );
}
