import {
  Form,
  FormElement,
  FormSection,
  FormSelection,
  TaskDefinition,
  WorkflowDefinition,
} from '../../models';
import {
  TaskDefinitionModel,
  WorkflowDefinitionModel,
} from '../interfaces/workflowDefinition';
import { convertFormToModel } from './form';

export async function convertWorkflowDefinitionToModel(
  data: WorkflowDefinition
) {
  const tasks = await data.process.toArray();
  const taskModels: TaskDefinitionModel[] = [];

  for (const task of tasks.sort((a, b) => b.order - a.order)) {
    taskModels.push(await convertTaskDefinitionToModel(task));
  }

  const results: WorkflowDefinitionModel = {
    id: data.id,
    name: data.name,
    description: data.description,
    descriptionReference: data.descriptionReference,
    process: taskModels,
    groups: data.groups,
    emails: data.emails ?? [],
    multiWorkflow: data.multiWorkflow,
    multiDataReference: data.multiDataReference,
    customColumns: data.customColumns,
  };

  return results;
}

export async function convertTaskDefinitionToModel(
  data: TaskDefinition,
  loadedForms?: Form[],
  loadedFormSections?: FormSection[],
  loadedFormElements?: FormElement[],
  loadedFormSelections?: FormSelection[]
) {
  const results: TaskDefinitionModel = {
    id: data.id,
    workflowDefinitionId: data.workflowDefinitionId,
    formId: data.formId,
    order: data.order,
    name: data.name,
    description: data.description,
    type: data.type,
    groups: data.groups,
    emails: data.emails ?? [],
    viewPreviousData: data.viewPreviousData,
    editPreviousForms: data.editPreviousForms ?? [],
    elementsToHide: data.elementsToHide ?? [],
    requiredTasksCompleted: data.requiredTasksCompleted,
    outcomes: data.outcomes,
  };

  const form =
    loadedForms?.find((f) => f.id === data.formId) ?? (await data.form);

  if (form) {
    results.form = await convertFormToModel(
      form,
      loadedFormSections,
      loadedFormElements,
      loadedFormSelections
    );
  }

  return results;
}
