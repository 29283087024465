import { Grid, Typography } from '@mui/material';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { useTheme } from '@mui/material/styles';
import { FormSectionDirection } from '../../../common';

export interface ElementLabelProps {
  element?: FormElementModel;
  direction: FormSectionDirection;
}

export default function ElementLabel({
  element,
  direction,
}: ElementLabelProps) {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      md={direction === FormSectionDirection.Horizontal ? 12 : 3}
      sx={{ marginBottom: '1em', alignSelf: 'flex-start' }}
    >
      {element && (
        <Typography variant="h4">
          {element?.name}
          {element?.required && (
            <span style={{ color: theme.palette.error.main }}> *</span>
          )}
        </Typography>
      )}
    </Grid>
  );
}
