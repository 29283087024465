import { DataStore, SortDirection } from 'aws-amplify';
import { FormSelection } from '../models';
import { CurrentUserProps } from '../hooks/useCurrentUser';
import {
  FormSelectionDataProps,
  FormSelectionModel,
} from './interfaces/formSelection';
import { convertFormSelectionToModel } from './convert/formSelection';

export const formSelectionsData = (currentUser: CurrentUserProps) => {
  async function get(id: string) {
    const result = await DataStore.query(FormSelection, id);
    if (result) {
      return convertFormSelectionToModel(result);
    }
  }

  async function getList(search?: string) {
    let results = await DataStore.query(
      FormSelection,
      search?.length
        ? (formSelection) =>
            formSelection.or((formSelection) => [
              formSelection.name.contains(search),
              formSelection.description.contains(search),
            ])
        : null,
      {
        sort: (f) => f.createdAt(SortDirection.ASCENDING),
      }
    );

    if (results) {
      const convertedResults = [];
      for (const result of results) {
        convertedResults.push(convertFormSelectionToModel(result));
      }
      return convertedResults;
    }

    return [];
  }

  async function create(data: FormSelectionModel) {
    const created = await DataStore.save(
      new FormSelection({
        id: data.id,
        name: data.name,
        description: data.description,
        subName: data.subName,
        allowCustomSubChoice: data.allowCustomSubChoice,
        sections: data.sections,
      })
    );

    if (created) {
      return convertFormSelectionToModel(created);
    }

    throw new Error('Failed to create Form Selection.');
  }

  async function update(data: FormSelectionModel) {
    const original = await DataStore.query(FormSelection, data.id);

    if (original) {
      const updated = await DataStore.save(
        FormSelection.copyOf(original, (updated) => {
          updated.name = data.name;
          updated.description = data.description;
          updated.subName = data.subName;
          updated.allowCustomSubChoice = data.allowCustomSubChoice;
          updated.sections = data.sections;
        })
      );

      if (updated) {
        return convertFormSelectionToModel(updated);
      }

      throw new Error('Could not update Selection.');
    } else {
      return await create(data);
    }
  }

  const returned: FormSelectionDataProps = {
    get,
    getList,
    create,
    update,
  };

  return returned;
};
