import { Button, Menu, MenuItem, Stack } from '@mui/material';
import * as React from 'react';

interface ButtonWithMenuProps {
  label: string;
  items: {
    id: string;
    label: string;
  }[];
  onSelect: (id: string) => void;
}

export default function ButtonWithMenu({
  label,
  items,
  onSelect,
}: ButtonWithMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id: string) => {
    handleClose();
    onSelect(id);
  };

  return (
    <Stack>
      <Button variant="contained" onClick={handleClick}>
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((item) => {
          return (
            <MenuItem key={item.id} onClick={() => handleSelect(item.id)}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
}
