import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { dateFormat } from '../../../common';
import { FormInputComponentProps } from './Interfaces';

export default function DateRange({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  const [min, setMin] = useState(dayjs());
  const [max, setMax] = useState(dayjs());

  return (
    <Stack direction="row" spacing={2}>
      <Stack sx={{ flex: 1 }}>
        <Controller
          control={control}
          name={`${idPrefix}${element.dataReference} Minimum`}
          rules={{ required: element.required }}
          render={({ field: { onChange, value, ...otherFields } }) => (
            <DatePicker
              value={dayjs(value)}
              onChange={(e) => {
                const date = dayjs(e);

                if (date.isValid()) {
                  const iso = date.toISOString();
                  setMin(date);
                  onChange(iso);
                }
              }}
              format={dateFormat}
              slotProps={{
                textField: { size: 'small' },
                inputAdornment: { position: 'start' },
              }}
              disabled={element.readonly ?? false}
              maxDate={max}
              {...otherFields}
            />
          )}
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Controller
          control={control}
          name={`${idPrefix}${element.dataReference} Maximum`}
          rules={{ required: element.required }}
          render={({ field: { onChange, value, ...otherFields } }) => (
            <DatePicker
              value={dayjs(value)}
              onChange={(e) => {
                const date = dayjs(e);

                if (date.isValid()) {
                  const iso = date.toISOString();
                  setMax(date);
                  onChange(iso);
                }
              }}
              format={dateFormat}
              slotProps={{
                textField: { size: 'small' },
                inputAdornment: { position: 'start' },
              }}
              disabled={element.readonly ?? false}
              minDate={min}
              {...otherFields}
            />
          )}
        />
      </Stack>
    </Stack>
  );
}
