/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, TableBody, TableCell, Typography } from '@mui/material';
import { useDatastore } from '../../hooks';
import { useEffect, useState } from 'react';
import {
  LoadingSpinner,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
} from '../../components';
import { SortKey, sortData } from '../../common/arrays/sorting';
import StyledTableFooter from '../../components/tables/StyledTableFooter';
import { WorkflowDefinitionModel } from '../../datastore/interfaces/workflowDefinition';

const headerCells = [
  { id: 'name', label: 'Name' },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'totalTasks',
    label: 'Total Tasks',
  },
];

export default function Workflows() {
  const store = useDatastore();
  const [data, setData] = useState<WorkflowDefinitionModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sort, setSort] = useState<SortKey>({
    key: headerCells[0].id,
    direction: 'asc',
  });

  const sortedData = sortData(
    data.map((d) => {
      return { ...d, totalTasks: d.process.length };
    }),
    sort
  ) as WorkflowDefinitionModel[];

  useEffect(() => {
    async function getData() {
      const results = await store.workflowDefinitions.getList();
      if (results) {
        setData(results);
      }

      setIsLoading(false);
    }

    getData();
  }, []);

  return (
    <Stack spacing={2}>
      <Typography variant="h1">Workflows</Typography>
      <LoadingSpinner isLoading={isLoading}>
        {data.length ? (
          <StyledTable>
            <StyledTableHead
              headerData={headerCells}
              sort={sort}
              setSort={setSort}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? sortedData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : sortedData
              ).map((workflow) => {
                return (
                  <StyledTableRow key={workflow.id}>
                    <TableCell>{workflow.name}</TableCell>
                    <TableCell>{workflow.description}</TableCell>
                    <TableCell>{workflow.process.length}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <StyledTableFooter
              data={sortedData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              type="workflows"
            />
          </StyledTable>
        ) : (
          <Typography>No Workflows found</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
