/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { DataStore, Hub, syncExpression } from 'aws-amplify';
import { workflowDefinitionsData } from '../datastore/workflowDefinition';
import { formsData } from '../datastore/form';
import { formSelectionsData } from '../datastore/formSelection';
import { workflowsData } from '../datastore/workflow';
import { TaskDataProps, tasksData } from '../datastore/task';
import { useCurrentUser } from './useCurrentUser';
import { CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import { Task, Workflow } from '../models';
import dayjs from 'dayjs';
import { WorkflowDefinitionDataProps } from '../datastore/interfaces/workflowDefinition';
import { WorkflowDataProps } from '../datastore/interfaces/workflow';
import { FormDataProps } from '../datastore/interfaces/form';
import { FormSelectionDataProps } from '../datastore/interfaces/formSelection';

export interface DatastoreContextProps {
  isDatastoreReady: boolean;
  workflowDefinitions: WorkflowDefinitionDataProps;
  workflows: WorkflowDataProps;
  forms: FormDataProps;
  formSelections: FormSelectionDataProps;
  tasks: TaskDataProps;
}

interface DatastoreProviderProps {
  children?: React.ReactNode;
}

const DatastoreContext = createContext<DatastoreContextProps>(
  {} as DatastoreContextProps
);

const defaultDatastoreConfig = {
  maxRecordsToSync: 50000,
  fullSyncInterval: 10080,
  syncPageSize: 80,
};

const useDatastore = () => useContext(DatastoreContext);

const DatastoreProvider = ({ children }: DatastoreProviderProps) => {
  const [isDatastoreReady, setIsDatastoreReady] = useState(false);
  const currentUser = useCurrentUser();

  const workflowDefinitionData = workflowDefinitionsData(currentUser);
  const formData = formsData(currentUser);
  const formSelectionData = formSelectionsData(currentUser);
  const workflowData = workflowsData(currentUser);
  const taskData = tasksData(currentUser);

  useEffect(() => {
    const listener = Hub.listen('datastore', async ({ payload }) => {
      switch (payload.event) {
        case 'ready':
          if (!isDatastoreReady) {
            setIsDatastoreReady(true);
          }
          break;
      }
    });

    const oneYearAgo = dayjs().add(-1, 'year').toISOString();

    const syncExpressions = [
      syncExpression(Workflow, () => {
        const department = currentUser.department;
        return (workflow) =>
          workflow?.or((workflow) => [
            workflow.availableToGroups.contains(department),
            workflow.availableToUsers.contains(currentUser.email),
            workflow.createdAt.gt(oneYearAgo),
          ]);
      }),
      syncExpression(Task, () => {
        const department = currentUser.department;
        return (task) =>
          task?.or((task) => [
            task.availableToGroups.contains(department),
            task.availableToUsers.contains(currentUser.email),
            task.createdAt.gt(oneYearAgo),
          ]);
      }),
    ];

    //Datastore documentation: https://docs.amplify.aws/lib/datastore/getting-started/q/platform/js/
    DataStore.configure({ ...defaultDatastoreConfig, syncExpressions });
    DataStore.start();

    return () => {
      listener();
    };
  }, []);

  const value = {
    isDatastoreReady: isDatastoreReady,
    workflowDefinitions: workflowDefinitionData,
    workflows: workflowData,
    forms: formData,
    formSelections: formSelectionData,
    tasks: taskData,
  };

  return isDatastoreReady ? (
    <DatastoreContext.Provider value={value}>
      {children}
    </DatastoreContext.Provider>
  ) : (
    <Dialog open={!isDatastoreReady} maxWidth="xs">
      <Stack spacing={8} sx={{ padding: '6em 8em', alignItems: 'center' }}>
        <CircularProgress />
        <Typography>Syncing Datastore...</Typography>
      </Stack>
    </Dialog>
  );
};

export { useDatastore, DatastoreProvider };
