import dayjs from 'dayjs';

export interface SortKey {
  key: string;
  direction: Order;
}

type Order = 'asc' | 'desc';

export function sortData(data: any[], sort: SortKey) {
  if (!data || !data.length) {
    return [];
  }

  return data.sort((a: any, b: any) => {
    const aValue = getValueWithCustomData(sort.key, a);
    const bValue = getValueWithCustomData(sort.key, b);

    if (aValue === undefined) {
      return sort.direction === 'asc' ? -1 : 1;
    }
    if (bValue === undefined) {
      return sort.direction === 'asc' ? 1 : -1;
    }

    if (dayjs.isDayjs(aValue) || dayjs.isDayjs(bValue)) {
      return sort.direction === 'asc'
        ? dayjs(aValue).isAfter(bValue)
          ? -1
          : 1
        : dayjs(bValue).isAfter(aValue)
        ? -1
        : 1;
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sort.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sort.direction === 'asc' ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });
}

export function getValue(key: string, obj: any) {
  try {
    return key.split('.').reduce((a: any, b: any) => a[b], obj);
  } catch (e) {
    return '';
  }
}

function getValueWithCustomData(path: string, obj: any): any {
  try {
    const steps = path.split('.').filter((p) => p);
    let value: any = obj;

    if (path.endsWith('.') && steps.length === 1) {
      steps[0] = path;
    }

    for (const step of steps) {
      if (typeof value === 'object' && value !== null && step in value) {
        value = value[step];
      } else {
        value = undefined;
        break;
      }
    }

    if (value !== undefined) {
      return value;
    }

    const customItem = obj.customData?.find(
      (item: any) => item.reference === path
    );

    return customItem ? customItem.value : undefined;
  } catch (e) {
    console.log(`Could not read custom value with path ${path}`);
    return '';
  }
}
