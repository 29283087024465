import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputComponentProps } from './Interfaces';
import { NumericFormat } from 'react-number-format';

interface NumberSettings {
  thousandSeparator?: boolean;
}

export default function NumberField({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  const minimum = isNaN(Number(element.minimum)) ? 0 : Number(element.minimum);
  const maximum = isNaN(Number(element.maximum))
    ? Number.MAX_SAFE_INTEGER
    : Number(element.maximum);

  const data: NumberSettings | null = element.data
    ? JSON.parse(element.data)
    : null;

  return (
    <Controller
      control={control}
      name={`${idPrefix}${element.dataReference}`}
      rules={{
        required: element.required,
        minLength: minimum,
        maxLength: maximum,
      }}
      render={({ field }) => (
        <NumericFormat
          value={field.value}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          inputRef={field.ref}
          customInput={TextField}
          disabled={element.readonly ?? false}
          fullWidth
          allowNegative={false}
          size="small"
          thousandSeparator={data?.thousandSeparator ?? ''}
          thousandsGroupStyle={data?.thousandSeparator ? 'thousand' : 'none'}
          isAllowed={(values) => {
            const { floatValue } = values;
            return (
              //allow for if the field is blank
              floatValue === undefined ||
              (floatValue >= minimum && floatValue <= maximum)
            );
          }}
        />
      )}
    />
  );
}
