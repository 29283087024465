import { Alert, Button, Stack, Typography } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { FormInputComponentProps } from './Interfaces';
import UploadArea from '../../upload/UploadArea';
import { maxFileSize, maxFileSizeError } from '../../../common';

export default function Attachments({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  const [customError, setCustomError] = useState('');

  const { fields, append, remove } = useFieldArray({
    name: `${idPrefix}${element.dataReference}`,
    control,
  });

  async function onAttachmentUpload(files: File[]) {
    if (files) {
      for (const file of files) {
        if (file.size > maxFileSize) {
          setCustomError(maxFileSizeError);
          break;
        } else {
          append({ file, fileName: file.name });
          setCustomError('');
        }
      }
    }
  }

  return (
    <Stack spacing={2}>
      {customError.length > 0 && <Alert severity="error">{customError}</Alert>}
      <UploadArea onUpload={onAttachmentUpload} />
      <Stack>
        {fields.map((field: any, i) => {
          return (
            <Stack key={field.id} direction="row" spacing={2}>
              <Typography>{field.fileName}</Typography>
              <Button variant="text" onClick={() => remove(i)}>
                X
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
