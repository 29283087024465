import { FormResultsData } from '../../components/form/Form';
import { FormElementModel } from '../../datastore/interfaces/form';
import { Attachment, Owner, TaskData } from '../../models';
import { FormElementTypes } from '../formElementTypes';
import { LocalAttachment } from '../interfaces/attachment';
import { FormElementVisibility } from '../interfaces/formElementVisiblity';
import { uploadFile } from '../uploadFiles';
import { suffixDivider } from './suffixes';

interface ParseFormResults {
  assignee: Owner | undefined;
  data: TaskData[];
  errors: ParseFormError[];
}

interface ParseFormError {
  reference: string;
  message: string;
}

interface ParseTaskData {
  key: string;
  group?: string;
  value: any;
  isChanged: boolean;
  groupId: string;
  sectionId: string;
  index: number;
  elementDataReference: string;
}

const ignoredTypes: string[] = [
  FormElementTypes.DataReference,
  FormElementTypes.Info,
];

export async function parseFormData(
  elements: FormElementModel[],
  data: FormResultsData[],
  visibility: FormElementVisibility[],
  previousElements?: FormElementModel[]
) {
  const parsedData: TaskData[] = [];
  let assignee: Owner | undefined = undefined;
  const errors: ParseFormError[] = [];

  for (const element of elements) {
    parseElement(element, visibility, data, parsedData, errors, assignee, true);
  }

  if (previousElements) {
    for (const previousElement of previousElements) {
      parseElement(
        previousElement,
        visibility,
        data,
        parsedData,
        errors,
        assignee,
        false
      );
    }
  }

  const results: ParseFormResults = {
    data: parsedData,
    assignee,
    errors,
  };

  return results;
}

async function parseElement(
  element: FormElementModel,
  visibility: FormElementVisibility[],
  data: FormResultsData[],
  parsedData: TaskData[],
  errors: ParseFormError[],
  assignee: Owner | undefined,
  isCurrent: boolean
) {
  if (
    !ignoredTypes.includes(element.type) &&
    visibility.some((v) => v.id === element.id && v.isVisible)
  ) {
    const taskDatas = retrieveTasks(data, element.dataReference);
    switch (element.type) {
      case FormElementTypes.Attachment:
        for (const taskData of taskDatas) {
          if (
            taskData &&
            taskData.value.length &&
            (taskData.isChanged || isCurrent)
          ) {
            const savedImages: Attachment[] = [];
            for (const attachment of taskData.value as LocalAttachment[]) {
              if (attachment.key && attachment.fileName) {
                savedImages.push({
                  key: attachment.key,
                  fileName: attachment.fileName,
                });
              } else if (attachment.file) {
                savedImages.push({
                  key: await uploadFile(attachment.file),
                  fileName: attachment.file.name,
                });
              }
            }

            parsedData.push({
              key: element.name,
              value: JSON.stringify(savedImages),
              reference: getElementDataReference(
                taskData,
                taskData.elementDataReference
              ),
              group: taskData.group,
              groupId: taskData.groupId,
            });
          }
        }

        break;
      case FormElementTypes.DateRange:
        const minKey = `${element.dataReference} Minimum`;
        const maxKey = `${element.dataReference} Maximum`;
        const mins = data.filter((d) => d.key === minKey);
        const maxes = data.filter((d) => d.key === maxKey);

        for (const min of mins) {
          const minError = checkError(element, min);
          if (minError) {
            errors.push(minError);
          }
          if (min && (min.isChanged || isCurrent)) {
            parsedData.push({
              key: `${element.name} Minimum`,
              value: min.value,
              reference: getElementDataReference(min, minKey),
              group: min.group,
              groupId: min.groupId,
            });
          }
        }

        for (const max of maxes) {
          const maxError = checkError(element, max);

          if (maxError) {
            errors.push(maxError);
          }

          if (max && (max.isChanged || isCurrent)) {
            parsedData.push({
              key: `${element.name} Maximum`,
              value: max.value,
              reference: getElementDataReference(max, maxKey),
              group: max.group,
              groupId: max.groupId,
            });
          }
        }

        break;
      case FormElementTypes.Assign:
      case FormElementTypes.User:
        const emailKey = `${element.dataReference} Email`;
        const nameKey = `${element.dataReference} Name`;
        const emails = data.filter((d) => d.key === emailKey);
        const names = data.filter((d) => d.key === nameKey);

        for (const email of emails) {
          const emailError = checkError(element, email);
          if (emailError) {
            errors.push(emailError);
          }
          if (email && (email.isChanged || isCurrent)) {
            parsedData.push({
              key: `${element.name} Email`,
              value: email.value,
              reference: getElementDataReference(email, emailKey),
              group: email.group,
              groupId: email.groupId,
            });
          }
        }

        for (const name of names) {
          const nameError = checkError(element, name);

          if (nameError) {
            errors.push(nameError);
          }

          if (name && (name.isChanged || isCurrent)) {
            parsedData.push({
              key: `${element.name} Name`,
              value: name.value,
              reference: getElementDataReference(name, nameKey),
              group: name.group,
              groupId: name.groupId,
            });
          }
        }

        //if an 'Assign' is used in a group section, then we'll only use the first.
        if (
          element.type === FormElementTypes.Assign &&
          emails.length &&
          names.length
        ) {
          assignee = {
            email: emails[0]?.value,
            name: names[0]?.value,
          };
        }
        break;
      case FormElementTypes.MultiTextField:
      case FormElementTypes.MultiUser:
        for (const taskData of taskDatas) {
          if (taskData.isChanged || isCurrent) {
            parsedData.push({
              key: element.name,
              value: taskData?.value
                .map((v: { value: string }) => v?.value ?? '')
                .join(', '),
              reference: taskData.elementDataReference,
              group: taskData.group,
              groupId: taskData.groupId,
            });
          }
        }
        break;
      default:
        for (const taskData of taskDatas) {
          const stringValue = `${taskData.value}`;
          if (
            taskData &&
            stringValue.length &&
            (taskData.isChanged || isCurrent)
          ) {
            const custom = data.find(
              (d) => d.key === `${element.dataReference} Custom`
            );
            let value = stringValue;
            let customValue = '';

            if (custom && custom.value) {
              const customError = checkError(element, custom);
              if (customError) {
                errors.push(customError);
              }
              const customOption = element.selection?.sections
                .find((s) => s.choices.some((c) => c.isCustom))
                ?.choices.find((c) => c.isCustom);
              const splitValue = value.split(', ');
              let indexToReplace = customOption
                ? splitValue.indexOf(customOption.choice)
                : -1;

              if (customOption && indexToReplace > -1) {
                customValue = customOption.choice;
                splitValue[indexToReplace] = custom.value;
                value = splitValue.join(', ');
              } else {
                const error = checkError(element, taskData);
                if (error) {
                  errors.push(error);
                }
              }
            } else {
              const error = checkError(element, taskData);
              if (error) {
                errors.push(error);
              }
            }
            parsedData.push({
              key: element.name,
              value: value,
              reference: taskData.elementDataReference,
              custom: customValue,
              group: taskData.group,
              groupId: taskData.groupId,
            });
          }
        }

        break;
    }
  }
}

function retrieveTasks(
  data: FormResultsData[],
  elementDataReference: string
): ParseTaskData[] {
  const taskDatas = data.filter((d) => d.key === elementDataReference);

  return taskDatas.map((taskData) => {
    return {
      ...taskData,
      elementDataReference: getElementDataReference(
        taskData,
        elementDataReference
      ),
    };
  });
}

function getElementDataReference(
  taskData: FormResultsData,
  elementDataReference: string
) {
  return taskData.group
    ? `${elementDataReference}${suffixDivider}${taskData.group}`
    : elementDataReference;
}

function checkError(element: FormElementModel, taskData: FormResultsData) {
  if (element.required && (!taskData.value || !taskData.value.length)) {
    const error: ParseFormError = {
      reference: `${taskData.sectionId}.${taskData.index}.${taskData.key}`,
      message: 'This is required',
    };
    return error;
  }
}
