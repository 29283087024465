import dayjs from 'dayjs';
import { TaskModel } from '../interfaces/task';
import { convertWorkflowToModel } from './workflow';
import { convertTaskDefinitionToModel } from './workflowDefinition';
import { Task } from '../../models';

export async function convertTaskToModel(data: Task) {
  const taskDefinition = await data.taskDefinition;
  const convertedTaskDefinition = await convertTaskDefinitionToModel(
    taskDefinition!
  );

  const workflow = await data.workflow;
  const convertedWorkflow = await convertWorkflowToModel(workflow!, false);

  const results: TaskModel = {
    id: data.id,
    workflowId: data.workflowId,
    status: data.status,
    complete: data.complete,
    taskDefinition: convertedTaskDefinition,
    taskNumber: data.taskNumber,
    assignedTo: {
      email: data.assignedToEmail,
      name: data.assignedToName,
    },
    data: data.data,
    dateDue: data.dateDue ? dayjs(data.dateDue) : undefined,
    workflow: convertedWorkflow,
  };

  return results;
}
