import { AppBar, Button, Stack, Typography } from '@mui/material';
import { Auth, DataStore } from 'aws-amplify';
import { useCurrentUser, useLoading } from '../../hooks';
import logo from '../../assets/images/mrllogo.png';
import HeaderButton from './HeaderButton';
import { useNavigate } from 'react-router-dom';
import HeaderMenuButton from './HeaderMenuButton';

export default function DesktopHeader() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const setLoading = useLoading();

  return (
    <AppBar sx={{ height: '3em', backgroundColor: 'black' }}>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          flex: 'space-between',
          paddingRight: '2em',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <HeaderButton onClick={() => navigate('/')}>
            <img src={logo} alt="MRL Logo" width="180px" />
          </HeaderButton>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'start', flex: 1 }}>
          <HeaderMenuButton onClick={() => navigate('/')}>
            Home
          </HeaderMenuButton>
          {user.department.length > 0 && (
            <HeaderMenuButton onClick={() => navigate('/groupWorkflows')}>
              Department
            </HeaderMenuButton>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          alignItems="center"
        >
          {process.env.REACT_APP_ENV?.length &&
          process.env.REACT_APP_ENV !== 'prod' ? (
            <Typography variant="caption">
              {process.env.REACT_APP_ENV} - v{process.env.REACT_APP_VERSION}
            </Typography>
          ) : (
            <></>
          )}
          <Typography>{user.name}</Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setLoading(true);
              DataStore.stop().then(() => {
                DataStore.clear();
                Auth.signOut();
                setLoading(false);
              });
            }}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </AppBar>
  );
}
