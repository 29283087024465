import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputWithErrorProps } from './Interfaces';
import { FormElementTypes } from '../../../common';

export default function TextFormField({
  element,
  control,
  error,
  idPrefix,
}: FormInputWithErrorProps) {
  const minimum = isNaN(Number(element.minimum)) ? 0 : Number(element.minimum);
  const maximum = isNaN(Number(element.maximum))
    ? Number.MAX_SAFE_INTEGER
    : Number(element.maximum);

  const multiline = element.type !== FormElementTypes.TextField;
  const maxRows =
    element.type === FormElementTypes.TextField
      ? 1
      : element.type === FormElementTypes.MediumTextField ||
        element.type === FormElementTypes.Comments
      ? 3
      : 6;

  return (
    <Controller
      control={control}
      name={`${idPrefix}${element.dataReference}`}
      rules={{
        required: element.required,
        minLength: minimum,
        maxLength: maximum,
        pattern: element.regex?.length
          ? {
              value: new RegExp(element.regex),
              message: 'Your answer has forbidden character(s).',
            }
          : { value: new RegExp(''), message: '' },
      }}
      render={({ field: { onChange, ...otherFields } }) => (
        <TextField
          {...otherFields}
          onChange={(e) => {
            if (element.regex?.length) {
              const reg = new RegExp(element.regex);
              if (!reg.test(e.target.value)) {
                return;
              }
            }
            onChange(e);
          }}
          error={error !== undefined}
          fullWidth
          size="small"
          multiline={multiline}
          rows={maxRows}
          disabled={element.readonly ?? false}
          inputProps={{
            minLength: minimum,
            maxLength: maximum,
          }}
        />
      )}
    />
  );
}
