/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import { useDatastore } from '../../hooks';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../components';
import { useParams } from 'react-router-dom';
import TopSection from './components/TopSection';
import HistoryTable from './components/HistoryTable';
import { WorkflowModel } from '../../datastore/interfaces/workflow';

export default function WorkflowHistory() {
  const { referenceNumber } = useParams();
  const store = useDatastore();
  const [workflow, setWorkflow] = useState<WorkflowModel>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      if (referenceNumber) {
        const results = await store.workflows.getByReferenceNumber(
          referenceNumber
        );
        if (results) {
          setWorkflow(results);
        }
      }

      setIsLoading(false);
    }

    getData();
  }, []);

  return (
    <Stack spacing={2}>
      <LoadingSpinner isLoading={isLoading}>
        {workflow ? (
          <>
            <TopSection workflow={workflow} />
            <HistoryTable workflow={workflow} />
          </>
        ) : (
          <Typography>No workflow found.</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
