/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Stack,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { useCurrentUser, useDatastore } from '../../hooks';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userCanEnterForm } from '../../common';
import { WorkflowModel } from '../../datastore/interfaces/workflow';

export default function NextTask() {
  const { referenceNumber } = useParams();
  const store = useDatastore();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [workflow, setWorkflow] = useState<WorkflowModel | undefined>();

  useEffect(() => {
    async function getData() {
      if (referenceNumber) {
        const workflow = await store.workflows.getByReferenceNumber(
          referenceNumber
        );
        if (workflow) {
          const currentTask = workflow.currentTasks.find((c) =>
            userCanEnterForm(c, workflow.definition, currentUser)
          );

          if (currentTask) {
            navigate(
              `/task/${workflow.referenceNumber}/${currentTask.taskNumber}`
            );
            return;
          }

          setWorkflow(workflow);
        }
      }
      setLoading(false);
    }

    getData();
  }, []);

  return (
    <Stack spacing={4} justifyContent="center">
      {loading ? (
        <>
          <CircularProgress />
          <Typography>Retrieving latest Task...</Typography>
        </>
      ) : workflow ? (
        <Stack spacing={2}>
          <Typography>{`${workflow.definition.name} is currently ${workflow.status}. You are not eligible to complete the current task.`}</Typography>
          <Typography>
            Please check the{' '}
            <MuiLink component={Link} to={`/workflows/${referenceNumber}`}>
              Workflow's History
            </MuiLink>{' '}
            and contact support if you think this is incorrect.
          </Typography>
        </Stack>
      ) : (
        <Typography>
          No Workflow found. Please check that the URL is correct.
        </Typography>
      )}
    </Stack>
  );
}
