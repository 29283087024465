import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import {
  Controller,
  Control,
  UseFormSetValue,
  FieldErrors,
  useWatch,
} from 'react-hook-form';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { useState } from 'react';
import { FormSelectionSection } from '../../../models';
import { Error } from '../components';

export interface CheckBoxSelectFieldProps {
  element: FormElementModel;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors;
  idPrefix: string;
}

export default function CheckBoxSelectField({
  element,
  control,
  setValue,
  errors,
  idPrefix,
}: CheckBoxSelectFieldProps) {
  const selection = element.selection;
  const customError = errors[`${idPrefix}${element.dataReference} Custom`];

  const watchField = useWatch({
    control,
    name: `${idPrefix}${element.dataReference}`,
  });

  const [state, setState] = useState<any>(() => {
    const choices = selection?.sections.flatMap((s) => s.choices);
    if (choices) {
      return choices.reduce((obj, item) => {
        return {
          ...obj,
          [item.choice]: watchField.includes(item.choice),
        };
      }, {});
    }
  });

  if (!selection) {
    return (
      <Typography>
        Error! CheckBox Selection element does not have any selection data.
      </Typography>
    );
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newState = {
      ...state,
      [event.target.name]: event.target.checked,
    };
    const trueKeys = Object.keys(newState).filter((k) => newState[k]);
    setState(newState);
    setValue(`${idPrefix}${element.dataReference}`, trueKeys.join(', '));
  }

  const allowCustom = selection.sections.flatMap((s) =>
    s.choices.filter((c) => c.isCustom).map((c) => c.choice)
  );

  function selectGroup(section: FormSelectionSection) {
    const items = section.choices.map((c) => {
      return (
        <FormControlLabel
          key={c.choice}
          control={
            <Checkbox
              checked={state[c.choice]}
              onChange={handleChange}
              name={c.choice}
            />
          }
          label={c.choice}
        />
      );
    });

    return section.name
      ? [<Typography key={section.name}>{section.name}</Typography>, items]
      : items;
  }

  return (
    <>
      <FormControl
        sx={{ m: 3 }}
        component="fieldset"
        variant="standard"
        disabled={element.readonly ?? false}
      >
        <FormGroup>
          {selection.sections.map((section) => {
            return selectGroup(section);
          })}
        </FormGroup>
      </FormControl>
      {allowCustom.some((a) => state[a]) ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h5">Custom:</Typography>
          <Controller
            control={control}
            name={`${idPrefix}${element.dataReference}.custom`}
            rules={{ required: true }}
            render={({ field }) => (
              <OutlinedInput {...field} fullWidth size="small" />
            )}
          />
          <Error element={element} error={customError} />
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}
