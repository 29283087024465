/* eslint-disable react-hooks/exhaustive-deps */
import { TableBody, TableCell, useMediaQuery } from '@mui/material';
import { StyledTable, StyledTableHead, StyledTableRow } from '..';
import { useTheme } from '@mui/material/styles';
import { Fragment } from 'react';
import CustomDataTableRow from './CustomDataTableRow';
import { TableData } from '../../common/tables/generateCustomTableData';

interface CustomDataTableProps {
  data: TableData[];
  maxColumns: number;
}

export default function CustomDataTable({
  data,
  maxColumns,
}: CustomDataTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledTable>
      <StyledTableHead
        headerData={[
          {
            id: 'field',
            label: isMobile ? 'Field' : 'Field/Attribute',
            width: '20%',
          },
          {
            id: 'value',
            label: isMobile ? 'Value' : 'Value/Data',
          },
        ]}
        maxColumns={maxColumns + 1}
      />
      <TableBody>
        {data.map((entry, index) => {
          const excess = maxColumns - entry.groups.length;
          const excessColumns =
            excess > 0 ? Array.from(Array(excess).keys()) : [];
          return (
            <Fragment key={index}>
              {entry.groups.length ? (
                <>
                  <StyledTableRow
                    sx={{
                      borderBottom: `1px solid var(--divider, ${theme.palette.secondary.dark})`,
                    }}
                  />
                  <StyledTableRow
                    sx={{
                      '& td': {
                        backgroundColor: theme.palette.secondary.light,
                      },
                    }}
                  >
                    <TableCell />
                    {entry.groups.map((group) => (
                      <TableCell key={group}>{group}</TableCell>
                    ))}
                    {excessColumns.map((col) => (
                      <TableCell key={col} />
                    ))}
                  </StyledTableRow>
                </>
              ) : (
                <></>
              )}
              {entry.data.map((keyValues) => {
                return (
                  <CustomDataTableRow
                    key={keyValues.key}
                    groups={entry.groups}
                    keyValues={keyValues}
                  />
                );
              })}
              {entry.groups.length ? (
                <StyledTableRow
                  sx={{
                    borderBottom: `1px solid var(--divider, ${theme.palette.secondary.dark})`,
                  }}
                />
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}
