import dayjs from 'dayjs';
import { Form, FormElement, FormSection, FormSelection } from '../../models';
import {
  FormElementModel,
  FormModel,
  FormSectionModel,
} from '../interfaces/form';
import { convertFormSelectionToModel } from './formSelection';
import { FormSectionDirection } from '../../common';

export async function convertFormToModel(
  data: Form,
  loadedFormSections?: FormSection[],
  loadedFormElements?: FormElement[],
  loadedFormSelections?: FormSelection[]
) {
  const sections =
    loadedFormSections?.filter((s) => s.formId === data.id) ??
    (await data.sections.toArray());

  const sectionModels: FormSectionModel[] = [];

  for (const section of sections) {
    sectionModels.push(
      await convertFormSectionToModel(
        section,
        loadedFormElements,
        loadedFormSelections
      )
    );
  }

  const results: FormModel = {
    id: data.id,
    name: data.name,
    description: data.description,
    sections: sectionModels,
    createdAt: dayjs(data.createdAt),
    updatedAt: dayjs(data.updatedAt),
  };

  return results;
}

async function convertFormSectionToModel(
  data: FormSection,
  loadedFormElements?: FormElement[],
  loadedFormSelections?: FormSelection[]
) {
  const elements =
    loadedFormElements?.filter((l) => l.formSectionId === data.id) ??
    (await data.elements.toArray());
  const elementModels: FormElementModel[] = [];

  for (const element of elements) {
    elementModels.push(
      await convertFormElementToModel(element, loadedFormSelections)
    );
  }

  const results: FormSectionModel = {
    id: data.id,
    formId: data.formId,
    name: data.name,
    direction: (data.direction ??
      FormSectionDirection.Vertical) as FormSectionDirection,
    groups: data.groups
      ? {
          groupId: data.groups.groupId ?? '',
          direction: data.groups.direction ?? FormSectionDirection.Vertical,
          fixed: data.groups.fixed ?? false,
          names: data.groups.names ?? [],
          maximum: data.groups.maximum ?? 5,
        }
      : null,
    elements: elementModels,
  };

  return results;
}

async function convertFormElementToModel(
  data: FormElement,
  loadedFormSelections?: FormSelection[]
) {
  const results: FormElementModel = {
    id: data.id,
    formSectionId: data.formSectionId,
    selectionId: data.selectionId,
    order: data.order,
    name: data.name,
    type: data.type,
    workflowReference: data.workflowReference ?? '',
    dataReference: data.dataReference ?? '',
    data: data.data ?? '',
    help: data.help ?? '',
    readonly: data.readonly ?? false,
    hidden: data.hidden ?? false,
    regex: data.regex ?? '',
    required: data.required,
    default: data.default,
    minimum: data.minimum ?? '',
    maximum: data.maximum ?? '',
    flex: data.flex,
    outcomes: data.outcomes,
  };

  if (data.selectionId) {
    const selection =
      loadedFormSelections?.find((s) => s.id === data.selectionId) ??
      (await data.selection);

    if (selection) {
      results.selection = convertFormSelectionToModel(selection);
    }
  }

  return results;
}
