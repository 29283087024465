import { TaskData } from '../../models';

export interface TableData {
  id: string;
  groups: string[];
  data: [
    {
      key: string;
      values: { group: string; value: string }[];
    },
  ];
}

export function generateCustomTableData(taskDatas: TaskData[]) {
  const data: TableData[] = [];

  for (const taskData of taskDatas) {
    if (!taskData.group) {
      const existing = data.find((d) => d.groups.length === 0);
      const innerData = {
        key: taskData.key,
        values: [{ group: '', value: taskData.value }],
      };

      if (existing) {
        existing.data.push(innerData);
      } else {
        data.push({
          id: '',
          groups: [],
          data: [innerData],
        });
      }
    } else {
      const existingWithGroupId = data.find((d) => d.id === taskData.groupId);

      if (existingWithGroupId) {
        if (!existingWithGroupId.groups.includes(taskData.group)) {
          existingWithGroupId.groups.push(taskData.group);
        }

        const existingWithKey = existingWithGroupId.data.find(
          (d) => d.key === taskData.key
        );

        if (existingWithKey) {
          existingWithKey.values.push({
            group: taskData.group,
            value: taskData.value,
          });
        } else {
          existingWithGroupId.data.push({
            key: taskData.key,
            values: [
              {
                group: taskData.group,
                value: taskData.value,
              },
            ],
          });
        }
      } else {
        data.push({
          id: taskData.groupId ?? '',
          groups: [taskData.group],
          data: [
            {
              key: taskData.key,
              values: [
                {
                  group: taskData.group,
                  value: taskData.value,
                },
              ],
            },
          ],
        });
      }
    }
  }

  //fill in the blanks!
  for (const entry of data) {
    for (const innerEntry of entry.data) {
      for (const group of entry.groups) {
        if (!innerEntry.values.find((v) => v.group === group)) {
          innerEntry.values.push({ group, value: '' });
        }
      }
    }
  }

  return data;
}
