import { InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputComponentProps } from './Interfaces';
import { NumericFormat } from 'react-number-format';

export default function CostField({
  element,
  control,
  idPrefix,
}: FormInputComponentProps) {
  const minimum = isNaN(Number(element.minimum)) ? 0 : Number(element.minimum);
  const maximum = isNaN(Number(element.maximum))
    ? Number.MAX_SAFE_INTEGER
    : Number(element.maximum);

  return (
    <Controller
      control={control}
      name={`${idPrefix}${element.dataReference}`}
      rules={{
        required: element.required,
        minLength: minimum,
        maxLength: maximum,
      }}
      render={({ field }) => (
        <NumericFormat
          value={field.value}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          inputRef={field.ref}
          customInput={TextField}
          disabled={element.readonly ?? false}
          fullWidth
          allowNegative={false}
          thousandSeparator=","
          thousandsGroupStyle="thousand"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{
            minLength: minimum,
            maxLength: maximum,
          }}
        />
      )}
    />
  );
}
