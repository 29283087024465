import dayjs from 'dayjs';
import {
  FormElement,
  FormSection,
  FormSelection,
  Task,
  TaskData,
  TaskDefinition,
  Workflow,
  WorkflowDefinition,
} from '../../models';
import {
  WorkflowAttachmentModel,
  WorkflowModel,
  WorkflowNoteModel,
  WorkflowTaskModel,
} from '../interfaces/workflow';
import { DataStore, Storage } from 'aws-amplify';
import { distinct } from '../../common';
import {
  convertTaskDefinitionToModel,
  convertWorkflowDefinitionToModel,
} from './workflowDefinition';
import { Form } from '../../models';

export async function convertWorkflowsToModelsAndEagerLoad(data: Workflow[]) {
  const workflowIds = data.map((w) => w.id);
  const definitionIds = data.map((w) => w.definitionId);

  const workflowDefinitions = await DataStore.query(
    WorkflowDefinition,
    (definition) =>
      definition.or((definition) =>
        definitionIds.map((w) => definition.id.eq(w))
      )
  );

  const tasks = await DataStore.query(Task, (task) =>
    task.or((task) => workflowIds.map((w) => task.workflowId.eq(w)))
  );

  const taskDefinitionIds = tasks.map((t) => t.taskDefinitionId);
  const taskDefinitions = await DataStore.query(TaskDefinition, (definition) =>
    definition.or((definition) =>
      taskDefinitionIds.map((t) => definition.id.eq(t))
    )
  );
  const formIds = taskDefinitions.map((t) => t.formId ?? '');
  const forms = await DataStore.query(Form, (form) =>
    form.or((form) => formIds.map((f) => form.id.eq(f)))
  );

  const formSections = await DataStore.query(FormSection, (section) =>
    section.or((section) => formIds.map((f) => section.formId.eq(f)))
  );

  const sectionIds = formSections.map((f) => f.id);
  const formElements = await DataStore.query(FormElement, (element) =>
    element.or((element) => sectionIds.map((f) => element.formSectionId.eq(f)))
  );

  const selectionIds = formElements.map((f) => f.selectionId ?? '');
  const formSelections = await DataStore.query(FormSelection, (selection) =>
    selection.or((selection) => selectionIds.map((s) => selection.id.eq(s)))
  );

  const workflows: WorkflowModel[] = [];

  for (const entry of data) {
    workflows.push(
      await convertWorkflowToModel(
        entry,
        true,
        workflowDefinitions,
        tasks,
        taskDefinitions,
        forms,
        formSections,
        formElements,
        formSelections
      )
    );
  }

  return workflows;
}

export async function convertWorkflowToModel(
  data: Workflow,
  includeTasks: boolean = true,
  loadedWorkflowDefinitions?: WorkflowDefinition[],
  loadedTasks?: Task[],
  loadedTaskDefinitions?: TaskDefinition[],
  loadedForms?: Form[],
  loadedFormSections?: FormSection[],
  loadedFormElements?: FormElement[],
  loadedFormSelections?: FormSelection[]
) {
  const tasks =
    loadedTasks?.filter((task) => task.workflowId === data.id) ??
    (await data.history.toArray());
  const taskModels: WorkflowTaskModel[] = [];

  for (const task of tasks.sort((a, b) => a.taskNumber - b.taskNumber)) {
    const convertedTask = await convertTaskToModel(
      task,
      loadedTaskDefinitions,
      loadedForms,
      loadedFormSections,
      loadedFormElements,
      loadedFormSelections
    );
    taskModels.push(convertedTask);
  }

  const customData: TaskData[] = distinct(
    taskModels
      .sort((a, b) => b.taskNumber - a.taskNumber)
      .flatMap((t) => t.data),
    'reference'
  );

  const notes: WorkflowNoteModel[] = [];

  for (const note of data.notes) {
    const attachments: WorkflowAttachmentModel[] = [];

    for (const attachment of note.attachments) {
      const downloadUrl = await Storage.get(attachment.key);

      attachments.push({
        key: attachment.key,
        fileName: attachment.fileName,
        url: downloadUrl,
      });
    }

    notes.push({
      createdBy: note.createdBy,
      note: note.note ?? '',
      attachments: attachments,
      date: dayjs(note.date),
    });
  }

  const workflowDefinition =
    loadedWorkflowDefinitions?.find((w) => w.id === data.definitionId) ??
    (await data.definition);

  if (workflowDefinition) {
    const results: WorkflowModel = {
      id: data.id,
      definitionId: data.definitionId,
      referenceNumber: data.referenceNumber,
      description: data.description ?? '',
      status: data.status,
      complete: data.complete,
      currentTasks: includeTasks
        ? taskModels.filter((t) => data.currentTasks.includes(t.id))
        : [],
      history: includeTasks ? taskModels : [],
      owner: {
        email: data.ownerEmail,
        name: data.ownerName,
      },
      notes: notes,
      definition: await convertWorkflowDefinitionToModel(workflowDefinition),
      customData: customData,
      created: dayjs(data.createdAt),
      updated: dayjs(data.updatedAt),
      updatedBy: data.updatedBy,
      completedAt: data.completedAt ? dayjs(data.completedAt) : undefined,
    };

    return results;
  }

  throw new Error('Unable to convert Workflow');
}

async function convertTaskToModel(
  data: Task,
  loadedTaskDefinitions?: TaskDefinition[],
  loadedForms?: Form[],
  loadedFormSections?: FormSection[],
  loadedFormElements?: FormElement[],
  loadedFormSelections?: FormSelection[]
) {
  const taskDefinition =
    loadedTaskDefinitions?.find((t) => t.id === data.taskDefinitionId) ??
    (await data.taskDefinition);
  const convertedTaskDefinition = await convertTaskDefinitionToModel(
    taskDefinition!,
    loadedForms,
    loadedFormSections,
    loadedFormElements,
    loadedFormSelections
  );
  const results: WorkflowTaskModel = {
    id: data.id,
    workflowId: data.workflowId,
    status: data.status,
    complete: data.complete,
    reassigned: data.reassigned,
    taskDefinition: convertedTaskDefinition,
    taskNumber: data.taskNumber,
    previousTaskNumber: data.previousTaskNumber ?? undefined,
    assignedTo: {
      email: data.assignedToEmail,
      name: data.assignedToName,
    },
    data: data.data,
    comments: data.comments ?? '',
    dateDue: data.dateDue ? dayjs(data.dateDue) : undefined,
    outcomeTriggers: data.outcomeTriggers,
    created: dayjs(data.createdAt),
    updated: dayjs(data.updatedAt),
  };

  return results;
}
