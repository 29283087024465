import { Alert, Snackbar, AlertColor } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

interface AlertProviderProps {
  children?: React.ReactNode;
}

const AlertContext = createContext(
  (message: string, severity: AlertColor) => {}
);

export const useAlert = () => useContext(AlertContext);

export function AlertProvider({ children }: AlertProviderProps) {
  const [message, setMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>('success');

  function showSnackbar(message: string, severity: AlertColor) {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <AlertContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        message={message}
        onClose={onClose}
      >
        <Alert severity={severity} onClose={onClose} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
}
