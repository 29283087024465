/* eslint-disable react-hooks/exhaustive-deps */
import {
  Link as MuiLink,
  Paper,
  Stack,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import {
  StyledTable,
  StyledTableHead,
  StyledTableRow,
} from '../../../components';
import { dateFormat } from '../../../common';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';

interface NotesTableProps {
  workflow: WorkflowModel;
}

export default function NotesTable({ workflow }: NotesTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    <Stack spacing={2}>
      <Typography variant="h3">Notes</Typography>
      {workflow.notes.map((note, i) => {
        const date = dayjs(note.date).format(dateFormat);
        return (
          <Paper key={i}>
            <Stack spacing={1} sx={{ padding: '1em' }}>
              <Typography fontWeight="bold">
                {date} - {note.createdBy.name}
              </Typography>
              <Typography>{note.note}</Typography>
              <Stack>
                {note.attachments.map((attachment) => {
                  return (
                    <MuiLink
                      key={attachment.key}
                      target="_blank"
                      href={attachment.url}
                      rel="noreferrer"
                    >
                      {attachment.fileName}
                    </MuiLink>
                  );
                })}
              </Stack>
            </Stack>
          </Paper>
        );
      })}
    </Stack>
  ) : (
    <Stack spacing={2}>
      <Typography variant="h3">Notes</Typography>
      <StyledTable>
        <StyledTableHead
          headerData={[
            { id: 'date', label: 'Date', width: '10%' },
            {
              id: 'owner.name',
              label: 'By',
              width: '15%',
            },
            {
              id: 'note',
              label: 'Note',
            },
            {
              id: 'attachments',
              label: 'Attachments',
              width: '30%',
            },
          ]}
        />
        <TableBody>
          {workflow.notes.map((note, i) => {
            const date = dayjs(note.date).format(dateFormat);
            return (
              <StyledTableRow key={i}>
                <TableCell>{date}</TableCell>
                <TableCell>{note.createdBy.name}</TableCell>
                <TableCell>{note.note}</TableCell>
                <TableCell>
                  <Stack>
                    {note.attachments.map((attachment) => {
                      return (
                        <MuiLink
                          key={attachment.key}
                          target="_blank"
                          href={attachment.url}
                          rel="noreferrer"
                        >
                          {attachment.fileName}
                        </MuiLink>
                      );
                    })}
                  </Stack>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </Stack>
  );
}
