/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Collapse, Stack } from '@mui/material';
import {
  UseFormWatch,
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
  UseFormClearErrors,
} from 'react-hook-form';
import { WorkflowModel } from '../../datastore/interfaces/workflow';
import { FormElementVisibility } from '../../common/interfaces/formElementVisiblity';
import InnerForm from './InnerForm';
import { FormModel } from '../../datastore/interfaces/form';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface PreviousFormProps {
  formDefinition: FormModel;
  watch: UseFormWatch<any>;
  control: Control<any>;
  workflow: WorkflowModel;
  errors: FieldErrors;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  clearErrors: UseFormClearErrors<any>;
  visibility: FormElementVisibility[];
}

export default function PreviousForm({
  formDefinition,
  watch,
  control,
  workflow,
  errors,
  setValue,
  getValues,
  clearErrors,
  visibility,
}: PreviousFormProps) {
  const [open, setOpen] = useState(false);

  return (
    <Stack spacing={1}>
      <Button
        variant="outlined"
        onClick={() => setOpen(!open)}
        endIcon={open ? <ArrowDownwardIcon /> : <ArrowForwardIcon />}
        sx={{ width: '100%', padding: '0.5em 1em' }}
      >
        {`Click to edit the ${formDefinition.name}`}
      </Button>
      <Collapse in={open} timeout="auto">
        <InnerForm
          formDefinition={formDefinition}
          watch={watch}
          control={control}
          workflow={workflow}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          clearErrors={clearErrors}
          visibility={visibility}
          skipSubmitButton
        />
      </Collapse>
    </Stack>
  );
}
