/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material/styles';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';
import { useCurrentUser } from '../../../hooks';

interface TopSectionProps {
  workflow: WorkflowModel;
  inDepartment: boolean;
  setNoteOpen: (set: boolean) => void;
  setReassignOpen: (set: boolean) => void;
  setUnassignOpen: (set: boolean) => void;
  backNavigation: string;
}

export default function TopSection({
  workflow,
  inDepartment,
  setNoteOpen,
  setReassignOpen,
  setUnassignOpen,
  backNavigation,
}: TopSectionProps) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Button onClick={() => navigate(backNavigation)}>
          <ArrowBackIosIcon />
        </Button>
        <Typography variant="h4">
          {workflow.referenceNumber} - {workflow.definition.name}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {inDepartment && (
          <Button variant="contained" onClick={() => setNoteOpen(true)}>
            Add Note
          </Button>
        )}

        {workflow.currentTasks.some(
          (c) => c.assignedTo.email === currentUser.email
        ) || inDepartment ? (
          <Button variant="contained" onClick={() => setReassignOpen(true)}>
            Reassign
          </Button>
        ) : (
          <></>
        )}

        {inDepartment && workflow.currentTasks?.length > 0 && (
          <Button variant="contained" onClick={() => setUnassignOpen(true)}>
            Unassign
          </Button>
        )}
      </Stack>
    </>
  ) : (
    <Stack direction="row" spacing={2} alignItems="center">
      <Button onClick={() => navigate(backNavigation)}>
        <ArrowBackIosIcon fontSize="large" />
      </Button>
      <Typography variant="h1">
        {workflow.referenceNumber} - {workflow.definition.name}
      </Typography>
      {workflow.complete ? (
        <></>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          sx={{ flex: 1 }}
        >
          {inDepartment && (
            <Button variant="contained" onClick={() => setNoteOpen(true)}>
              Add Note
            </Button>
          )}

          {workflow.currentTasks.some(
            (c) => c.assignedTo.email === currentUser.email
          ) || inDepartment ? (
            <Button variant="contained" onClick={() => setReassignOpen(true)}>
              Reassign
            </Button>
          ) : (
            <></>
          )}
          {inDepartment && workflow.currentTasks?.length > 0 && (
            <Button variant="contained" onClick={() => setUnassignOpen(true)}>
              Unassign
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
