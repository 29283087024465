export function distinct(array: any[], key: string) {
  if (!array || !array.length) {
    return array;
  }

  const uniqueBy =
    (k: any, s = new Set()) =>
    (o: any) =>
      !s.has(o[k]) && s.add(o[k]);
  return array.filter(uniqueBy(key));
}
