export const schema = {
  models: {
    WorkflowDefinition: {
      name: 'WorkflowDefinition',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        descriptionReference: {
          name: 'descriptionReference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        groups: {
          name: 'groups',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        emails: {
          name: 'emails',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        multiWorkflow: {
          name: 'multiWorkflow',
          isArray: false,
          type: 'Boolean',
          isRequired: true,
          attributes: [],
        },
        multiDataReference: {
          name: 'multiDataReference',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        customColumns: {
          name: 'customColumns',
          isArray: true,
          type: {
            nonModel: 'CustomColumn',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        process: {
          name: 'process',
          isArray: true,
          type: {
            model: 'TaskDefinition',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['workflowDefinition'],
          },
        },
        workflows: {
          name: 'workflows',
          isArray: true,
          type: {
            model: 'Workflow',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['definition'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'WorkflowDefinitions',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    TaskDefinition: {
      name: 'TaskDefinition',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        workflowDefinitionId: {
          name: 'workflowDefinitionId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        formId: {
          name: 'formId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        requiredTasksCompleted: {
          name: 'requiredTasksCompleted',
          isArray: true,
          type: 'ID',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        order: {
          name: 'order',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        daysDue: {
          name: 'daysDue',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: [],
        },
        viewPreviousData: {
          name: 'viewPreviousData',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        editPreviousForms: {
          name: 'editPreviousForms',
          isArray: true,
          type: 'ID',
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        elementsToHide: {
          name: 'elementsToHide',
          isArray: true,
          type: 'ID',
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        type: {
          name: 'type',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        groups: {
          name: 'groups',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        emails: {
          name: 'emails',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        form: {
          name: 'form',
          isArray: false,
          type: {
            model: 'Form',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['formId'],
          },
        },
        workflowDefinition: {
          name: 'workflowDefinition',
          isArray: false,
          type: {
            model: 'WorkflowDefinition',
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['workflowDefinitionId'],
          },
        },
        outcomes: {
          name: 'outcomes',
          isArray: true,
          type: {
            nonModel: 'TaskOutcomeDefinition',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        tasks: {
          name: 'tasks',
          isArray: true,
          type: {
            model: 'Task',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['taskDefinition'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'TaskDefinitions',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'workflowdefinition-taskdefinition-index',
            fields: ['workflowDefinitionId'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'form-taskdefinition-index',
            fields: ['formId'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Form: {
      name: 'Form',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        sections: {
          name: 'sections',
          isArray: true,
          type: {
            model: 'FormSection',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['formId'],
          },
        },
        taskDefinitions: {
          name: 'taskDefinitions',
          isArray: true,
          type: {
            model: 'TaskDefinition',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['form'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'Forms',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    FormSection: {
      name: 'FormSection',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        formId: {
          name: 'formId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        groups: {
          name: 'groups',
          isArray: false,
          type: {
            nonModel: 'FormSectionGroups',
          },
          isRequired: false,
          attributes: [],
        },
        direction: {
          name: 'direction',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        elements: {
          name: 'elements',
          isArray: true,
          type: {
            model: 'FormElement',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['formSectionId'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'FormSections',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'form-section-index',
            fields: ['formId'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    FormElement: {
      name: 'FormElement',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        formSectionId: {
          name: 'formSectionId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        selectionId: {
          name: 'selectionId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        order: {
          name: 'order',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: [],
        },
        type: {
          name: 'type',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        required: {
          name: 'required',
          isArray: false,
          type: 'Boolean',
          isRequired: true,
          attributes: [],
        },
        default: {
          name: 'default',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        workflowReference: {
          name: 'workflowReference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        dataReference: {
          name: 'dataReference',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        data: {
          name: 'data',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        readonly: {
          name: 'readonly',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        hidden: {
          name: 'hidden',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        help: {
          name: 'help',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        regex: {
          name: 'regex',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        minimum: {
          name: 'minimum',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        maximum: {
          name: 'maximum',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        flex: {
          name: 'flex',
          isArray: false,
          type: 'Float',
          isRequired: false,
          attributes: [],
        },
        outcomes: {
          name: 'outcomes',
          isArray: true,
          type: {
            nonModel: 'FormOutcome',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        selection: {
          name: 'selection',
          isArray: false,
          type: {
            model: 'FormSelection',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['selectionId'],
          },
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'FormElements',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'formsection-element-index',
            fields: ['formSectionId'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'formelement-formselection-index',
            fields: ['selectionId'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    FormSelection: {
      name: 'FormSelection',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        subName: {
          name: 'subName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        allowCustomSubChoice: {
          name: 'allowCustomSubChoice',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        formElements: {
          name: 'formElements',
          isArray: true,
          type: {
            model: 'FormElement',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['selection'],
          },
        },
        sections: {
          name: 'sections',
          isArray: true,
          type: {
            nonModel: 'FormSelectionSection',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'FormSelections',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Workflow: {
      name: 'Workflow',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        definitionId: {
          name: 'definitionId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        referenceNumber: {
          name: 'referenceNumber',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        complete: {
          name: 'complete',
          isArray: false,
          type: 'Boolean',
          isRequired: true,
          attributes: [],
        },
        currentTasks: {
          name: 'currentTasks',
          isArray: true,
          type: 'ID',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        ownerEmail: {
          name: 'ownerEmail',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        ownerName: {
          name: 'ownerName',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        notes: {
          name: 'notes',
          isArray: true,
          type: {
            nonModel: 'WorkflowNote',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        history: {
          name: 'history',
          isArray: true,
          type: {
            model: 'Task',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['workflow'],
          },
        },
        definition: {
          name: 'definition',
          isArray: false,
          type: {
            model: 'WorkflowDefinition',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['definitionId'],
          },
        },
        updatedBy: {
          name: 'updatedBy',
          isArray: false,
          type: {
            nonModel: 'Owner',
          },
          isRequired: true,
          attributes: [],
        },
        availableToUsers: {
          name: 'availableToUsers',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        availableToGroups: {
          name: 'availableToGroups',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: true,
          attributes: [],
        },
        completedAt: {
          name: 'completedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'Workflows',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'workflowdefinition-workflow-index',
            fields: ['definitionId'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Task: {
      name: 'Task',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        workflowId: {
          name: 'workflowId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        taskDefinitionId: {
          name: 'taskDefinitionId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        taskNumber: {
          name: 'taskNumber',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: [],
        },
        previousTaskNumber: {
          name: 'previousTaskNumber',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: [],
        },
        assignedToEmail: {
          name: 'assignedToEmail',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        assignedToName: {
          name: 'assignedToName',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        complete: {
          name: 'complete',
          isArray: false,
          type: 'Boolean',
          isRequired: true,
          attributes: [],
        },
        reassigned: {
          name: 'reassigned',
          isArray: false,
          type: 'Boolean',
          isRequired: true,
          attributes: [],
        },
        skipTrigger: {
          name: 'skipTrigger',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        data: {
          name: 'data',
          isArray: true,
          type: {
            nonModel: 'TaskData',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        comments: {
          name: 'comments',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        dateDue: {
          name: 'dateDue',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        outcomeTriggers: {
          name: 'outcomeTriggers',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        taskDefinition: {
          name: 'taskDefinition',
          isArray: false,
          type: {
            model: 'TaskDefinition',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['taskDefinitionId'],
          },
        },
        workflow: {
          name: 'workflow',
          isArray: false,
          type: {
            model: 'Workflow',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['workflowId'],
          },
        },
        availableToUsers: {
          name: 'availableToUsers',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        availableToGroups: {
          name: 'availableToGroups',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: true,
          attributes: [],
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: 'Tasks',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            fields: ['id'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'workflow-task-index',
            fields: ['workflowId'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'taskdefinition-task-index',
            fields: ['taskDefinitionId'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {},
  nonModels: {
    CustomColumn: {
      name: 'CustomColumn',
      fields: {
        label: {
          name: 'label',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        workflowReference: {
          name: 'workflowReference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        dataReference: {
          name: 'dataReference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
      },
    },
    TaskOutcomeDefinition: {
      name: 'TaskOutcomeDefinition',
      fields: {
        triggers: {
          name: 'triggers',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        nextTask: {
          name: 'nextTask',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        nextStatus: {
          name: 'nextStatus',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        assignTo: {
          name: 'assignTo',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        emails: {
          name: 'emails',
          isArray: true,
          type: {
            nonModel: 'TaskEmailDefinition',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    TaskEmailDefinition: {
      name: 'TaskEmailDefinition',
      fields: {
        to: {
          name: 'to',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        toRef: {
          name: 'toRef',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        from: {
          name: 'from',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        cc: {
          name: 'cc',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        ccRef: {
          name: 'ccRef',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        addresses: {
          name: 'addresses',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        template: {
          name: 'template',
          isArray: false,
          type: {
            nonModel: 'TaskEmailTemplate',
          },
          isRequired: true,
          attributes: [],
        },
        tables: {
          name: 'tables',
          isArray: true,
          type: {
            nonModel: 'TaskEmailTable',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    TaskEmailTemplate: {
      name: 'TaskEmailTemplate',
      fields: {
        subject: {
          name: 'subject',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        body: {
          name: 'body',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
      },
    },
    TaskEmailTable: {
      name: 'TaskEmailTable',
      fields: {
        headers: {
          name: 'headers',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        values: {
          name: 'values',
          isArray: true,
          type: {
            nonModel: 'TaskEmailTableRow',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    TaskEmailTableRow: {
      name: 'TaskEmailTableRow',
      fields: {
        class: {
          name: 'class',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        dynamic: {
          name: 'dynamic',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        cells: {
          name: 'cells',
          isArray: true,
          type: {
            nonModel: 'TaskEmailTableCell',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    TaskEmailTableCell: {
      name: 'TaskEmailTableCell',
      fields: {
        value: {
          name: 'value',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        colspan: {
          name: 'colspan',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: [],
        },
      },
    },
    FormSectionGroups: {
      name: 'FormSectionGroups',
      fields: {
        groupId: {
          name: 'groupId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        direction: {
          name: 'direction',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        fixed: {
          name: 'fixed',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        names: {
          name: 'names',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        maximum: {
          name: 'maximum',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: [],
        },
      },
    },
    FormSelectionSection: {
      name: 'FormSelectionSection',
      fields: {
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        choices: {
          name: 'choices',
          isArray: true,
          type: {
            nonModel: 'FormSelectionChoice',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
      },
    },
    FormSelectionChoice: {
      name: 'FormSelectionChoice',
      fields: {
        choice: {
          name: 'choice',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        isCustom: {
          name: 'isCustom',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        customLabel: {
          name: 'customLabel',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        subChoices: {
          name: 'subChoices',
          isArray: true,
          type: 'String',
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    FormOutcome: {
      name: 'FormOutcome',
      fields: {
        triggers: {
          name: 'triggers',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        taskTrigger: {
          name: 'taskTrigger',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        nextElement: {
          name: 'nextElement',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
      },
    },
    WorkflowNote: {
      name: 'WorkflowNote',
      fields: {
        createdBy: {
          name: 'createdBy',
          isArray: false,
          type: {
            nonModel: 'Owner',
          },
          isRequired: true,
          attributes: [],
        },
        note: {
          name: 'note',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        attachments: {
          name: 'attachments',
          isArray: true,
          type: {
            nonModel: 'Attachment',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        date: {
          name: 'date',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: true,
          attributes: [],
        },
      },
    },
    Attachment: {
      name: 'Attachment',
      fields: {
        key: {
          name: 'key',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        fileName: {
          name: 'fileName',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
      },
    },
    TaskData: {
      name: 'TaskData',
      fields: {
        key: {
          name: 'key',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        value: {
          name: 'value',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        reference: {
          name: 'reference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        group: {
          name: 'group',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        groupId: {
          name: 'groupId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        custom: {
          name: 'custom',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
      },
    },
    Owner: {
      name: 'Owner',
      fields: {
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        email: {
          name: 'email',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
      },
    },
    OktaUser: {
      name: 'OktaUser',
      fields: {
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        email: {
          name: 'email',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        department: {
          name: 'department',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
      },
    },
  },
  codegenVersion: '3.4.4',
  version: '1cf9e3d69cead16af5390e464c5473e4',
};
