import { DataStore, SortDirection } from 'aws-amplify';
import { TaskDefinition, WorkflowDefinition } from '../models';
import { CurrentUserProps } from '../hooks/useCurrentUser';
import {
  WorkflowDefinitionDataProps,
  WorkflowDefinitionModel,
} from './interfaces/workflowDefinition';
import { convertWorkflowDefinitionToModel } from './convert/workflowDefinition';

export const workflowDefinitionsData = (currentUser: CurrentUserProps) => {
  async function get(id: string) {
    const result = await DataStore.query(WorkflowDefinition, id);
    if (result) {
      return await convertWorkflowDefinitionToModel(result);
    }
  }

  async function getList(search?: string) {
    let results = await DataStore.query(
      WorkflowDefinition,
      search?.length
        ? (workflow) =>
            workflow.or((workflow) => [
              workflow.name.contains(search),
              workflow.description.contains(search),
            ])
        : null,
      {
        sort: (w) => w.createdAt(SortDirection.ASCENDING),
      }
    );

    if (results) {
      const convertedResults = [];
      for (const result of results) {
        convertedResults.push(await convertWorkflowDefinitionToModel(result));
      }
      return convertedResults;
    }

    return [];
  }

  async function getListByDepartmentOrEmail(department: string, email: string) {
    if (!department || !email) {
      return [];
    }
    const lowerEmail = email.toLowerCase();

    let results = await DataStore.query(
      WorkflowDefinition,
      (workflow) =>
        workflow.or((workflow) => [
          workflow.groups.contains(department),
          workflow.emails.contains(lowerEmail),
        ]),
      {
        sort: (w) => w.createdAt(SortDirection.ASCENDING),
      }
    );

    if (results) {
      const convertedResults = [];
      for (const result of results) {
        convertedResults.push(await convertWorkflowDefinitionToModel(result));
      }
      return convertedResults;
    }

    return [];
  }

  async function create(data: WorkflowDefinitionModel) {
    const created = await DataStore.save(
      new WorkflowDefinition({
        id: data.id,
        name: data.name,
        description: data.description,
        descriptionReference: data.descriptionReference,
        groups: data.groups,
        emails: data.emails,
        customColumns: data.customColumns,
        multiWorkflow: data.multiWorkflow,
        multiDataReference: data.multiDataReference,
      })
    );

    if (created) {
      for (const task of data.process) {
        await DataStore.save(
          new TaskDefinition({
            id: task.id,
            workflowDefinitionId: created.id,
            workflowDefinition: created,
            formId: task.formId,
            name: task.name,
            description: task.description,
            daysDue: task.daysDue,
            groups: task.groups,
            emails: task.emails,
            order: task.order,
            viewPreviousData: task.viewPreviousData,
            editPreviousForms: task.editPreviousForms,
            elementsToHide: task.elementsToHide,
            type: task.type,
            requiredTasksCompleted: task.requiredTasksCompleted,
            outcomes: task.outcomes,
          })
        );
      }

      return convertWorkflowDefinitionToModel(created);
    }

    throw new Error('Failed to create Workflow Definition.');
  }

  async function update(data: WorkflowDefinitionModel) {
    const original = await DataStore.query(WorkflowDefinition, data.id);

    if (original) {
      const updated = await DataStore.save(
        WorkflowDefinition.copyOf(original, (updated) => {
          updated.name = data.name;
          updated.description = data.description;
          updated.descriptionReference = data.descriptionReference;
          updated.groups = data.groups;
          updated.emails = data.emails;
          updated.multiDataReference = data.multiDataReference;
          updated.multiWorkflow = data.multiWorkflow;
          updated.customColumns = data.customColumns;
        })
      );

      if (updated) {
        for (const task of data.process) {
          const originalTask = await DataStore.query(TaskDefinition, task.id);

          if (originalTask) {
            await DataStore.save(
              TaskDefinition.copyOf(originalTask, (updated) => {
                updated.order = task.order;
                updated.name = task.name;
                updated.description = task.description;
                updated.type = task.type;
                updated.requiredTasksCompleted = task.requiredTasksCompleted;
                updated.daysDue = task.daysDue;
                updated.outcomes = task.outcomes;
                updated.viewPreviousData = task.viewPreviousData;
                updated.editPreviousForms = task.editPreviousForms;
                updated.elementsToHide = task.elementsToHide;
                updated.groups = task.groups;
                updated.emails = task.emails;
              })
            );
          } else {
            await DataStore.save(
              new TaskDefinition({
                id: task.id,
                workflowDefinitionId: updated.id,
                workflowDefinition: updated,
                formId: task.formId,
                name: task.name,
                description: task.description,
                daysDue: task.daysDue,
                groups: task.groups,
                emails: task.emails,
                order: task.order,
                viewPreviousData: task.viewPreviousData,
                editPreviousForms: task.editPreviousForms,
                elementsToHide: task.elementsToHide,
                type: task.type,
                requiredTasksCompleted: task.requiredTasksCompleted,
                outcomes: task.outcomes,
              })
            );
          }
        }
        return convertWorkflowDefinitionToModel(updated);
      }

      throw new Error('Workflow Definition failed to update.');
    }

    throw new Error('Original Definition not found.');
  }

  const returned: WorkflowDefinitionDataProps = {
    get,
    getList,
    getListByDepartmentOrEmail,
    create,
    update,
  };

  return returned;
};
