/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from '@mui/material';
import {
  useAlert,
  useCurrentUser,
  useDatastore,
  useLoading,
} from '../../hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, LoadingSpinner } from '../../components';
import {
  WorkflowCreateMultiModel,
  WorkflowModel,
} from '../../datastore/interfaces/workflow';
import { Owner, TaskData } from '../../models';

export default function CreateWorkflow() {
  const { id } = useParams();
  const store = useDatastore();
  const currentUser = useCurrentUser();
  const alert = useAlert();
  const navigate = useNavigate();
  const setLoading = useLoading();
  const [workflow, setWorkflow] = useState<WorkflowModel | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const task = workflow?.currentTasks[0];

  useEffect(() => {
    async function getData() {
      if (id) {
        try {
          const results = await store.workflows.buildEmpty(
            id,
            currentUser.getOwner()
          );
          if (results) {
            setWorkflow(results);
          }
        } catch (error) {
          console.error(error);
        }
      }

      setIsLoading(false);
    }

    getData();
  }, []);

  async function onComplete(
    data: TaskData[],
    assignee: Owner | undefined,
    triggers: string[]
  ) {
    if (!workflow || !task) {
      alert('Cannot save, Task not found', 'error');
      return;
    }
    setLoading(true);

    const newWorkflows = await store.workflows.createWithMulti(
      workflow.definitionId,
      currentUser.getOwner(),
      data
    );

    /*
    There's a bug in Amplify! 
    https://github.com/aws-amplify/amplify-js/issues/9891
    https://github.com/aws-amplify/amplify-js/issues/11241
    When you save/update a model, then immediately do another update, data can be lost.
    In this instance, we are creating the Workflow in 'store.workflows.createWithMulti' above, 
    then updating it with the completed task data in 'store.workflows.completeTask'.
    This was causing submitted Workflows to lose the latest status and description, reverting to the originals.
    Putting a timer on it, to let DataStore process the first create/update, solves the issue.
    TODO If this is ever fixed, move this all into one function.
    */
    if (newWorkflows.length) {
      setTimeout(
        () => saveData(triggers, newWorkflows, assignee),
        //the more entries, the longer it takes Datastore to sort out
        1600 * newWorkflows.length
      );
    } else {
      alert('Failed to create Workflow!', 'error');
    }
  }

  async function saveData(
    triggers: string[],
    newWorkflows: WorkflowCreateMultiModel[],
    assignee?: Owner
  ) {
    for (const workflow of newWorkflows) {
      await store.workflows.completeTask(
        workflow.workflow,
        workflow.task,
        workflow.description,
        assignee,
        triggers
      );
    }

    setLoading(false);
    alert('Task submitted. Thank you!', 'success');
    navigate('/');
  }

  return (
    <Stack spacing={4} justifyContent="center">
      <LoadingSpinner isLoading={isLoading}>
        {workflow && task ? (
          <>
            <Typography variant="h1">{task.taskDefinition.name}</Typography>
            <Form
              existingData={[]}
              taskDefinition={task.taskDefinition}
              workflow={workflow}
              onComplete={onComplete}
            />
          </>
        ) : (
          <Typography>No task found.</Typography>
        )}
      </LoadingSpinner>
    </Stack>
  );
}
