/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Dialog, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

type ServiceWorkerUpdateProps = {
  children: string | JSX.Element | JSX.Element[];
};

export default function ServiceWorkerUpdate({
  children,
}: ServiceWorkerUpdateProps) {
  useEffect(() => {
    checkUpdate();
  }, []);

  const [updateAvailable, setUpdateAvailable] = useState(false);

  function checkUpdate() {
    navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
      for (const registration of registrationsArray) {
        registration.update();
        registration.onupdatefound = (ev) => {
          setUpdateAvailable(true);
        };
      }
      setTimeout(() => checkUpdate(), 600000); //10 minutes
    });
  }

  function forceReload() {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        registration.unregister().then(function () {
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    });
  }

  return (
    <>
      {children}
      <Dialog open={updateAvailable}>
        <Stack spacing={1} sx={{ padding: '1em' }}>
          <Alert severity="info" sx={{ zIndex: '10000' }}>
            A new update is available. Please refresh the page.
          </Alert>
          <Stack spacing={1} direction="row" justifyContent="center">
            <Button
              variant="outlined"
              onClick={() => setUpdateAvailable(false)}
            >
              Not Now
            </Button>
            <Button variant="contained" onClick={forceReload}>
              Reload
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
