import Header from '../header';
import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type LayoutProps = {
  children: string | JSX.Element | JSX.Element[];
};

export default function Layout({ children }: LayoutProps) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Header />
      <Stack sx={{ padding: isTablet ? '4em 2em' : '4em 5.7em' }}>
        {children}
      </Stack>
    </>
  );
}
