import { Link as MuiLink, TableCell } from '@mui/material';
import { StyledTableRow } from '..';
import { FormElementTypes, parseDataRef } from '../../common';
import { Attachment } from '../../models';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

interface CustomDataTableRowProps {
  groups: string[];
  keyValues: { key: string; values: { group: string; value: string }[] };
}

export default function CustomDataTableRow({
  keyValues,
  groups,
}: CustomDataTableRowProps) {
  const [valueElements, setValueElements] = useState<React.ReactNode>();
  const key = keyValues.key;
  const values = keyValues.values.sort(
    (a, b) => groups.indexOf(a.group) - groups.indexOf(b.group)
  );

  useEffect(() => {
    async function renderValues() {
      setValueElements(
        await Promise.all(
          values.map(async ({ value }, index) => {
            let result: string | React.ReactNode = parseDataRef(value);

            if (key.includes(FormElementTypes.Attachment)) {
              try {
                const parsedValue: Attachment[] = JSON.parse(value);

                const elements: JSX.Element[] = [];

                for (const attachment of parsedValue) {
                  const downloadUrl = await Storage.get(attachment.key);
                  elements.push(
                    <MuiLink
                      key={attachment.key}
                      target="_blank"
                      href={downloadUrl}
                      rel="noreferrer"
                    >
                      {attachment.fileName}
                    </MuiLink>
                  );
                }

                result = elements;
              } catch (error) {
                console.log(error);
              }
            }

            return <TableCell key={index}>{result}</TableCell>;
          })
        )
      );
    }

    renderValues();
  }, [key, values]);

  return (
    <StyledTableRow key={key}>
      <TableCell>{key}</TableCell>
      {valueElements}
    </StyledTableRow>
  );
}
