/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material/styles';
import { WorkflowModel } from '../../../datastore/interfaces/workflow';

interface TopSectionProps {
  workflow: WorkflowModel;
}

export default function TopSection({ workflow }: TopSectionProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Button
        onClick={() => navigate(`/workflows/${workflow.referenceNumber}`)}
      >
        <ArrowBackIosIcon />
      </Button>
      <Typography variant={isMobile ? 'h4' : 'h1'}>
        {workflow.referenceNumber} - {workflow.definition.name}
      </Typography>
    </Stack>
  );
}
