import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: '3em',
  borderRadius: '1px',
  padding: '0 1em',
  color: theme.palette.primary.contrastText,
}));

export default HeaderButton;
