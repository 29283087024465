/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useAlert } from '../../../hooks';
import { SearchConditions, departmentKey } from '../../../common';
import { SearchFieldModel } from '../../../common/search/searchFieldModel';
import { useTheme } from '@mui/material/styles';
import { WorkflowDefinitionModel } from '../../../datastore/interfaces/workflowDefinition';

interface SearchFunctionProps {
  data: any[];
  workflowDefinition: WorkflowDefinitionModel;
  searchFields: SearchFieldModel[];
  setSearchFields: (fields: SearchFieldModel[]) => void;
}

export default function SearchFunction({
  data,
  workflowDefinition,
  searchFields,
  setSearchFields,
}: SearchFunctionProps) {
  const dataWithoutId = data.map(({ id, ...d }) => d);
  const alert = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const departments = [
    ...new Set(workflowDefinition.process.flatMap((p) => p.groups)),
  ];

  if (!dataWithoutId.length) {
    return <Typography>No data to search.</Typography>;
  }
  const searchColumns = Object.keys(dataWithoutId[0]);
  const searchConditions = Object.values(SearchConditions);

  function onAddSearchColumn() {
    const searches = structuredClone(searchFields);

    searches.push({
      column: '',
      condition: SearchConditions.contains,
      value: '',
    });
    setSearchFields(searches);
  }

  function onRemoveSearchColumn(i: number) {
    const searches = structuredClone(searchFields);
    try {
      searches.splice(i, 1);
      setSearchFields(searches);
    } catch (error) {
      alert(`Failed to remove search of index ${i}`, 'error');
      console.error(error);
    }
  }

  function onSearchColumnChange(value: string, i: number) {
    const searches = structuredClone(searchFields);
    searches[i].column = value;
    searches[i].value = '';
    setSearchFields(searches);
  }

  function onSearchConditionChange(value: string, i: number) {
    const searches = structuredClone(searchFields);
    const condition = value as SearchConditions;
    searches[i].condition = condition;
    setSearchFields(searches);
  }

  function onSearchValueChange(value: string, i: number) {
    const searches = structuredClone(searchFields);
    searches[i].value = value;
    setSearchFields(searches);
  }

  function ColumnSelect(searchField: SearchFieldModel, i: number) {
    return (
      <FormControl sx={{ flex: 1 }}>
        <InputLabel id={`searchColumn-label-${i}`} shrink>
          Column
        </InputLabel>
        <Select
          labelId={`searchColumn-label-${i}`}
          id={`searchColumn-${i}`}
          value={searchField.column}
          label="Column"
          notched
          onChange={(e) => onSearchColumnChange(e.target.value, i)}
          sx={{
            '& .MuiInputBase-input': {
              padding: '0.4em 0.6em !important',
            },
          }}
        >
          {searchColumns.map((column) => {
            return (
              <MenuItem key={column} value={column}>
                {column}
              </MenuItem>
            );
          })}
          {departments.length && (
            <MenuItem value={departmentKey}>Department</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

  function DepartmentSelect(searchField: SearchFieldModel, i: number) {
    return (
      <FormControl sx={{ flex: 1 }}>
        <InputLabel id={`searchDepartment-label-${i}`} shrink>
          Department
        </InputLabel>
        <Select
          labelId={`searchDepartment-label-${i}`}
          id={`searchDepartment-${i}`}
          value={searchField.value}
          label="Department"
          notched
          onChange={(e) => onSearchValueChange(e.target.value, i)}
          sx={{
            '& .MuiInputBase-input': {
              padding: '0.4em 0.6em !important',
            },
          }}
        >
          {departments
            .sort((a, b) => (a > b ? 1 : -1))
            .map((department) => {
              return (
                <MenuItem key={department} value={department}>
                  {department}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }

  function ConditionSelect(searchField: SearchFieldModel, i: number) {
    return (
      <FormControl key="ConditionSelect" sx={{ flex: 1 }}>
        <InputLabel id={`searchCondition-label-${i}`} shrink>
          Condition
        </InputLabel>
        <Select
          labelId={`searchCondition-label-${i}`}
          id={`searchCondition-${i}`}
          value={searchField.condition}
          label="Condition"
          notched
          onChange={(e) => onSearchConditionChange(e.target.value, i)}
          sx={{
            '& .MuiInputBase-input': {
              padding: '0.4em 0.6em !important',
            },
          }}
        >
          {searchConditions.map((condition) => {
            return (
              <MenuItem key={condition} value={condition}>
                {condition}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  function ValueField(searchField: SearchFieldModel, i: number) {
    return (
      <TextField
        key="ValueField"
        placeholder="Search"
        value={searchField.value}
        onChange={(e) => onSearchValueChange(e.target.value, i)}
        fullWidth
        InputProps={{
          sx: {
            '& input': {
              padding: '0.4em 0.5em',
            },
          },
        }}
        sx={{ flex: 2 }}
      />
    );
  }

  return (
    <Stack spacing={isMobile ? 2 : 1}>
      <Typography fontWeight="bold">Search</Typography>
      {searchFields.length ? (
        searchFields.map((searchField, i) => {
          return isMobile ? (
            <Stack key={i} spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                {ColumnSelect(searchField, i)}
                {searchField.column === departmentKey
                  ? DepartmentSelect(searchField, i)
                  : ConditionSelect(searchField, i)}
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                {searchField.column === departmentKey ? (
                  <></>
                ) : (
                  ValueField(searchField, i)
                )}
                <IconButton onClick={() => onRemoveSearchColumn(i)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <Stack key={i} direction="row" spacing={1} alignItems="center">
              {ColumnSelect(searchField, i)}
              {searchField.column === departmentKey
                ? DepartmentSelect(searchField, i)
                : [ConditionSelect(searchField, i), ValueField(searchField, i)]}
              <IconButton onClick={() => onRemoveSearchColumn(i)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Stack>
          );
        })
      ) : (
        <Typography>No search criteria specified.</Typography>
      )}
      <Box>
        <Button variant="contained" onClick={onAddSearchColumn}>
          <AddIcon />
        </Button>
      </Box>
    </Stack>
  );
}
