import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { FormElementModel } from '../../../datastore/interfaces/form';
import { OktaUser } from '../../../models';
import OktaLookup from '../../lookup/OktaLookup';

export interface AssignFieldProps {
  element: FormElementModel;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  idPrefix: string;
}

export interface AssignFieldSettings {
  allowSelf?: boolean;
}

export default function AssignField({
  element,
  getValues,
  setValue,
  clearErrors,
  error,
  idPrefix,
}: AssignFieldProps) {
  const elementEmail = `${idPrefix}${element.dataReference} Email`;
  const elementName = `${idPrefix}${element.dataReference} Name`;

  const data: AssignFieldSettings | null = element.data
    ? JSON.parse(element.data)
    : null;

  function onLookupChange(user: OktaUser) {
    setValue(elementEmail, user.email);
    setValue(elementName, user.name);
    clearErrors(element.dataReference);
  }

  function onLookupClear() {
    setValue(elementEmail, '');
    setValue(elementName, '');
    clearErrors(element.dataReference);
  }

  const existingValues = getValues([elementName, elementEmail]);

  const existingValue =
    existingValues.length === 2 && existingValues[0] && existingValues[1]
      ? `${existingValues[0]} (${existingValues[1]})`
      : '';

  return (
    <OktaLookup
      onSelect={onLookupChange}
      onClear={onLookupClear}
      defaultValue={existingValue}
      hasError={error !== undefined}
      allowSelf={data?.allowSelf ?? false}
    />
  );
}
